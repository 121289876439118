<div class="popup">
  <div class="popup__body">
    <div class="popup__body-content">
      <div class="display-flex width-100">
        <div class="menus__menu width-50 right">
          <div class="menus__menu-label">
            {{ "PROFILE.Reset_Password.Current_Password" | translate }}
          </div>
          <div class="menus__menu-selector">
            <input
              [attr.type]="visiblePassword === 'currentPassword' ? 'text' : 'password'"
              maxlength="25"
              (change)="changePassword($event.target.value)"
              [value]="passwordCurrent"
            />
            <img src="assets/img/login/eye_icon.009fd067.svg" (click)="showHidePass('currentPassword')">
            <div class="err" *ngIf="errorCode === Errors.invalidPassword && showSaveFail$ | async">
              {{'PROFILE.Reset_Password.' + errorCode | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="display-flex width-100">
        <div class="menus__menu width-50 right">

          <div class="menus__menu-label">
            {{ "PROFILE.Reset_Password.New_Password" | translate }}
          </div>
          <div class="menus__menu-label details">
            {{ "PROFILE.Reset_Password.Password_Details" | translate }}
          </div>
          <div class="menus__menu-selector">
            <input
              [attr.type]="visiblePassword === 'newPassword' ? 'text' : 'password'"
              maxlength="25"
              (keyup)="passwordNew = $event.target.value"
              [value]="passwordNew"
            />
            <img src="assets/img/login/eye_icon.009fd067.svg" (click)="showHidePass('newPassword')">
            <div class="err" *ngIf="(errorCode === Errors.cannotChangePasswordToCurrentPassword || errorCode === Errors.passwordHasBeenUsedTooRecently) && showSaveFail$ | async">
              {{'PROFILE.Reset_Password.' + errorCode | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="display-flex width-100">
        <div class="menus__menu width-50 right">
          <div class="menus__menu-label">
            {{ "PROFILE.Reset_Password.Confirm_Password" | translate }}
          </div>
          <div class="menus__menu-selector">
            <input
              [attr.type]="visiblePassword === 'confirmPassword' ? 'text' : 'password'"
              maxlength="25"
              (keyup)="passwordConfirm = $event.target.value"
              [value]="passwordConfirm"
            />
            <img src="assets/img/login/eye_icon.009fd067.svg" (click)="showHidePass('confirmPassword')">
          </div>
        </div>
      </div>
    </div>

    <div class="display-flex end width-100 farms-button-wrapper">
      <button
        class="blue-rounded-button"
        (click)="updateMyUserDetails()"
        [disabled]="
              !isValidPassword ||
              passwordNew !== passwordConfirm ||
              passwordNew?.length < 8 ||
              passwordConfirm?.length < 8">
        {{ "COMMON.BUTTONS.SAVE_CHANGES" | translate }}
      </button>
    </div>
  </div>
</div>
