import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translations/translation.service';
import { LanguageEnum } from '../../services/api/models/language-enum';

export const POUND_TO_KG = 0.45359237;

@Pipe({
  name: 'unitsConvert',
  pure: false,
})
export class UnitsConvertPipe implements PipeTransform {
  constructor(private readonly translationService: TranslationService) {}

  private readonly kgToPound = 2.20462;
  private readonly literToGallon = 0.26417205;
  private readonly meterToFeet = 3.28084;

  public transform(
    value: any,
    format: string,
    isShowUnits: boolean,
    naParameter?: string,
    isShowNaParameter?: boolean
  ): any {
    if (
      value !== null &&
      value !== undefined &&
      !Number.isNaN(value) &&
      value !== 'NaN'
    ) {
      // if (value === 0 || value === '0') {
      //   return value;
      // }
      value = Number(value);
      if (this.translationService.selectedLanguage === LanguageEnum.EnUs) {
        switch (format) {
          case 'g':
            value = this.locale(value / 1000);
          case 'kg':
            return (
              `${this.locale(value * this.kgToPound, 3)}` +
              `${isShowUnits ? ` Lbs` : ``}`
            );
            break;
          case 'C':
            return (
              `${this.locale((value * 9) / 5 + 32, 1)}` +
              `${isShowUnits ? ` °F` : ``}`
            );
            break;
          case 'L':
            return (
              `${this.locale(value * this.literToGallon, 1)}` +
              `${isShowUnits ? ` gal` : ``}`
            );
            break;
          case 'm':
            return (
              `${this.locale(value * this.meterToFeet, 1)}` +
              `${isShowUnits ? ` ft` : ``}`
            );
            break;
          case '%':
            return `${this.locale(value, 3)}` + `${isShowUnits ? `%` : ``}`;
            break;
          case 'lbs':
            return (
              `${this.locale(value / this.kgToPound, 3)}` +
              `${isShowUnits ? ` kg` : ``}`
            );
        }
      } else {
        switch (format) {
          case 'g':
            value = value / 1000;
          case 'kg':
            return `${this.locale(value, 3)}` + `${isShowUnits ? ` Kg` : ``}`;
            break;
          case 'C':
            return `${this.locale(value, 1)}` + `${isShowUnits ? ` °C` : ``}`;
            break;
          case 'L':
            return `${this.locale(value, 1)}` + `${isShowUnits ? ` L` : ``}`;
            break;
          case 'm':
            return `${this.locale(value, 1)}` + `${isShowUnits ? ` m` : ``}`;
            break;
          case '%':
            return `${this.locale(value, 0)}` + `${isShowUnits ? `%` : ``}`;
            break;
          case 'lbs':
            return `${this.locale(value, 3)}` + `${isShowUnits ? `Lbs` : ``}`;
            break;
        }
      }
    } else {
      if (naParameter && isShowNaParameter) {
        return naParameter;
      } else if (isShowNaParameter) {
        return 'NA';
      } else {
        return value;
      }
    }
  }

  private locale(value: any, toFixed?: number) {
    return toFixed
      ? value?.toLocaleString(
          this.translationService.transformLanguageEnum(
            this.translationService.selectedLanguage
          ),
          {
            minimumFractionDigits: toFixed,
            maximumFractionDigits: toFixed,
          }
        )
      : value?.toLocaleString(
          this.translationService.transformLanguageEnum(
            this.translationService.selectedLanguage
          )
        );
  }
}
