import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslationService } from '../../../../services/translations/translation.service';
import * as _ from 'lodash';

@Component({
  selector: 'default-multiselect',
  templateUrl: './default-multiselect.component.html',
  styleUrls: ['./default-multiselect.component.scss'],
})
export class DefaultMultiselectComponent implements OnChanges {
  @Input() public values: string[] = [];
  @Input() public isTranslated = false;
  @Input() public isSearchable = false;
  @Input() public defaultValues: string[] = [];
  @Input() public disabled?: boolean = false;
  @Input() public placeholder?: string = '';
  @Output() public selectedValuesChanged: EventEmitter<Array<any>> =
    new EventEmitter();
  public isOpen: boolean = false;
  public selectedValues: string[] = [];
  public valuesCopy: string[] = [];
  public searchValue: string = '';
  private isClickInside: boolean = false;

  @HostListener('click')
  public clickInside() {
    this.isClickInside = true;
  }

  @HostListener('document:click')
  public clickOutside() {
    if (!this.isClickInside) {
      this.scrollToStart();
      this.search('');
      this.isOpen = false;
    }
    this.isClickInside = false;
  }

  @HostListener('window:scroll')
  public scroll() {
    if (!this.isClickInside) {
      this.scrollToStart();
      this.isOpen = false;
    }
    this.isClickInside = false;
  }

  @ViewChild('list')
  public list: ElementRef;

  constructor(public translationService: TranslationService) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.values) {
      this.selectedValues = [];
      this.valuesCopy = _.cloneDeep(this.values);
    }
    if (changes.defaultValues) {
      this.selectedValues = [...this.defaultValues];
    }
  }

  public selectAll() {
    if (this.selectedValues.length === this.valuesCopy.length) {
      this.selectedValues = [];
    } else {
      this.selectedValues = this.valuesCopy.map((item) => item);
    }
  }

  public toggle(value) {
    if (value === 'all') {
      this.selectAll();
    } else if (!this.selectedValues.includes(value) && value !== 'all') {
      this.selectedValues.push(this.valuesCopy.find((item) => item === value));
    } else {
      this.selectedValues.splice(this.selectedValues.indexOf(value), 1);
    }
    this.selectedValues = [...this.selectedValues];
    this.selectedValuesChanged.emit(this.selectedValues);
  }

  public get translatedSelectedValues() {
    return this.selectedValues.map((item) =>
      this.translationService.translate('COMMENTS.' + item)
    );
  }

  public isChecked(value) {
    if (value === 'all') {
      return this.selectedValues.length === this.valuesCopy.length;
    } else {
      return this.selectedValues.includes(value);
    }
  }

  public openDropdown() {
    if (this.valuesCopy?.length > 0 && !this.disabled) {
      this.isOpen = true;
    }
  }

  public scrollToStart() {
    this.list.nativeElement.scrollTop = 0;
  }

  public search(value: string) {
    this.searchValue = value;
    if (value.length) {
      this.valuesCopy = this.values.filter((item) => item.includes(value));
    } else {
      this.valuesCopy = _.cloneDeep(this.values);
    }
  }
}
