<div class="popup">
  <div class="popup__body">
    <div class="popup__body-content">
      <div class="display-flex details width-100">
        <div class="menus__menu width-50 right">
          <div class="menus__menu-label">
            {{ "PROFILE.DETAILS.First_Name" | translate }}
          </div>
          <div class="menus__menu-selector">
            <input
              type="text"
              maxlength="35"
              (change)="profileData.firstName = $event.target.value"
              [value]="profileData.firstName"
            />
          </div>
        </div>
        <div class="menus__menu width-50">
          <div class="menus__menu-label">
            {{ "PROFILE.DETAILS.Last_Name" | translate }}
          </div>
          <div class="menus__menu-selector">
            <input
              type="text"
              maxlength="35"
              (change)="profileData.lastName = $event.target.value"
              [value]="profileData.lastName"
            />
          </div>
        </div>
      </div>
      <div class="display-flex role-name width-100">
        <div class="menus__menu width-50 right">
          <div class="menus__menu-label">
            {{ "PROFILE.DETAILS.Role" | translate }}
          </div>
          <div class="menus__menu-selector role">
            <input
              type="text"
              maxlength="35"
              disabled="disabled"
              [value]="profileData.role"
            />
          </div>
        </div>
        <div class="menus__menu width-50">
          <div class="menus__menu-label">
            {{ "PROFILE.DETAILS.Phone" | translate }}
          </div>
          <div class="menus__menu-selector">
            <input
              type="text"
              maxlength="35"
              placeholder="e.g. +12345678900"
              (keyup)="profileData.phone = $event.target.value"
              [value]="profileData.phone"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="display-flex end width-100 farms-button-wrapper">
      <button
        class="blue-rounded-button"
        [disabled]="
              profileData.firstName?.length === 0 ||
              profileData.lastName?.length === 0 ||
              !utilsService.isPhoneValid(profileData.phone, false)"
        (click)="updateMyUserDetails()">
        {{ "COMMON.BUTTONS.SAVE_CHANGES" | translate }}
      </button>
    </div>
  </div>
</div>
