/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BulkResult } from '../models/bulk-result';
import { DeleteRegisterDevicePlacementRequest } from '../models/delete-register-device-placement-request';
import { DeviceFullDto } from '../models/device-full-dto';
import { DeviceInUseDto } from '../models/device-in-use-dto';
import { DownlinkCommandDto } from '../models/downlink-command-dto';
import { DownlinkCommandStatusDto } from '../models/downlink-command-status-dto';
import { LocationDevice } from '../models/location-device';
import { RegisteredDevice } from '../models/registered-device';
import { RegisteredDeviceCreateDto } from '../models/registered-device-create-dto';
import { RegisteredDeviceResponse } from '../models/registered-device-response';
import { UpdateDevicePlacementsDto } from '../models/update-device-placements-dto';

@Injectable({
  providedIn: 'root',
})
export class RegisteredDeviceService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDevice
   */
  static readonly GetDevicePath = '/api/regdevice';

  /**
   * Get Device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevice$Response(params?: {
    customerId?: number;
    facilityGroupId?: number;
    includeNonActive?: boolean;
  }): Observable<StrictHttpResponse<Array<RegisteredDeviceResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.GetDevicePath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
      rb.query('facilityGroupId', params.facilityGroupId, {});
      rb.query('includeNonActive', params.includeNonActive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RegisteredDeviceResponse>>;
      })
    );
  }

  /**
   * Get Device.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevice(params?: {
    customerId?: number;
    facilityGroupId?: number;
    includeNonActive?: boolean;
  }): Observable<Array<RegisteredDeviceResponse>> {

    return this.getDevice$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RegisteredDeviceResponse>>) => r.body as Array<RegisteredDeviceResponse>)
    );
  }

  /**
   * Path part for operation createDevice
   */
  static readonly CreateDevicePath = '/api/regdevice';

  /**
   * Create Device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDevice$Response(params: {
    body: RegisteredDeviceCreateDto
  }): Observable<StrictHttpResponse<RegisteredDevice>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.CreateDevicePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisteredDevice>;
      })
    );
  }

  /**
   * Create Device.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDevice(params: {
    body: RegisteredDeviceCreateDto
  }): Observable<RegisteredDevice> {

    return this.createDevice$Response(params).pipe(
      map((r: StrictHttpResponse<RegisteredDevice>) => r.body as RegisteredDevice)
    );
  }

  /**
   * Path part for operation sendDownlinkCommand
   */
  static readonly SendDownlinkCommandPath = '/api/regdevice/dlcommand';

  /**
   * Send Downlink Command.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendDownlinkCommand()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendDownlinkCommand$Response(params: {
    body: DownlinkCommandDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.SendDownlinkCommandPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Send Downlink Command.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendDownlinkCommand$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendDownlinkCommand(params: {
    body: DownlinkCommandDto
  }): Observable<void> {

    return this.sendDownlinkCommand$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDownlinkCommandStatus
   */
  static readonly GetDownlinkCommandStatusPath = '/api/regdevice/dlcommand/{identifier}';

  /**
   * Get Downlink Command Status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDownlinkCommandStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDownlinkCommandStatus$Response(params: {
    identifier: string;
  }): Observable<StrictHttpResponse<DownlinkCommandStatusDto>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.GetDownlinkCommandStatusPath, 'get');
    if (params) {
      rb.path('identifier', params.identifier, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DownlinkCommandStatusDto>;
      })
    );
  }

  /**
   * Get Downlink Command Status.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDownlinkCommandStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDownlinkCommandStatus(params: {
    identifier: string;
  }): Observable<DownlinkCommandStatusDto> {

    return this.getDownlinkCommandStatus$Response(params).pipe(
      map((r: StrictHttpResponse<DownlinkCommandStatusDto>) => r.body as DownlinkCommandStatusDto)
    );
  }

  /**
   * Path part for operation getGroupDevicesByFacilityId
   */
  static readonly GetGroupDevicesByFacilityIdPath = '/api/regdevice/facility/{facilityId}';

  /**
   * Get Group Devices By Facility Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroupDevicesByFacilityId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupDevicesByFacilityId$Response(params: {
    facilityId: number;
  }): Observable<StrictHttpResponse<Array<DeviceInUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.GetGroupDevicesByFacilityIdPath, 'get');
    if (params) {
      rb.path('facilityId', params.facilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DeviceInUseDto>>;
      })
    );
  }

  /**
   * Get Group Devices By Facility Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGroupDevicesByFacilityId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupDevicesByFacilityId(params: {
    facilityId: number;
  }): Observable<Array<DeviceInUseDto>> {

    return this.getGroupDevicesByFacilityId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DeviceInUseDto>>) => r.body as Array<DeviceInUseDto>)
    );
  }

  /**
   * Path part for operation getDevicesByFarm
   */
  static readonly GetDevicesByFarmPath = '/api/regdevice/facilityZoneId/{facilityZoneId}';

  /**
   * Get Devices By Farm.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDevicesByFarm()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevicesByFarm$Response(params: {
    facilityZoneId: number;
  }): Observable<StrictHttpResponse<Array<RegisteredDevice>>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.GetDevicesByFarmPath, 'get');
    if (params) {
      rb.path('facilityZoneId', params.facilityZoneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RegisteredDevice>>;
      })
    );
  }

  /**
   * Get Devices By Farm.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDevicesByFarm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevicesByFarm(params: {
    facilityZoneId: number;
  }): Observable<Array<RegisteredDevice>> {

    return this.getDevicesByFarm$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RegisteredDevice>>) => r.body as Array<RegisteredDevice>)
    );
  }

  /**
   * Path part for operation getDevicePageData
   */
  static readonly GetDevicePageDataPath = '/api/regdevice/full';

  /**
   * Get Device Page Data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDevicePageData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevicePageData$Response(params?: {
    cropId?: number;
    customerId?: number;
    facilityGroupId?: number;
    includeNonActive?: boolean;
  }): Observable<StrictHttpResponse<DeviceFullDto>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.GetDevicePageDataPath, 'get');
    if (params) {
      rb.query('cropId', params.cropId, {});
      rb.query('customerId', params.customerId, {});
      rb.query('facilityGroupId', params.facilityGroupId, {});
      rb.query('includeNonActive', params.includeNonActive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceFullDto>;
      })
    );
  }

  /**
   * Get Device Page Data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDevicePageData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevicePageData(params?: {
    cropId?: number;
    customerId?: number;
    facilityGroupId?: number;
    includeNonActive?: boolean;
  }): Observable<DeviceFullDto> {

    return this.getDevicePageData$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceFullDto>) => r.body as DeviceFullDto)
    );
  }

  /**
   * Path part for operation getDevicePageLocationData
   */
  static readonly GetDevicePageLocationDataPath = '/api/regdevice/full/location';

  /**
   * Get Device Page Location Data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDevicePageLocationData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevicePageLocationData$Response(params?: {
    facilityId?: number;
  }): Observable<StrictHttpResponse<Array<LocationDevice>>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.GetDevicePageLocationDataPath, 'get');
    if (params) {
      rb.query('facilityId', params.facilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocationDevice>>;
      })
    );
  }

  /**
   * Get Device Page Location Data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDevicePageLocationData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevicePageLocationData(params?: {
    facilityId?: number;
  }): Observable<Array<LocationDevice>> {

    return this.getDevicePageLocationData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocationDevice>>) => r.body as Array<LocationDevice>)
    );
  }

  /**
   * Path part for operation updateDevicePlacements
   */
  static readonly UpdateDevicePlacementsPath = '/api/regdevice/placement';

  /**
   * Update Device Placements.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDevicePlacements()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDevicePlacements$Response(params: {
    body: UpdateDevicePlacementsDto
  }): Observable<StrictHttpResponse<Array<RegisteredDevice>>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.UpdateDevicePlacementsPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RegisteredDevice>>;
      })
    );
  }

  /**
   * Update Device Placements.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDevicePlacements$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDevicePlacements(params: {
    body: UpdateDevicePlacementsDto
  }): Observable<Array<RegisteredDevice>> {

    return this.updateDevicePlacements$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RegisteredDevice>>) => r.body as Array<RegisteredDevice>)
    );
  }

  /**
   * Path part for operation deleteDevicePlacement
   */
  static readonly DeleteDevicePlacementPath = '/api/regdevice/placement';

  /**
   * Delete Device Placement.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDevicePlacement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteDevicePlacement$Response(params: {
    body: DeleteRegisterDevicePlacementRequest
  }): Observable<StrictHttpResponse<RegisteredDeviceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.DeleteDevicePlacementPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisteredDeviceResponse>;
      })
    );
  }

  /**
   * Delete Device Placement.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteDevicePlacement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteDevicePlacement(params: {
    body: DeleteRegisterDevicePlacementRequest
  }): Observable<RegisteredDeviceResponse> {

    return this.deleteDevicePlacement$Response(params).pipe(
      map((r: StrictHttpResponse<RegisteredDeviceResponse>) => r.body as RegisteredDeviceResponse)
    );
  }

  /**
   * Path part for operation replaceDevice
   */
  static readonly ReplaceDevicePath = '/api/regdevice/replace/{deviceId}';

  /**
   * Replace Device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceDevice$Response(params: {
    deviceId: number;
    body: RegisteredDeviceCreateDto
  }): Observable<StrictHttpResponse<RegisteredDevice>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.ReplaceDevicePath, 'post');
    if (params) {
      rb.path('deviceId', params.deviceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisteredDevice>;
      })
    );
  }

  /**
   * Replace Device.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `replaceDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceDevice(params: {
    deviceId: number;
    body: RegisteredDeviceCreateDto
  }): Observable<RegisteredDevice> {

    return this.replaceDevice$Response(params).pipe(
      map((r: StrictHttpResponse<RegisteredDevice>) => r.body as RegisteredDevice)
    );
  }

  /**
   * Path part for operation upload
   */
  static readonly UploadPath = '/api/regdevice/upload';

  /**
   * Upload.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upload()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  upload$Response(params: {
    facilityGroupId?: number;
    body: {
}
  }): Observable<StrictHttpResponse<BulkResult>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.UploadPath, 'post');
    if (params) {
      rb.query('facilityGroupId', params.facilityGroupId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkResult>;
      })
    );
  }

  /**
   * Upload.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upload$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  upload(params: {
    facilityGroupId?: number;
    body: {
}
  }): Observable<BulkResult> {

    return this.upload$Response(params).pipe(
      map((r: StrictHttpResponse<BulkResult>) => r.body as BulkResult)
    );
  }

  /**
   * Path part for operation updateRegDeviceBasicData
   */
  static readonly UpdateRegDeviceBasicDataPath = '/api/regdevice/{deviceId}';

  /**
   * Update Reg Device Basic Data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRegDeviceBasicData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRegDeviceBasicData$Response(params: {
    deviceId: number;
    body: RegisteredDeviceCreateDto
  }): Observable<StrictHttpResponse<RegisteredDevice>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.UpdateRegDeviceBasicDataPath, 'put');
    if (params) {
      rb.path('deviceId', params.deviceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisteredDevice>;
      })
    );
  }

  /**
   * Update Reg Device Basic Data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRegDeviceBasicData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRegDeviceBasicData(params: {
    deviceId: number;
    body: RegisteredDeviceCreateDto
  }): Observable<RegisteredDevice> {

    return this.updateRegDeviceBasicData$Response(params).pipe(
      map((r: StrictHttpResponse<RegisteredDevice>) => r.body as RegisteredDevice)
    );
  }

  /**
   * Path part for operation deleteDevice
   */
  static readonly DeleteDevicePath = '/api/regdevice/{deviceId}';

  /**
   * Delete Device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDevice$Response(params: {
    deviceId: number;
  }): Observable<StrictHttpResponse<RegisteredDevice>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.DeleteDevicePath, 'delete');
    if (params) {
      rb.path('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisteredDevice>;
      })
    );
  }

  /**
   * Delete Device.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDevice(params: {
    deviceId: number;
  }): Observable<RegisteredDevice> {

    return this.deleteDevice$Response(params).pipe(
      map((r: StrictHttpResponse<RegisteredDevice>) => r.body as RegisteredDevice)
    );
  }

  /**
   * Path part for operation setDeviceCalibrationByPast
   */
  static readonly SetDeviceCalibrationByPastPath = '/api/regdevice/{deviceId}/calibration/bytime';

  /**
   * Set Device Calibration By Past.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setDeviceCalibrationByPast()` instead.
   *
   * This method doesn't expect any request body.
   */
  setDeviceCalibrationByPast$Response(params: {
    deviceId: number;
  }): Observable<StrictHttpResponse<RegisteredDevice>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.SetDeviceCalibrationByPastPath, 'post');
    if (params) {
      rb.path('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisteredDevice>;
      })
    );
  }

  /**
   * Set Device Calibration By Past.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setDeviceCalibrationByPast$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setDeviceCalibrationByPast(params: {
    deviceId: number;
  }): Observable<RegisteredDevice> {

    return this.setDeviceCalibrationByPast$Response(params).pipe(
      map((r: StrictHttpResponse<RegisteredDevice>) => r.body as RegisteredDevice)
    );
  }

  /**
   * Path part for operation disable
   */
  static readonly DisablePath = '/api/regdevice/{deviceId}/disable/{disabled}';

  /**
   * Disable.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disable()` instead.
   *
   * This method doesn't expect any request body.
   */
  disable$Response(params: {
    deviceId: number;
    disabled: boolean;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.DisablePath, 'put');
    if (params) {
      rb.path('deviceId', params.deviceId, {});
      rb.path('disabled', params.disabled, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Disable.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `disable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disable(params: {
    deviceId: number;
    disabled: boolean;
  }): Observable<void> {

    return this.disable$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation markAsCalibrated
   */
  static readonly MarkAsCalibratedPath = '/api/regdevice/{deviceId}/markascalibrated';

  /**
   * Mark As Calibrated.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markAsCalibrated()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAsCalibrated$Response(params: {
    deviceId: number;
  }): Observable<StrictHttpResponse<RegisteredDevice>> {

    const rb = new RequestBuilder(this.rootUrl, RegisteredDeviceService.MarkAsCalibratedPath, 'put');
    if (params) {
      rb.path('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisteredDevice>;
      })
    );
  }

  /**
   * Mark As Calibrated.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `markAsCalibrated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAsCalibrated(params: {
    deviceId: number;
  }): Observable<RegisteredDevice> {

    return this.markAsCalibrated$Response(params).pipe(
      map((r: StrictHttpResponse<RegisteredDevice>) => r.body as RegisteredDevice)
    );
  }

}
