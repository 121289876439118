/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FacilityZoneType } from '../models/facility-zone-type';

@Injectable({
  providedIn: 'root',
})
export class FacilityZoneTypeService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFacilityZoneTypes
   */
  static readonly GetFacilityZoneTypesPath = '/api/facilityzonetype';

  /**
   * Get Facility Zone Types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFacilityZoneTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityZoneTypes$Response(params?: {
    customerId?: number;
  }): Observable<StrictHttpResponse<Array<FacilityZoneType>>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityZoneTypeService.GetFacilityZoneTypesPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FacilityZoneType>>;
      })
    );
  }

  /**
   * Get Facility Zone Types.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFacilityZoneTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityZoneTypes(params?: {
    customerId?: number;
  }): Observable<Array<FacilityZoneType>> {

    return this.getFacilityZoneTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FacilityZoneType>>) => r.body as Array<FacilityZoneType>)
    );
  }

  /**
   * Path part for operation addFacilityZoneType
   */
  static readonly AddFacilityZoneTypePath = '/api/facilityzonetype';

  /**
   * Add Facility Zone Type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFacilityZoneType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFacilityZoneType$Response(params: {
    body: FacilityZoneType
  }): Observable<StrictHttpResponse<FacilityZoneType>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityZoneTypeService.AddFacilityZoneTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityZoneType>;
      })
    );
  }

  /**
   * Add Facility Zone Type.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addFacilityZoneType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFacilityZoneType(params: {
    body: FacilityZoneType
  }): Observable<FacilityZoneType> {

    return this.addFacilityZoneType$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityZoneType>) => r.body as FacilityZoneType)
    );
  }

  /**
   * Path part for operation updateFacilityZoneType
   */
  static readonly UpdateFacilityZoneTypePath = '/api/facilityzonetype/{facilityZoneTypeId}';

  /**
   * Update Facility Zone Type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFacilityZoneType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFacilityZoneType$Response(params: {
    facilityZoneTypeId: number;
    body: FacilityZoneType
  }): Observable<StrictHttpResponse<FacilityZoneType>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityZoneTypeService.UpdateFacilityZoneTypePath, 'put');
    if (params) {
      rb.path('facilityZoneTypeId', params.facilityZoneTypeId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityZoneType>;
      })
    );
  }

  /**
   * Update Facility Zone Type.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFacilityZoneType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFacilityZoneType(params: {
    facilityZoneTypeId: number;
    body: FacilityZoneType
  }): Observable<FacilityZoneType> {

    return this.updateFacilityZoneType$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityZoneType>) => r.body as FacilityZoneType)
    );
  }

  /**
   * Path part for operation deleteFacilityZoneType
   */
  static readonly DeleteFacilityZoneTypePath = '/api/facilityzonetype/{facilityZoneTypeId}';

  /**
   * Delete Facility Zone Type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFacilityZoneType()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFacilityZoneType$Response(params: {
    facilityZoneTypeId: number;
  }): Observable<StrictHttpResponse<FacilityZoneType>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityZoneTypeService.DeleteFacilityZoneTypePath, 'delete');
    if (params) {
      rb.path('facilityZoneTypeId', params.facilityZoneTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityZoneType>;
      })
    );
  }

  /**
   * Delete Facility Zone Type.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFacilityZoneType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFacilityZoneType(params: {
    facilityZoneTypeId: number;
  }): Observable<FacilityZoneType> {

    return this.deleteFacilityZoneType$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityZoneType>) => r.body as FacilityZoneType)
    );
  }

}
