import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import * as _ from 'lodash';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  AlertNotificationSetting,
  LanguageEnum,
  NotificationTitle,
  UserAlertNotificationSettingDto,
} from '../../../../services/api/models';
import { NotificationStatus } from '../../../../services/api/models/notification-status';
import { RoleDto } from '../../../../services/api/models/role-dto';
import { CustomerSelectorsService } from '../../../../services/customer-selectors/customer-selectors.service';
import { TranslationService } from '../../../../services/translations/translation.service';
import { PermissionService } from '../../../../services/user/permission-service';
import {
  DATE_FORMAT_WITH_Z,
  UtilsService,
} from '../../../../services/utils/utils.service';
import {
  EpochStateModel,
  EpochStateModelMinMaxMode,
} from '../../time-picker/epoch-state-model';
import { IProfile } from '../../profile-modal/profile-modal.component';
import { Router } from '@angular/router';

interface ISettingsUpdatePayload {
  settingId?: number;
  body: UserAlertNotificationSettingDto;
}

interface AlertNotificationSettingExt
  extends Omit<AlertNotificationSetting, 'timeFrom' | 'timeTo'> {
  timeFrom: EpochStateModel;
  timeTo: EpochStateModel;
}

const DEFAULT_NOTIFICATION_SETTING: {
  [key: string]: AlertNotificationSettingExt;
} = {
  [NotificationTitle.Temperature]: {
    status: NotificationStatus.No,
    title: NotificationTitle.Temperature,
    isEmailActive: false,
    isSmsActive: false,
    timeFrom: new EpochStateModel(
      EpochStateModelMinMaxMode.Time,
      moment.utc('07:00', 'HH:mm').valueOf() / 1000
    ),
    timeTo: new EpochStateModel(
      EpochStateModelMinMaxMode.Time,
      moment.utc('22:00', 'HH:mm').valueOf() / 1000
    ),
  },
  [NotificationTitle.GatewayDisconnect]: {
    status: NotificationStatus.No,
    title: NotificationTitle.GatewayDisconnect,
    isEmailActive: false,
    isSmsActive: false,
    timeFrom: new EpochStateModel(
      EpochStateModelMinMaxMode.Time,
      moment.utc('07:00', 'HH:mm').valueOf() / 1000
    ),
    timeTo: new EpochStateModel(
      EpochStateModelMinMaxMode.Time,
      moment.utc('22:00', 'HH:mm').valueOf() / 1000
    ),
  },
  [NotificationTitle.WaterConsumption]: {
    status: NotificationStatus.No,
    title: NotificationTitle.WaterConsumption,
    isEmailActive: false,
    isSmsActive: false,
    timeFrom: new EpochStateModel(
      EpochStateModelMinMaxMode.Time,
      moment.utc('07:00', 'HH:mm').valueOf() / 1000
    ),
    timeTo: new EpochStateModel(
      EpochStateModelMinMaxMode.Time,
      moment.utc('22:00', 'HH:mm').valueOf() / 1000
    ),
  },
  [NotificationTitle.Recharge]: {
    status: NotificationStatus.No,
    title: NotificationTitle.Recharge,
    isEmailActive: false,
    isSmsActive: false,
    timeFrom: new EpochStateModel(
      EpochStateModelMinMaxMode.Time,
      moment.utc('07:00', 'HH:mm').valueOf() / 1000
    ),
    timeTo: new EpochStateModel(
      EpochStateModelMinMaxMode.Time,
      moment.utc('22:00', 'HH:mm').valueOf() / 1000
    ),
  },
};

@Component({
  selector: 'edit-notification-settings-modal',
  templateUrl: './edit-notification-settings-modal.component.html',
  styleUrls: ['./edit-notification-settings-modal.component.scss'],
})
export class EditNotificationSettingsModalComponent
  implements OnDestroy, OnChanges, OnInit
{
  @Output() public onCloseModal?: EventEmitter<void> = new EventEmitter<void>();

  @Output() public updateSetting: EventEmitter<ISettingsUpdatePayload[]> =
    new EventEmitter<ISettingsUpdatePayload[]>();

  @Output() public onEditRole: EventEmitter<any> = new EventEmitter<any>();

  @Output() public onShowPopup: EventEmitter<any> = new EventEmitter<any>();

  @Input('settings')
  public settings: AlertNotificationSetting[];

  @Input() public profileData: IProfile;

  public mappedSettings: AlertNotificationSettingExt[];

  @Input()
  public isProfileSettings: boolean = false;

  @Input()
  public currentRole?: RoleDto;

  @Input()
  public settingsUpdated: Observable<boolean>;

  private destroyed$ = new Subject();

  public NotificationStatus = NotificationStatus;

  public Number = Number;

  public from: EpochStateModel;
  public to: EpochStateModel;

  public openedSettingType: NotificationTitle = NotificationTitle.Temperature;
  private presetupSettings: typeof DEFAULT_NOTIFICATION_SETTING = _.cloneDeep(
    DEFAULT_NOTIFICATION_SETTING
  );

  public constructor(
    public dateTimeAdapter: DateTimeAdapter<any>,
    public translationService: TranslationService,
    public customerSelectorsService: CustomerSelectorsService,
    public PermissionService: PermissionService,
    public utilsService: UtilsService,
    public router: Router
  ) {
    dateTimeAdapter.setLocale(
      this.translationService.transformLanguageEnum(
        this.translationService.selectedLanguage
      )
    );
    this.from = new EpochStateModel(EpochStateModelMinMaxMode.Time);
    this.to = new EpochStateModel(EpochStateModelMinMaxMode.Time);
  }

  public get isEnUsLocale() {
    return this.translationService.selectedLanguage === LanguageEnum.EnUs;
  }

  public get isChangedSettings() {
    return !this.mappedSettings?.every((setting) => {
      const defaultSetting = this.getSettingDefaultInstance(setting.title);
      if (defaultSetting === undefined) {
        return false;
      }
      const settingMapped = this.mapEpochToSource(setting);
      const defaultSettingMapped = this.mapEpochToSource(defaultSetting);
      return _.isEqual(settingMapped, defaultSettingMapped);
    });
  }

  public getDiff(a, b) {
    return _.reduce(
      a,
      function (result, value, key) {
        return _.isEqual(value, b[key]) ? result : result.concat(key);
      },
      []
    );
  }

  public saveNotificationSettings() {
    const payload = [];
    this.mappedSettings.forEach((setting) => {
      const body = this.mapEpochToSource(setting);
      const defaultSetting = this.mapEpochToSource(
        this.getSettingDefaultInstance(setting.title)
      );
      if (!_.isEqual(body, defaultSetting) && setting.title) {
        if (setting.id) {
          payload.push({
            settingId: setting.id,
            body,
          });
        } else {
          payload.push({
            body,
          });
        }
      }
    });
    this.updateSetting.emit(payload);
  }

  private getSettingDefaultInstance(
    settingTitle: NotificationTitle
  ): AlertNotificationSettingExt {
    return this.presetupSettings[settingTitle];
  }

  private setSettingDefaultInstance(
    setting: AlertNotificationSettingExt,
    settingTitle: NotificationTitle
  ) {
    this.presetupSettings[settingTitle] = setting;
  }

  public setMaxPerDay(notification: AlertNotificationSetting, value: number) {
    if (value < 0 || value > 10) {
      notification.maxPerDay = 1;
    } else if (!this.isProfileSettings && value === 0) {
      notification.maxPerDay = null;
    } else if (this.isProfileSettings && value === 0) {
      notification.maxPerDay = 1;
    } else {
      notification.maxPerDay = value;
    }
  }

  public setInterval(notification: AlertNotificationSetting, value: number) {
    if (value < 0 || value > 24) {
      notification.hoursInterval = 1;
    } else if (!this.isProfileSettings && value === 0) {
      notification.hoursInterval = null;
    } else if (this.isProfileSettings && value === 0) {
      notification.hoursInterval = 1;
    } else {
      notification.hoursInterval = value;
    }
  }

  public closeModal() {
    this.onCloseModal?.emit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.settings) {
      this.initNotificationSettings();
    }
  }

  private convertToEpochTime(date: string): EpochStateModel {
    return new EpochStateModel(
      EpochStateModelMinMaxMode.Time,
      date
        ? moment.utc(date).set({ date: 1, month: 0, year: 1970 }).valueOf() /
          1000
        : null
    );
  }

  private convertEpochToString(epoch: EpochStateModel) {
    return epoch && !(epoch.epochTime == undefined || epoch.epochTime === null)
      ? moment.utc(epoch.epochTime * 1000).format(DATE_FORMAT_WITH_Z)
      : null;
  }

  private mapFromSourceToEpoch(
    setting: AlertNotificationSetting
  ): AlertNotificationSettingExt {
    const timeFrom = this.convertToEpochTime(setting.timeFrom);
    const timeTo = this.convertToEpochTime(setting.timeTo);
    const res: AlertNotificationSettingExt = {
      ...setting,
      timeFrom,
      timeTo,
    };
    res.timeFrom.max = res.timeTo.epochTime;
    res.timeTo.min = res.timeFrom.epochTime;
    return res;
  }

  private mapEpochToSource(
    setting: AlertNotificationSettingExt
  ): AlertNotificationSetting {
    const timeFrom = this.convertEpochToString(
      setting.timeFrom as EpochStateModel
    );
    const timeTo = this.convertEpochToString(setting.timeTo as EpochStateModel);
    return {
      ...setting,
      timeFrom,
      timeTo,
    } as AlertNotificationSetting;
  }

  private initNotificationSettings() {
    const sOrder = [
      NotificationTitle.Temperature,
      NotificationTitle.WaterConsumption,
      NotificationTitle.GatewayDisconnect,
      NotificationTitle.Recharge,
    ];
    this.mappedSettings = sOrder.map((title) => {
      const setting = this.settings?.find((s) => s.title === title);
      if (!setting) {
        const defaultSetting = this.getSettingDefaultInstance(title);
        return _.cloneDeep(defaultSetting);
      } else {
        const settingInstance = this.mapFromSourceToEpoch(setting);
        this.setSettingDefaultInstance(_.cloneDeep(settingInstance), title);
        return settingInstance;
      }
    });
  }

  public selectNotificationSettingType(type) {
    if (type === this.openedSettingType) {
      this.openedSettingType = null;
    } else {
      this.openedSettingType = type;
    }
  }

  public onEpochChanged(epoch, notificationSettingType, prop) {
    notificationSettingType[prop] = epoch;

    notificationSettingType.timeFrom.max =
      notificationSettingType.timeTo._epochTime;
    notificationSettingType.timeTo.min =
      notificationSettingType.timeFrom._epochTime;
  }

  public ngOnInit(): void {
    this.settingsUpdated?.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.closeModal();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
