/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PermissionName } from '../models/permission-name';
import { Role } from '../models/role';
import { RoleDto } from '../models/role-dto';
import { RolePermissionsDto } from '../models/role-permissions-dto';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getRoles
   */
  static readonly GetRolesPath = '/api/role';

  /**
   * Get Roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles$Response(params?: {
    customerId?: number;
  }): Observable<StrictHttpResponse<Array<RoleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.GetRolesPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RoleDto>>;
      })
    );
  }

  /**
   * Get Roles.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles(params?: {
    customerId?: number;
  }): Observable<Array<RoleDto>> {

    return this.getRoles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RoleDto>>) => r.body as Array<RoleDto>)
    );
  }

  /**
   * Path part for operation addRole
   */
  static readonly AddRolePath = '/api/role';

  /**
   * Add Role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRole$Response(params: {
    body: RolePermissionsDto
  }): Observable<StrictHttpResponse<Role>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.AddRolePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Role>;
      })
    );
  }

  /**
   * Add Role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRole(params: {
    body: RolePermissionsDto
  }): Observable<Role> {

    return this.addRole$Response(params).pipe(
      map((r: StrictHttpResponse<Role>) => r.body as Role)
    );
  }

  /**
   * Path part for operation getPermissionsByRoleName
   */
  static readonly GetPermissionsByRoleNamePath = '/api/role/name/{roleName}';

  /**
   * Get Permissions By Role Name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPermissionsByRoleName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissionsByRoleName$Response(params: {
    roleName: string;
  }): Observable<StrictHttpResponse<Array<PermissionName>>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.GetPermissionsByRoleNamePath, 'get');
    if (params) {
      rb.path('roleName', params.roleName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PermissionName>>;
      })
    );
  }

  /**
   * Get Permissions By Role Name.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPermissionsByRoleName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissionsByRoleName(params: {
    roleName: string;
  }): Observable<Array<PermissionName>> {

    return this.getPermissionsByRoleName$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PermissionName>>) => r.body as Array<PermissionName>)
    );
  }

  /**
   * Path part for operation updateRole
   */
  static readonly UpdateRolePath = '/api/role/name/{roleName}';

  /**
   * Update Role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRole$Response(params: {
    roleName: string;
    body: RolePermissionsDto
  }): Observable<StrictHttpResponse<Role>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.UpdateRolePath, 'put');
    if (params) {
      rb.path('roleName', params.roleName, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Role>;
      })
    );
  }

  /**
   * Update Role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRole(params: {
    roleName: string;
    body: RolePermissionsDto
  }): Observable<Role> {

    return this.updateRole$Response(params).pipe(
      map((r: StrictHttpResponse<Role>) => r.body as Role)
    );
  }

  /**
   * Path part for operation deleteRole
   */
  static readonly DeleteRolePath = '/api/role/name/{roleName}';

  /**
   * Delete Role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRole$Response(params: {
    roleName: string;
  }): Observable<StrictHttpResponse<Role>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.DeleteRolePath, 'delete');
    if (params) {
      rb.path('roleName', params.roleName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Role>;
      })
    );
  }

  /**
   * Delete Role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRole(params: {
    roleName: string;
  }): Observable<Role> {

    return this.deleteRole$Response(params).pipe(
      map((r: StrictHttpResponse<Role>) => r.body as Role)
    );
  }

  /**
   * Path part for operation getAllPermissions
   */
  static readonly GetAllPermissionsPath = '/api/role/permission/all';

  /**
   * Get All Permissions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPermissions$Response(params?: {
  }): Observable<StrictHttpResponse<Array<PermissionName>>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.GetAllPermissionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PermissionName>>;
      })
    );
  }

  /**
   * Get All Permissions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPermissions(params?: {
  }): Observable<Array<PermissionName>> {

    return this.getAllPermissions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PermissionName>>) => r.body as Array<PermissionName>)
    );
  }

  /**
   * Path part for operation getPermissionsByRoleId
   */
  static readonly GetPermissionsByRoleIdPath = '/api/role/{roleId}';

  /**
   * Get Permissions By Role Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPermissionsByRoleId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissionsByRoleId$Response(params: {
    roleId: number;
  }): Observable<StrictHttpResponse<Array<PermissionName>>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.GetPermissionsByRoleIdPath, 'get');
    if (params) {
      rb.path('roleId', params.roleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PermissionName>>;
      })
    );
  }

  /**
   * Get Permissions By Role Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPermissionsByRoleId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermissionsByRoleId(params: {
    roleId: number;
  }): Observable<Array<PermissionName>> {

    return this.getPermissionsByRoleId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PermissionName>>) => r.body as Array<PermissionName>)
    );
  }

  /**
   * Path part for operation updateRole_1
   */
  static readonly UpdateRole_1Path = '/api/role/{roleId}';

  /**
   * Update Role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRole_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRole_1$Response(params: {
    roleId: number;
    body: RolePermissionsDto
  }): Observable<StrictHttpResponse<Role>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.UpdateRole_1Path, 'put');
    if (params) {
      rb.path('roleId', params.roleId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Role>;
      })
    );
  }

  /**
   * Update Role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRole_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRole_1(params: {
    roleId: number;
    body: RolePermissionsDto
  }): Observable<Role> {

    return this.updateRole_1$Response(params).pipe(
      map((r: StrictHttpResponse<Role>) => r.body as Role)
    );
  }

  /**
   * Path part for operation deleteRole_1
   */
  static readonly DeleteRole_1Path = '/api/role/{roleId}';

  /**
   * Delete Role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRole_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRole_1$Response(params: {
    roleId: number;
  }): Observable<StrictHttpResponse<Role>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.DeleteRole_1Path, 'delete');
    if (params) {
      rb.path('roleId', params.roleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Role>;
      })
    );
  }

  /**
   * Delete Role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRole_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRole_1(params: {
    roleId: number;
  }): Observable<Role> {

    return this.deleteRole_1$Response(params).pipe(
      map((r: StrictHttpResponse<Role>) => r.body as Role)
    );
  }

  /**
   * Path part for operation getUsersByRoleId
   */
  static readonly GetUsersByRoleIdPath = '/api/role/{roleId}/user';

  /**
   * Get Users By Role Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersByRoleId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersByRoleId$Response(params: {
    roleId: number;
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.GetUsersByRoleIdPath, 'get');
    if (params) {
      rb.path('roleId', params.roleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get Users By Role Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsersByRoleId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersByRoleId(params: {
    roleId: number;
  }): Observable<Array<string>> {

    return this.getUsersByRoleId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

}
