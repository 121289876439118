<div class="popup__body-header">
  {{ 'COMMON.BUTTONS.Start_a_new_crop' | translate }}
  <div class="popup__body-close">
    <span class="svg close-svg-blue" (click)="cancel()"></span>
  </div>
</div>
<div class="stepper">
  <div class="step"
       [class.text-border-main-blue]="maxStep >= 1"
       [class.bold-text-border-main-blue]="maxStep >= 2"
  >
    <span [class.color-white]="maxStep >= 2">1</span>
    <div class="step-text">{{ 'CROP_CARD.Crop_Initiation' | translate }}</div>
  </div>
  <div class="line" [class.border-bottom-main-blue]="maxStep >= 1"></div>
  <div class="step" [class.text-border-main-blue]="maxStep >= 2"
       [class.bold-text-border-main-blue]="maxStep === 3">
    <span [class.color-white]="maxStep === 3">2</span>
    <div class="step-text">{{ 'CROP_CARD.Vaccine_Plan' | translate }}</div>
  </div>
  <div class="line" [class.border-bottom-main-blue]="maxStep === 3"></div>
  <div class="step" [class.text-border-main-blue]="maxStep === 3">
    <span>3</span>
    <div class="step-text">{{ 'CROP_CARD.Nutrition_plan' | translate }}</div>
  </div>
</div>
<div class="width80percent margin0auto" [class.none]="currentStep !== 1">
  <div class="menus flex-wrap">
    <div class="menus__menu width244">
      <div class="menus__menu-label">{{ 'COMMON.SELECTORS.Customer' | translate }}</div>
      <div class="menus__menu-selector">
        <div class="disabled"> {{ customerSelectorsService.currentCustomer?.name }}</div>
      </div>
    </div>
    <div class="menus__menu width244">
      <div class="menus__menu-label">{{ 'COMMON.SELECTORS.Farm' | translate }}</div>
      <div class="menus__menu-selector">
        <div class="disabled"> {{ customerSelectorsService.currentFarm?.name }}</div>
      </div>
    </div>
    <div class="menus__menu width244">
      <div class="menus__menu-label">{{ 'COMMON.SELECTORS.House' | translate }}</div>
      <div class="menus__menu-selector">
        <div class="disabled"> {{ customerSelectorsService.currentHouse?.name }}</div>
      </div>
    </div>
    <div class="menus__menu width244">
      <div class="menus__menu-label">{{ 'CROP_CARD.Crop' | translate }}</div>
      <div class="menus__menu-selector">
        <div class="disabled">{{ crop }}</div>
      </div>
    </div>

    <div class="menus__menu width244">
      <div class="menus__menu-label">{{ 'CROP_CARD.Chicks_Source' | translate }}</div>
      <div class="menus__menu-selector">
        <regular-select (change)="chicksSourceChanged($event.target.value)">
          <option #option selected
                  [value]="cultivationTypesEnum.ChickPlacement">{{ 'CROP_CARD.Day_old_Chick_Placement' | translate }}
          </option>
          <option #option
                  [value]="cultivationTypesEnum.FarmHatching">{{ 'CROP_CARD.On_Farm_Hatching' | translate }}
          </option>
        </regular-select>
      </div>
    </div>
    <div class="menus__menu width244" *ngIf="selectedCultivationType === cultivationTypesEnum.FarmHatching">
      <div class="menus__menu-label">{{ 'CROP_CARD.Date_of_Egg_Placement' | translate }}</div>
      <div class="menus__menu-selector">
        <!--    [max]="day0Date"     -->
        <date-picker [min]="minDate"
                     [value]="eggPlacementDate"
                     (valueChange)="changeOpenCropDateAndMaxEggPlacementDate($event)"></date-picker>
      </div>
    </div>
    <div class="menus__menu width244" *ngIf="selectedCultivationType === cultivationTypesEnum.FarmHatching">
      <div class="menus__menu-label">{{ 'COMMON.time' | translate }}</div>
      <div class="menus__menu-selector">
        <time-picker class="create-batch-event-content-control"
                     [shownRemovableIcon]="false"
                     (epochChanged)="onEpochChanged($event)"
                     [selectedEpoch]="epochSelectedDate"></time-picker>
      </div>
    </div>
    <div class="menus__menu width244" [class.none]="selectedCultivationType !== cultivationTypesEnum.FarmHatching">
      <div class="menus__menu-label">{{ 'CROP_CARD.Day_0_Date' | translate }}</div>
      <div class="menus__menu-selector">
        <date-picker [min]="eggPlacementDate"
                     [value]="day0Date"
                     [max]="maxDay0Date"
                     [disabled]="!eggPlacementDate"
                     (valueChange)="changeDay0Date($event)"></date-picker>
      </div>
    </div>
    <div class="menus__menu width244" *ngIf="selectedCultivationType !== cultivationTypesEnum.FarmHatching">
      <div class="menus__menu-label">{{ 'CROP_CARD.Day_0_Date' | translate }}</div>
      <div class="menus__menu-selector">
        <date-picker [min]="minDate"
                     [value]="day0Date"
                     (valueChange)="changeDay0Date($event)"></date-picker>
      </div>
    </div>
    <div class="menus__menu width244" [class.none]="selectedCultivationType === cultivationTypesEnum.FarmHatching">
      <div class="menus__menu-label">{{ 'COMMON.time' | translate }}</div>
      <div class="menus__menu-selector">
        <time-picker class="create-batch-event-content-control"
                     [shownRemovableIcon]="false"
                     (epochChanged)="onEpochChanged($event)"
                     [selectedEpoch]="epochSelectedDate"></time-picker>
      </div>
    </div>
    <div class="menus__menu width244">
      <div class="menus__menu-label">{{ 'CROP_CARD.Water_Ratio' | translate }}</div>
      <div class="menus__menu-selector">
        <input name="broodingDuration"
               [options]="{
                digitGroupSeparator: translationService.digitGroupSeparator,
                decimalCharacter: translationService.decimalCharacter,
                decimalPlaces: 1,
                minimumValue: '0',
                maximumValue: '3'
              }"
               autonumeric
               [(ngModel)]="waterFeedRatio">
      </div>
    </div>
    <div class="menus__menu width244" [class.none]="!permissionService.hasPermission(myFarmCropCardAddBrooding)">
      <div class="menus__menu-label">{{ 'CROP_CARD.House_Brooding' | translate }}</div>
      <div class="menus__menu-selector">
        <regular-dropdown-list
          [items]="broodingModes"
          [allowClear]="false"
          [selectedValue]="selectedBroodingMode"
          (onItemSelected)="houseBroodingModeChanged($event)">
        </regular-dropdown-list>
      </div>
    </div>
    <div class="menus__menu width244" *ngIf="selectedBroodingMode.value === 'MANUAL'">
      <div class="menus__menu-label">{{ 'CROP_CARD.Part_House_Brooding_Days' | translate }}</div>
      <div class="menus__menu-selector">
        <input name="broodingDuration"
               type="number"
               [min]="0"
               [max]="broodingService.getMaxBroodingDuration(selectedDurationForInitialBrooding)"
               [(ngModel)]="selectedDurationForInitialBrooding"
               (blur)="setValueIfLessThanOne()"
               (input)="setSelectedDurationForInitialBrooding(utilsService.toNormalValue($event.target.value))">
      </div>
    </div>
  </div>

  <div class="identifiers display-flex space-between">
    <div class="width-50 display-flex align-center">
      <span>{{ 'CROP_CARD.Integrator_Specific_Identifier_No' | translate }} <b>{{ acpNumber }}</b></span>
      <div class="identifiers__select">
        <div *ngIf="isShowSex(harvestSex.Male)"
             [class.active]="newCrop.flockType === harvestSex.Male" (click)="setFlockType(harvestSex.Male)">
          {{ 'CROP_CARD.Male' | translate }}
        </div>
        <div *ngIf="isShowSex(harvestSex.Female)"
             [class.active]="newCrop.flockType === harvestSex.Female" (click)="setFlockType(harvestSex.Female)">
          {{ 'CROP_CARD.Female' | translate }}
        </div>
        <div *ngIf="isShowSex(harvestSex.AsHatched)"
             [class.active]="newCrop.flockType === harvestSex.AsHatched"
             (click)="setFlockType(harvestSex.AsHatched)">
          {{ 'CROP_CARD.As-hatched' | translate }}
        </div>
      </div>
    </div>
    <div class="zones-wrapper">
      <selectable-zones
        *ngIf="selectedBroodingMode.value === 'MANUAL'"
        [zonesInUse]="!!veryFirstBroodingEvent? veryFirstBroodingEvent?.values[0]?.value : ''"
        (onSelectedZones)="setSelectedBroodingZones($event)"
      ></selectable-zones>
    </div>
  </div>
  <form>
    <div class="display-flex">
      <div class="menus__menu mt-43 ml-10 ">
        <div class="menus__menu-label">{{ 'CROP_CARD.Total_Number_of_Chicks' | translate }}</div>
        <div class="menus__menu-selector change-padding">
          {{ totalNumberOfChicks | numberFormatter : 0 }}
        </div>
      </div>
      <div class="menus__menu mt-43 ml-10 ">
        <div class="menus__menu-label">{{ 'CROP_CARD.Breed' | translate }}</div>
        <div class="menus__menu-selector change-padding">
          <regular-dropdown-list #breed
                                 [selectedValue]="selectedBrand"
                                 [allowClear]="true"
                                 [items]="brandList"
                                 (onItemSelected)="brandSelected($event)">
          </regular-dropdown-list>
        </div>
      </div>

      <div class="clearance-wrapper">
        <div class="clearance">
          <!--          <div class="line"></div>-->
          <div class="text">{{ 'CROP_CARD.At_Clearance' |translate }}</div>
          <!--          <div class="line"></div>-->
        </div>
        <div class="display-flex">
          <div class="menus__menu width110 ml-10">
            <div class="menus__menu-label">{{ 'LOCALES.UNITS.KG' | translate }}</div>
            <div class="menus__menu-selector kg-bird-age">
              <input name="weight"
                     [options]="{
                digitGroupSeparator: translationService.digitGroupSeparator,
                decimalCharacter: translationService.decimalCharacter,
                decimalPlaces: 3,
                minimumValue: '0',
              }"
                     autonumeric
                     [(ngModel)]="backUpWeights['targetWeightAtClearance']"
                     (change)="backUpWeights['targetWeightAtClearance'] = utilsService.toNormalValue($event.target.value)">
            </div>
          </div>
          <div class="menus__menu width110 ml-10">
            <div class="menus__menu-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
            <div class="menus__menu-selector kg-bird-age">
              <input type="number"
                     name="birdAge"
                     min="0"
                     oninput="validity.valid||(value='');"
                     [(ngModel)]="newCrop.targetBirdAgeAtClearance"
                     (change)="changeBirdAge($event.target.value)">
            </div>
          </div>
        </div>
      </div>

      <div class="menus__menu mt-43 ml-19 ">
        <div class="menus__menu-label">{{ 'CROP_CARD.Maximum_stocking_density' | translate }}</div>
        <div class="menus__menu-selector change-padding">
          <input
            name="maxStockingDensity"
            [options]="{
                digitGroupSeparator: translationService.digitGroupSeparator,
                decimalCharacter: translationService.decimalCharacter,
                decimalPlaces: 0,
                minimumValue: '0',
                maximumValue: '50'
              }"
            autonumeric
            [(ngModel)]="newCrop.maxStockingDensity">
        </div>
      </div>
      <div class="menus__menu mt-43 ml-10 ">
        <div class="menus__menu-label">{{ 'CROP_CARD.In-hatchery_Probiotic' | translate }}</div>
        <div class="menus__menu-selector change-padding">
          <regular-dropdown-list
            #probiotic
            [selectedValue]="selectedProbiotic"
            [allowClear]="true"
            [items]="probioticList"
            (onItemSelected)="probioticSelected($event)">
          </regular-dropdown-list>
        </div>
      </div>
    </div>
  </form>

  <!--Hatchery Vaccines-->
  <div class="display-flex width-100 position-relative"
       *ngFor="let hatcheryVaccine of newCrop.hatcheryVaccines; let i = index;"
       [class.none]="currentStep !== 1">
    <div class="menus__menu">
      <div class="menus__menu-selector height-60">
        <div class="menus__menu-selector-label">{{ "EVENT.Country" | translate }}</div>
        <regular-dropdown-list
          [items]="countries"
          [allowClear]="false"
          [selectedValue]="selectedCountry"
          (onItemSelected)="countryChanged($event)">
        </regular-dropdown-list>
      </div>
    </div>
    <div class="width-75">
      <hatchery-vaccines
        [event]="hatcheryVaccine"
        [rowIndex]="i"
        [possibleDeleteFirst]="true"
        [vaccination]="hatcheryVaccination[selectedCountry.value]"
        (onTypeChanged)="newCrop.hatcheryVaccines[i].type = $event"
        (onDiseaseChanged)="newCrop.hatcheryVaccines[i].disease = $event"
        (onVaccineChanged)="newCrop.hatcheryVaccines[i].vaccine = $event"
        (removeEvent)="removeHatcheryVaccine(newCrop.hatcheryVaccines[i])">
      </hatchery-vaccines>
    </div>
  </div>

  <div class="add-flock" *ngIf="newCrop.hatcheryVaccines.length < 6" (click)="addHatchery()"
       [class.none]="currentStep !== 1">
    <span>+</span>
    {{ 'CROP_CARD.Add_Hatchery_Vaccine' | translate }}
  </div>

  <!--FLOCK-->
  <div class="display-flex width-100 position-relative" *ngFor="let flock of newCrop.deliveryNotes; let i = index;"
       [class.none]="currentStep !== 1">
    <div class="menus__menu">
      <div class="menus__menu-label">{{ 'CROP_CARD.crop_code' | translate }}</div>
      <div class="menus__menu-selector">
        <input [value]="flock.harvestCode"
               (change)="newCrop.deliveryNotes[i].harvestCode = $event.target.value">
      </div>
    </div>
    <div class="menus__menu">
      <div class="menus__menu-selector" (click)="isTouched = true">
        <div class="menus__menu-label">{{ 'CROP_CARD.Hatchery' | translate }}</div>
        <ejs-autocomplete
          [dataSource]='hatcheryNamesList'
          [allowFiltering]='true'
          [allowCustom]="isAllowCustomHatchery"
          [autofill]="true"
          [fields]="fields"
          [sortOrder]="'Ascending'"
          [filterType]="'Contains'"
          [value]="getSelectedNote(i)"
          (filtering)="onFiltering($event)"
          (focus)="isAllowCustomHatchery = false"
          (change)="setHatcheryName($event?.value, i)"
          placeholder=''
        ></ejs-autocomplete>
      </div>
    </div>
    <div class="menus__menu">
      <div class="menus__menu-label">{{ 'CROP_CARD.Number_of_chicks' | translate }}</div>
      <div class="menus__menu-selector">
        <input
          *ngIf="customerSelectorsService.currentHouse.houseType !== HouseType.Outside"
          name="numberOfChicks"
          [options]="{
                digitGroupSeparator: translationService.digitGroupSeparator,
                decimalCharacter: translationService.decimalCharacter,
                decimalPlaces: 0,
                minimumValue: '0',
              }"
          autonumeric
          [(ngModel)]="flock.chicksValue"
          (change)="newCrop.deliveryNotes[i].chicksValue = utilsService.toNormalValue($event.target.value); calculateTotalNumberOfChicks()"/>
        <input *ngIf="customerSelectorsService.currentHouse.houseType === HouseType.Outside" value="0" disabled/>
      </div>
    </div>
    <div class="menus__menu">
      <div class="menus__menu-label">{{ 'CROP_CARD.Parent_crop_age_(weeks)' | translate }}</div>
      <div class="menus__menu-selector">
        <input
          name="parentCropAge"
          [options]="{
                digitGroupSeparator: translationService.digitGroupSeparator,
                decimalCharacter: translationService.decimalCharacter,
                decimalPlaces: 0,
                minimumValue: '0',
                maximumValue: '100'
              }"
          autonumeric
          [(ngModel)]="flock.parentHarvestAge"
          (change)="newCrop.deliveryNotes[i].parentHarvestAge = $event.target.value"
          (blur)="setValueIfUserPutUnexpected(
                          utilsService.toNormalValue($event.target.value), 'parentCropAge', i)">
      </div>
    </div>
    <div class="menus__menu">
      <div class="menus__menu-label">
        {{ 'CROP_CARD.Av_weight' | translate }}
        {{ 'LOCALES.UNITS.Kg' | translate }}
        <span class="hint">
            {{ 'CROP_CARD.Av_weight_range_hint' | translate }}
          </span>
      </div>
      <div class="menus__menu-selector">
        <input
          name="avWeight"
          [options]="{
                digitGroupSeparator: translationService.digitGroupSeparator,
                decimalCharacter: translationService.decimalCharacter,
                decimalPlaces: 3,
                minimumValue: '0',
                maximumValue: MAX_AV_WEIGHT
              }"
          autonumeric
          [(ngModel)]="backUpWeights['avWeights'][i]"
          (change)="backUpWeights['avWeights'][i] = utilsService.toNormalValue($event.target.value)"
          (blur)="setValueIfUserPutUnexpected(
                          utilsService.toNormalValue($event.target.value), 'avWeight', i)">
      </div>
    </div>
    <div class="flock-remover">
      <div *ngIf="i > 0" (click)="removeFlock(newCrop.deliveryNotes[i], i)" class="remove-flock">
        <span class="svg delete-blue"></span>
      </div>
    </div>

  </div>

  <div class="add-flock" (click)="addFlock()" [class.none]="currentStep !== 1">
    <span>+</span>
    {{ 'CROP_CARD.Add_a_new_crop' | translate }}
  </div>
</div>


<!--STEP 2-->
<div class="width80percent margin0auto" [class.none]="currentStep !== 2">
  <div class="event-label">{{ 'CROP_CARD.Vaccination_Plan' | translate }}</div>

  <div class="width-100" *ngFor="let event of previousVaccineEvents; let i = index">
    <vaccination-event
      [event]="event"
      [functionalMode]="eventFunctionalMode"
      [day0Date]="day0Date"
      [rowIndex]="i"
      [possibleDeleteFirst]="true"
      [vaccination]="vaccination[selectedCountry.value]"
      (dateChanged)="previousVaccineEvents[i].date = $event"
      (onTypeChanged)="previousVaccineEvents[i].values[1].value = $event"
      (onDiseaseChanged)="previousVaccineEvents[i].values[0].value = $event"
      (onVaccineChanged)="previousVaccineEvents[i].values[2].value = $event"
      (removeEvent)="removeEvent(i, 'Add Vaccination')">
    </vaccination-event>
  </div>
  <div class="add-flock" *ngIf="previousVaccineEvents.length < 5" (click)="addEvent('Add Vaccination')">
    <span>+</span>
    {{ 'CROP_CARD.add_event' | translate }}
  </div>
</div>
<!--STEP 3-->
<div class="width80percent margin0auto" [class.none]="currentStep !== 3">
  <!--  FEED-->
  <div class="event-label">{{ 'CROP_CARD.Feed_Event' | translate }}</div>
  <div class="width-100" *ngFor="let event of previousFeedEvents; let i = index">
    <feed-event
      [event]="event"
      [day0Date]="day0Date"
      [rowIndex]="i"
      [functionalMode]="eventFunctionalMode"
      [possibleDeleteFirst]="true"
      [feed]="feed"
      (dateChanged)="previousFeedEvents[i].date = $event"
      (onCoccidiostatChanged)="previousFeedEvents[i].values[1].value = $event"
      (onAdditivesChanged)="previousFeedEvents[i].values[0].value = $event"
      (onRationChanged)="previousFeedEvents[i].values[3].value = $event"
      (onKgChanged)="previousFeedEvents[i].values[4].value = $event"
      (onBatchNumberChanged)="previousFeedEvents[i].values[5].value = $event"
      (onMillChanged)="setMillValue($event, i)"
      (removeEvent)="removeEvent(i, 'Add Feed')">
      >
    </feed-event>
  </div>
  <div class="add-flock" *ngIf="previousFeedEvents.length < 10" (click)="addEvent('Add Feed')">
    <span>+</span>
    {{ 'CROP_CARD.add_event' | translate }}
  </div>
  <!--  NUTRACEUTICAL-->
  <div class="event-label">{{ 'CROP_CARD.Nutraceutical_Event' | translate }}</div>
  <div class="width-100" *ngFor="let event of previousNutraceuticalEvents; let i = index">
    <nutraceutical-event
      [event]="event"
      [day0Date]="day0Date"
      [rowIndex]="i"
      [functionalMode]="eventFunctionalMode"
      [possibleDeleteFirst]="true"
      [nutraceutical]="nutraceuticals"
      (dateChanged)="previousNutraceuticalEvents[i].date = $event"
      (onNutraceuticalChanged)="previousNutraceuticalEvents[i].values[1].value = $event"
      (onReasonChanged)="previousNutraceuticalEvents[i].values[2].value = $event"
      (onDurationChanged)="previousNutraceuticalEvents[i].values[0].value = $event"
      (removeEvent)="removeEvent(i, 'Add Nutraceutical')">
      >
    </nutraceutical-event>
  </div>
  <div class="add-flock" *ngIf="previousNutraceuticalEvents.length < 10" (click)="addEvent('Add Nutraceutical')">
    <span>+</span>
    {{ 'CROP_CARD.add_event' | translate }}
  </div>
  <div class="event-label">{{ 'CROP_CARD.Other_Event' | translate }}</div>
  <div class="width-100" *ngFor="let event of previousAllOtherEvents; let i = index">
    <!--    MEDICATION   -->
    <div class="display-flex" *ngIf="event.type === eventTypesEnum.AddMedication">
      <div class="menus__menu additional-margin width-15">
        <div class="menus__menu-selector-label custom-type-label">{{ "EVENT.select_event_type" | translate }}</div>
        <div class="menus__menu-selector">
          <regular-select (change)="otherEventTypeChanged($event.target.value, i)">
            <option #option
                    selected
                    [value]="eventTypesEnum.AddMedication">{{ "EVENT.add_medication" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.FlushWaterLine">{{ "EVENT.FlushWaterLine" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.WaterSanitation">{{ "EVENT.WaterSanitation" | translate }}
            </option>
            <!--NOTE: LEAVE IT FOR NOW, It should be included in next updates-->
            <!--            <option #option-->
            <!--                    *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.PlannedThinningAges) > 0"-->
            <!--                    [value]="eventTypesEnum.PlannedThinningAges">{{"EVENT.PlannedThinningAges" | translate}}</option>-->
            <option #option
                    [value]="eventTypesEnum.PlannedHandWeighingOfBirds">{{ "EVENT.PlannedHandWeighingOfBirds" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.PlannedFootpadScoringOfBirds">{{ "EVENT.PlannedFootpadScoringOfBirds" | translate }}
            </option>
            <option #option
                    *ngIf="selectedBroodingMode === broodingModes[1] && !isAllZonesSelected && broodingService.broodingDurationRemaining > 0 && permissionService.hasPermission(myFarmCropCardAddBrooding)"
                    [value]="eventTypesEnum.Brooding">{{ "EVENT.Brooding" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.AddThinning">{{ "EVENT.Add_Thinning" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.OtherEvents">{{ "EVENT.OtherEvents" | translate }}
            </option>
          </regular-select>
        </div>
      </div>
      <div class="width-85 additional-margin">
        <medication-event
          [event]="event"
          [day0Date]="day0Date"
          [rowIndex]="i"
          [functionalMode]="eventFunctionalMode"
          [possibleDeleteFirst]="true"
          [medication]="medication"
          (dateChanged)="previousAllOtherEvents[i].date = $event"
          (onMedicationDurationChanged)="previousAllOtherEvents[i].values[1].value = $event"
          (onmMedicationDiseaseChanged)="previousAllOtherEvents[i].values[0].value = $event"
          (onAntibioticsChanged)="previousAllOtherEvents[i].values[2].value = $event"
          (removeEvent)="removeEvent(i, eventTypesEnum.AddMedication)">
        </medication-event>
      </div>
    </div>
    <!--    Add Thinning   -->
    <div class="display-flex" *ngIf="event.type === eventTypesEnum.AddThinning">
      <div class="menus__menu additional-margin width-15">
        <div class="menus__menu-selector-label custom-type-label">{{ "EVENT.select_event_type" | translate }}</div>
        <div class="menus__menu-selector">
          <regular-select (change)="otherEventTypeChanged($event.target.value, i)">
            <option #option
                    [value]="eventTypesEnum.AddMedication">{{ "EVENT.add_medication" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.FlushWaterLine">{{ "EVENT.FlushWaterLine" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.WaterSanitation">{{ "EVENT.WaterSanitation" | translate }}
            </option>
            <!--NOTE: LEAVE IT FOR NOW, It should be included in next updates-->
            <!--            <option #option-->
            <!--                    *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.PlannedThinningAges) > 0"-->
            <!--                    [value]="eventTypesEnum.PlannedThinningAges">{{"EVENT.PlannedThinningAges" | translate}}</option>-->
            <option #option
                    [value]="eventTypesEnum.PlannedHandWeighingOfBirds">{{ "EVENT.PlannedHandWeighingOfBirds" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.PlannedFootpadScoringOfBirds">{{ "EVENT.PlannedFootpadScoringOfBirds" | translate }}
            </option>
            <option #option
                    *ngIf="selectedBroodingMode === broodingModes[1] && !isAllZonesSelected && broodingService.broodingDurationRemaining > 0 && permissionService.hasPermission(myFarmCropCardAddBrooding)"
                    [value]="eventTypesEnum.Brooding">{{ "EVENT.Brooding" | translate }}
            </option>
            <option #option
                    selected
                    [value]="eventTypesEnum.AddThinning">{{ "EVENT.Add_Thinning" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.OtherEvents">{{ "EVENT.OtherEvents" | translate }}
            </option>
          </regular-select>
        </div>
      </div>
      <div class="width-85 additional-margin">
        <thinning-event
          [event]="event"
          [currentHarvest]="currentHarvest"
          [functionalMode]="eventFunctionalMode"
          [rowIndex]="i"
          [cropType]="newCrop.flockType"
          [day0Date]="day0Date"
          [cropNumber]="cropNumber"
          (dateChanged)="changeSelectedDateTime($event, i)"
          (numberOfBirdsChanged)="previousAllOtherEvents[i].values[1].value = $event"
          (totalKgChanged)="previousAllOtherEvents[i].values[0].value = $event"
          (removeEvent)="removeEvent(i, eventTypesEnum.AddThinning)">
        </thinning-event>
      </div>
    </div>
    <!--    FlushWaterLine-->
    <div class="display-flex" *ngIf="event.type === eventTypesEnum.FlushWaterLine">
      <div class="menus__menu additional-margin width-15">
        <div class="menus__menu-selector-label custom-type-label">{{ "EVENT.select_event_type" | translate }}</div>
        <div class="menus__menu-selector">
          <regular-select (change)="otherEventTypeChanged($event.target.value, i)">
            <option #option
                    [value]="eventTypesEnum.AddMedication">{{ "EVENT.add_medication" | translate }}
            </option>
            <option #option
                    selected
                    [value]="eventTypesEnum.FlushWaterLine">{{ "EVENT.FlushWaterLine" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.WaterSanitation">{{ "EVENT.WaterSanitation" | translate }}
            </option>
            <!--NOTE: LEAVE IT FOR NOW, It should be included in next updates-->
            <!--            <option #option-->
            <!--                    *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.PlannedThinningAges) > 0"-->
            <!--                    [value]="eventTypesEnum.PlannedThinningAges">{{"EVENT.PlannedThinningAges" | translate}}</option>-->
            <option #option
                    [value]="eventTypesEnum.PlannedHandWeighingOfBirds">{{ "EVENT.PlannedHandWeighingOfBirds" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.PlannedFootpadScoringOfBirds">{{ "EVENT.PlannedFootpadScoringOfBirds" | translate }}
            </option>
            <option #option
                    [class.none]="!permissionService.hasPermission(myFarmCropCardAddBrooding)"
                    *ngIf="selectedBroodingMode === broodingModes[1] && !isAllZonesSelected && broodingService.broodingDurationRemaining > 0 && permissionService.hasPermission(myFarmCropCardAddBrooding)"
                    [value]="eventTypesEnum.Brooding">{{ "EVENT.Brooding" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.AddThinning">{{ "EVENT.Add_Thinning" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.OtherEvents">{{ "EVENT.OtherEvents" | translate }}
            </option>
          </regular-select>
        </div>
      </div>
      <div class="width-85 additional-margin">
        <flush-water-line
          [event]="event"
          [day0Date]="day0Date"
          [rowIndex]="i"
          [functionalMode]="eventFunctionalMode"
          [possibleDeleteFirst]="true"
          (onCommentChanged)="previousAllOtherEvents[i].values[0].value = $event"
          (dateChanged)="previousAllOtherEvents[i].date = $event"
          (removeEvent)="removeEvent(i, eventTypesEnum.FlushWaterLine)"
        >
        </flush-water-line>
      </div>
    </div>
    <!--    WaterSanitation-->
    <div class="display-flex" *ngIf="event.type === eventTypesEnum.WaterSanitation">
      <div class="menus__menu additional-margin width-15">
        <div class="menus__menu-selector-label custom-type-label">{{ "EVENT.select_event_type" | translate }}</div>
        <div class="menus__menu-selector">
          <regular-select (change)="otherEventTypeChanged($event.target.value, i)">
            <option #option

                    [value]="eventTypesEnum.AddMedication">{{ "EVENT.add_medication" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.FlushWaterLine">{{ "EVENT.FlushWaterLine" | translate }}
            </option>
            <option #option
                    selected
                    [value]="eventTypesEnum.WaterSanitation">{{ "EVENT.WaterSanitation" | translate }}
            </option>
            <!--NOTE: LEAVE IT FOR NOW, It should be included in next updates-->
            <!--            <option #option-->
            <!--                    *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.PlannedThinningAges) > 0"-->
            <!--                    [value]="eventTypesEnum.PlannedThinningAges">{{"EVENT.PlannedThinningAges" | translate}}</option>-->
            <option #option
                    [value]="eventTypesEnum.PlannedHandWeighingOfBirds">{{ "EVENT.PlannedHandWeighingOfBirds" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.PlannedFootpadScoringOfBirds">{{ "EVENT.PlannedFootpadScoringOfBirds" | translate }}
            </option>
            <option #option
                    *ngIf="selectedBroodingMode === broodingModes[1] && !isAllZonesSelected && broodingService.broodingDurationRemaining > 0 && permissionService.hasPermission(myFarmCropCardAddBrooding)"
                    [value]="eventTypesEnum.Brooding">{{ "EVENT.Brooding" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.AddThinning">{{ "EVENT.Add_Thinning" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.OtherEvents">{{ "EVENT.OtherEvents" | translate }}
            </option>
          </regular-select>
        </div>
      </div>
      <div class="width-85 additional-margin">
        <water-sanitation
          [event]="event"
          [day0Date]="day0Date"
          [rowIndex]="i"
          [functionalMode]="eventFunctionalMode"
          [possibleDeleteFirst]="true"
          (onCommentChanged)="previousAllOtherEvents[i].values[0].value = $event"
          (dateChanged)="previousAllOtherEvents[i].date = $event"
          (removeEvent)="removeEvent(i, eventTypesEnum.WaterSanitation)"
        >
        </water-sanitation>
      </div>
    </div>
    <!--    PlannedHandWeighingOfBirds-->
    <div class="display-flex" *ngIf="event.type === eventTypesEnum.PlannedHandWeighingOfBirds">
      <div class="menus__menu additional-margin width-15">
        <div class="menus__menu-selector-label custom-type-label">{{ "EVENT.select_event_type" | translate }}</div>
        <div class="menus__menu-selector">
          <regular-select (change)="otherEventTypeChanged($event.target.value, i)">
            <option #option
                    [value]="eventTypesEnum.AddMedication">{{ "EVENT.add_medication" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.FlushWaterLine">{{ "EVENT.FlushWaterLine" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.WaterSanitation">{{ "EVENT.WaterSanitation" | translate }}
            </option>
            <!--NOTE: LEAVE IT FOR NOW, It should be included in next updates-->
            <!--            <option #option-->
            <!--                    *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.PlannedThinningAges) > 0"-->
            <!--                    [value]="eventTypesEnum.PlannedThinningAges">{{"EVENT.PlannedThinningAges" | translate}}</option>-->
            <option #option
                    selected
                    [value]="eventTypesEnum.PlannedHandWeighingOfBirds">{{ "EVENT.PlannedHandWeighingOfBirds" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.PlannedFootpadScoringOfBirds">{{ "EVENT.PlannedFootpadScoringOfBirds" | translate }}
            </option>
            <option #option
                    *ngIf="selectedBroodingMode === broodingModes[1] && !isAllZonesSelected && broodingService.broodingDurationRemaining > 0 && permissionService.hasPermission(myFarmCropCardAddBrooding)"
                    [value]="eventTypesEnum.Brooding">{{ "EVENT.Brooding" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.AddThinning">{{ "EVENT.Add_Thinning" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.OtherEvents">{{ "EVENT.OtherEvents" | translate }}
            </option>
          </regular-select>
        </div>
      </div>
      <div class="width-85 additional-margin">
        <planned-hand-weight
          [event]="event"
          [day0Date]="day0Date"
          [rowIndex]="i"
          [functionalMode]="eventFunctionalMode"
          [possibleDeleteFirst]="true"
          (onCommentChanged)="previousAllOtherEvents[i].values[0].value = $event"
          (onAvWeightChanged)="previousAllOtherEvents[i].values[1].value = $event"
          (dateChanged)="previousAllOtherEvents[i].date = $event"
          (removeEvent)="removeEvent(i, eventTypesEnum.PlannedHandWeighingOfBirds)"
        >
        </planned-hand-weight>
      </div>
    </div>
    <!--    PlannedFootpadScoringOfBirds-->
    <div class="display-flex" *ngIf="event.type === eventTypesEnum.PlannedFootpadScoringOfBirds">
      <div class="menus__menu additional-margin width-15">
        <div class="menus__menu-selector-label custom-type-label">{{ "EVENT.select_event_type" | translate }}</div>
        <div class="menus__menu-selector">
          <regular-select (change)="otherEventTypeChanged($event.target.value, i)">
            <option #option
                    [value]="eventTypesEnum.AddMedication">{{ "EVENT.add_medication" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.FlushWaterLine">{{ "EVENT.FlushWaterLine" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.WaterSanitation">{{ "EVENT.WaterSanitation" | translate }}
            </option>
            <!--NOTE: LEAVE IT FOR NOW, It should be included in next updates-->
            <!--            <option #option-->
            <!--                    *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.PlannedThinningAges) > 0"-->
            <!--                    [value]="eventTypesEnum.PlannedThinningAges">{{"EVENT.PlannedThinningAges" | translate}}</option>-->
            <option #option
                    [value]="eventTypesEnum.PlannedHandWeighingOfBirds">{{ "EVENT.PlannedHandWeighingOfBirds" | translate }}
            </option>
            <option #option
                    selected
                    [value]="eventTypesEnum.PlannedFootpadScoringOfBirds">{{ "EVENT.PlannedFootpadScoringOfBirds" | translate }}
            </option>
            <option #option
                    *ngIf="selectedBroodingMode === broodingModes[1] && !isAllZonesSelected && broodingService.broodingDurationRemaining > 0 && permissionService.hasPermission(myFarmCropCardAddBrooding)"
                    [value]="eventTypesEnum.Brooding">{{ "EVENT.Brooding" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.AddThinning">{{ "EVENT.Add_Thinning" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.OtherEvents">{{ "EVENT.OtherEvents" | translate }}
            </option>
          </regular-select>
        </div>
      </div>
      <div class="width-85 additional-margin">
        <planned-footpad-scoring
          [event]="event"
          [currentHarvest]="currentHarvest"
          [day0Date]="day0Date"
          [rowIndex]="i"
          [functionalMode]="eventFunctionalMode"
          (score0)="previousAllOtherEvents[i].values[0].value = $event"
          (score1)="previousAllOtherEvents[i].values[1].value = $event"
          (score2)="previousAllOtherEvents[i].values[2].value = $event"
          (numberOfZone)="setSelectedFootpadScoringZones($event, i)"
          (dateChanged)="previousAllOtherEvents[i].date = $event"
          (removeEvent)="removeEvent(i,eventTypesEnum.PlannedFootpadScoringOfBirds)"
        >
        </planned-footpad-scoring>
      </div>
    </div>
    <!--    BroodingEvents-->
    <div class="display-flex" *ngIf="event.type === eventTypesEnum.Brooding && event !== veryFirstBroodingEvent">
      <div class="menus__menu brooding-margin width-15">
        <div class="menus__menu-selector-label brooding-type-label">{{ "EVENT.select_event_type" | translate }}</div>
        <div class="menus__menu-selector">
          <regular-select (change)="otherEventTypeChanged($event.target.value, i)">
            <option #option
                    [value]="eventTypesEnum.AddMedication">{{ "EVENT.add_medication" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.FlushWaterLine">{{ "EVENT.FlushWaterLine" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.WaterSanitation">{{ "EVENT.WaterSanitation" | translate }}
            </option>
            <!--NOTE: LEAVE IT FOR NOW, It should be included in next updates-->
            <!--            <option #option-->
            <!--                    *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.PlannedThinningAges) > 0"-->
            <!--                    [value]="eventTypesEnum.PlannedThinningAges">{{"EVENT.PlannedThinningAges" | translate}}</option>-->
            <option #option
                    [value]="eventTypesEnum.PlannedHandWeighingOfBirds">{{ "EVENT.PlannedHandWeighingOfBirds" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.PlannedFootpadScoringOfBirds">{{ "EVENT.PlannedFootpadScoringOfBirds" | translate }}
            </option>
            <option #option
                    selected
                    *ngIf="selectedBroodingMode === broodingModes[1] && !isAllZonesSelected && permissionService.hasPermission(myFarmCropCardAddBrooding)"
                    [value]="eventTypesEnum.Brooding">{{ "EVENT.Brooding" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.AddThinning">{{ "EVENT.Add_Thinning" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.OtherEvents">{{ "EVENT.OtherEvents" | translate }}
            </option>
          </regular-select>
        </div>
      </div>
      <div class="width-85 additional-margin">
        <brooding-event
          [currentHarvest]="currentHarvest"
          [event]="event"
          [initialDuration]="selectedDurationForInitialBrooding"
          [currentEvents]="previousAllOtherEvents"
          [day0Date]="day0Date"
          [rowIndex]="i"
          [functionalMode]="eventFunctionalMode"
          [possibleDeleteFirst]="true"
          (selectedZonesChanged)="previousAllOtherEvents[i].values[0].value = $event"
          (durationChanged)="setSelectedDurationForInitialBrooding($event, i)"
          (dateChanged)="previousAllOtherEvents[i].date = $event"
          (removeEvent)="removeEvent(i, eventTypesEnum.Brooding)"
        >
        </brooding-event>
      </div>
    </div>
    <!--    OtherEvents-->
    <div class="display-flex" *ngIf="event.type === eventTypesEnum.OtherEvents">
      <div class="menus__menu additional-margin width-15">
        <div class="menus__menu-selector-label custom-type-label">{{ "EVENT.select_event_type" | translate }}</div>
        <div class="menus__menu-selector">
          <regular-select (change)="otherEventTypeChanged($event.target.value, i)">
            <option #option
                    [value]="eventTypesEnum.AddMedication">{{ "EVENT.add_medication" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.FlushWaterLine">{{ "EVENT.FlushWaterLine" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.WaterSanitation">{{ "EVENT.WaterSanitation" | translate }}
            </option>
            <!--NOTE: LEAVE IT FOR NOW, It should be included in next updates-->
            <!--            <option #option-->
            <!--                    *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.PlannedThinningAges) > 0"-->
            <!--                    [value]="eventTypesEnum.PlannedThinningAges">{{"EVENT.PlannedThinningAges" | translate}}</option>-->
            <option #option
                    [value]="eventTypesEnum.PlannedHandWeighingOfBirds">{{ "EVENT.PlannedHandWeighingOfBirds" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.PlannedFootpadScoringOfBirds">{{ "EVENT.PlannedFootpadScoringOfBirds" | translate }}
            </option>
            <option #option
                    *ngIf="selectedBroodingMode === broodingModes[1] && !isAllZonesSelected && broodingService.broodingDurationRemaining > 0 && permissionService.hasPermission(myFarmCropCardAddBrooding)"
                    [value]="eventTypesEnum.Brooding">{{ "EVENT.Brooding" | translate }}
            </option>
            <option #option
                    [value]="eventTypesEnum.AddThinning">{{ "EVENT.Add_Thinning" | translate }}
            </option>
            <option #option
                    selected
                    [value]="eventTypesEnum.OtherEvents">{{ "EVENT.OtherEvents" | translate }}
            </option>
          </regular-select>
        </div>
      </div>
      <div class="width-85 additional-margin">
        <other-event
          [event]="event"
          [day0Date]="day0Date"
          [rowIndex]="i"
          [functionalMode]="eventFunctionalMode"
          [possibleDeleteFirst]="true"
          (onCommentChanged)="previousAllOtherEvents[i].values[0].value = $event"
          (dateChanged)="previousAllOtherEvents[i].date = $event"
          (removeEvent)="removeEvent(i, eventTypesEnum.OtherEvents)"
        >
        </other-event>
      </div>
    </div>
  </div>
  <div class="add-flock" *ngIf="previousAllOtherEvents.length < 5"
       (click)="addOtherEvent(eventTypesEnum.AddMedication)">
    <span>+</span>
    {{ 'CROP_CARD.add_event' | translate }}
  </div>
</div>

<!--COMMENTS-->
<div class="width80percent margin0auto">
  <div class="menus__menu-label">{{ 'CROP_CARD.Comments' | translate }}</div>
  <input maxlength="100" class="comments" type="text" (keyup)="updateCropComments($event.target.value)">
  <small class="characters-left">{{
      "CROP_CARD.VALIDATION.Characters_Limit"
        | translate: null: charactersLeft
    }}</small>
</div>


<!--BUTTONS-->
<div class="space-between display-flex width80percent margin0auto align-center">
  <button class="bordered-button" (click)="resetToOriginal()">{{ 'COMMON.BUTTONS.RESET_TO_DEFAULT' | translate }}
  </button>
  <div class="display-flex">
    <button class="blue-rounded-button new-farm-button"
            (click)="currentStep = currentStep-1"
            [class.none]="currentStep === 1">{{ 'COMMON.BUTTONS.BACK' | translate }}
    </button>
    <button class="blue-rounded-button new-farm-button"
            (click)="addStep()"
            [class.none]="currentStep === 3 || !newCrop.brandId || !openCropDate">{{ 'COMMON.BUTTONS.NEXT' | translate }}
    </button>
  </div>
  <button type="submit" [disabled]="!newCrop.brandId || !openCropDate || !waterFeedRatio"
          class="bordered-button"
          (click)="startNewCrop()">
    {{ 'COMMON.BUTTONS.FINISH' | translate }}
  </button>
</div>

<div class="popup" *ngIf="isConfirmModal">
  <div class="popup__body">
    <div class="popup__body-header">
      {{ 'CROP_CARD.START_CROP_CONFIRMATION' | translate }}
    </div>
    <div class="popup__body-close">
      <span class="svg close-svg-blue" (click)="isConfirmModal = false"></span>
    </div>
    <div class="popup__body-content">
      <div class="popup__body-content-row">
        <div>{{ 'CROP_CARD.Client' | translate }}</div>
        <div>{{ customerSelectorsService.currentCustomer?.name }}</div>
      </div>
      <div class="popup__body-content-row">
        <div>{{ 'CROP_CARD.Farm' | translate }}</div>
        <div>{{ customerSelectorsService.currentFarm?.name }}</div>
      </div>
      <div class="popup__body-content-row">
        <div>{{ 'CROP_CARD.House' | translate }}</div>
        <div>{{ customerSelectorsService.currentHouse?.name }}</div>
      </div>
      <div class="popup__body-content-row">
        <div>{{ 'CROP_CARD.Crop' | translate }}</div>
        <div>{{ newCrop.cropNumber }}</div>
      </div>
      <div class="popup__body-content-row">
        <div *ngIf="selectedCultivationType === cultivationTypesEnum.ChickPlacement">
          {{ 'CROP_CARD.chicks_placement_date' | translate }} / {{ 'COMMON.time' | translate }}
        </div>
        <div *ngIf="selectedCultivationType === cultivationTypesEnum.FarmHatching">
          {{ 'CROP_CARD.chicks_expected_hatching_date' | translate }}  / {{ 'COMMON.time' | translate }}
        </div>
        <div>{{ this.day0Date | date: translationService.localeLongDateTimeFormatMinutesOnly }}</div>
      </div>
      <div class="popup__body-content-row">
        <div>{{ 'CROP_CARD.Total_Number_of_Chicks' | translate }}</div>
        <div>{{ totalNumberOfChicks | numberFormatter: 0 }}</div>
      </div>
    </div>
    <div class="center width-100">
      <button class="blue-rounded-button new-farm-button"
              (click)="isConfirmModal = false">{{ 'COMMON.BUTTONS.CANCEL' | translate }}
      </button>
      <button class="blue-rounded-button new-farm-button"
              (click)="continueCreateCrop()">{{ 'COMMON.BUTTONS.Confirm' | translate }}
      </button>
    </div>
  </div>
</div>

<confirmation-modal
  [title]="confirmationPopupTitle | translate"
  [text]="confirmarmationPopupText | translate"
  [mode]="confirmationPopupMode"
  [isShowCross]="false"
  *ngIf="isShowConfirmationBeforeClose"
  (onClose)="closeWithoutChanges()"
  (onConfirm)="saveCropData()"
></confirmation-modal>
