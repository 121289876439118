/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Vaccination } from '../models/vaccination';
import { VaccinationDto } from '../models/vaccination-dto';

@Injectable({
  providedIn: 'root',
})
export class VaccinationService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getVaccinationList
   */
  static readonly GetVaccinationListPath = '/api/vaccination';

  /**
   * Get Vaccination List.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVaccinationList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccinationList$Response(params?: {
    customerId?: number;
  }): Observable<StrictHttpResponse<Array<Vaccination>>> {

    const rb = new RequestBuilder(this.rootUrl, VaccinationService.GetVaccinationListPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Vaccination>>;
      })
    );
  }

  /**
   * Get Vaccination List.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVaccinationList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccinationList(params?: {
    customerId?: number;
  }): Observable<Array<Vaccination>> {

    return this.getVaccinationList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Vaccination>>) => r.body as Array<Vaccination>)
    );
  }

  /**
   * Path part for operation addVaccination
   */
  static readonly AddVaccinationPath = '/api/vaccination';

  /**
   * Add Vaccination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addVaccination()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addVaccination$Response(params: {
    body: VaccinationDto
  }): Observable<StrictHttpResponse<Vaccination>> {

    const rb = new RequestBuilder(this.rootUrl, VaccinationService.AddVaccinationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vaccination>;
      })
    );
  }

  /**
   * Add Vaccination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addVaccination$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addVaccination(params: {
    body: VaccinationDto
  }): Observable<Vaccination> {

    return this.addVaccination$Response(params).pipe(
      map((r: StrictHttpResponse<Vaccination>) => r.body as Vaccination)
    );
  }

  /**
   * Path part for operation getVaccinationMap
   */
  static readonly GetVaccinationMapPath = '/api/vaccination/vaccinationmap';

  /**
   * Get Vaccination Map.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVaccinationMap()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccinationMap$Response(params?: {
  }): Observable<StrictHttpResponse<{
[key: string]: any;
}>> {

    const rb = new RequestBuilder(this.rootUrl, VaccinationService.GetVaccinationMapPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: any;
        }>;
      })
    );
  }

  /**
   * Get Vaccination Map.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVaccinationMap$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccinationMap(params?: {
  }): Observable<{
[key: string]: any;
}> {

    return this.getVaccinationMap$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: any;
}>) => r.body as {
[key: string]: any;
})
    );
  }

  /**
   * Path part for operation getVaccination
   */
  static readonly GetVaccinationPath = '/api/vaccination/{vaccinationId}';

  /**
   * Get Vaccination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVaccination()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccination$Response(params: {
    vaccinationId: number;
  }): Observable<StrictHttpResponse<Vaccination>> {

    const rb = new RequestBuilder(this.rootUrl, VaccinationService.GetVaccinationPath, 'get');
    if (params) {
      rb.path('vaccinationId', params.vaccinationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vaccination>;
      })
    );
  }

  /**
   * Get Vaccination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVaccination$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccination(params: {
    vaccinationId: number;
  }): Observable<Vaccination> {

    return this.getVaccination$Response(params).pipe(
      map((r: StrictHttpResponse<Vaccination>) => r.body as Vaccination)
    );
  }

  /**
   * Path part for operation updateVaccination
   */
  static readonly UpdateVaccinationPath = '/api/vaccination/{vaccinationId}';

  /**
   * Update Vaccination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVaccination()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVaccination$Response(params: {
    vaccinationId: number;
    body: VaccinationDto
  }): Observable<StrictHttpResponse<Vaccination>> {

    const rb = new RequestBuilder(this.rootUrl, VaccinationService.UpdateVaccinationPath, 'put');
    if (params) {
      rb.path('vaccinationId', params.vaccinationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vaccination>;
      })
    );
  }

  /**
   * Update Vaccination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateVaccination$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVaccination(params: {
    vaccinationId: number;
    body: VaccinationDto
  }): Observable<Vaccination> {

    return this.updateVaccination$Response(params).pipe(
      map((r: StrictHttpResponse<Vaccination>) => r.body as Vaccination)
    );
  }

  /**
   * Path part for operation deleteVaccination
   */
  static readonly DeleteVaccinationPath = '/api/vaccination/{vaccinationId}';

  /**
   * Delete Vaccination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteVaccination()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVaccination$Response(params: {
    vaccinationId: number;
  }): Observable<StrictHttpResponse<Vaccination>> {

    const rb = new RequestBuilder(this.rootUrl, VaccinationService.DeleteVaccinationPath, 'delete');
    if (params) {
      rb.path('vaccinationId', params.vaccinationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Vaccination>;
      })
    );
  }

  /**
   * Delete Vaccination.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteVaccination$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVaccination(params: {
    vaccinationId: number;
  }): Observable<Vaccination> {

    return this.deleteVaccination$Response(params).pipe(
      map((r: StrictHttpResponse<Vaccination>) => r.body as Vaccination)
    );
  }

}
