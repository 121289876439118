/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomerCreateRequest } from '../models/customer-create-request';
import { CustomerResponse } from '../models/customer-response';
import { CustomerUpdateRequest } from '../models/customer-update-request';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCustomers
   */
  static readonly GetCustomersPath = '/api/customer';

  /**
   * Get Customers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CustomerResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomerResponse>>;
      })
    );
  }

  /**
   * Get Customers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers(params?: {
  }): Observable<Array<CustomerResponse>> {

    return this.getCustomers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CustomerResponse>>) => r.body as Array<CustomerResponse>)
    );
  }

  /**
   * Path part for operation createCustomer
   */
  static readonly CreateCustomerPath = '/api/customer';

  /**
   * Create Customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomer$Response(params: {
    body: CustomerCreateRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.CreateCustomerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Create Customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomer(params: {
    body: CustomerCreateRequest
  }): Observable<void> {

    return this.createCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateCustomer
   */
  static readonly UpdateCustomerPath = '/api/customer/{customerId}';

  /**
   * Update Customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomer$Response(params: {
    customerId: number;
    body: CustomerUpdateRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.UpdateCustomerPath, 'put');
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update Customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomer(params: {
    customerId: number;
    body: CustomerUpdateRequest
  }): Observable<void> {

    return this.updateCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteCustomer
   */
  static readonly DeleteCustomerPath = '/api/customer/{customerId}';

  /**
   * Delete Customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomer$Response(params: {
    customerId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.DeleteCustomerPath, 'delete');
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete Customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomer(params: {
    customerId: number;
  }): Observable<void> {

    return this.deleteCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setActiveCustomer_2
   */
  static readonly SetActiveCustomer_2Path = '/api/customer/{customerId}/setactive={isActive}';

  /**
   * Set Active Customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setActiveCustomer_2()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActiveCustomer_2$Response(params: {
    customerId: number;
    isActive: boolean;
  }): Observable<StrictHttpResponse<CustomerResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.SetActiveCustomer_2Path, 'put');
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.path('isActive', params.isActive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerResponse>;
      })
    );
  }

  /**
   * Set Active Customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setActiveCustomer_2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActiveCustomer_2(params: {
    customerId: number;
    isActive: boolean;
  }): Observable<CustomerResponse> {

    return this.setActiveCustomer_2$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerResponse>) => r.body as CustomerResponse)
    );
  }

}
