/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FacilityDataDto } from '../models/facility-data-dto';
import { FacilityGroup } from '../models/facility-group';
import { FacilityGroupCreateRequest } from '../models/facility-group-create-request';
import { FacilityGroupDto } from '../models/facility-group-dto';
import { FacilityGroupResponse } from '../models/facility-group-response';
import { FacilityGroupUpdateRequest } from '../models/facility-group-update-request';
import { Harvest } from '../models/harvest';
import { HarvestTargetResponse } from '../models/harvest-target-response';
import { OverviewFacilityDto } from '../models/overview-facility-dto';
import { OverviewZoneDto } from '../models/overview-zone-dto';
import { ZonesOverviewResponse } from '../models/zones-overview-response';

@Injectable({
  providedIn: 'root',
})
export class FacilityGroupService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFacilityGroupsByCustomer
   */
  static readonly GetFacilityGroupsByCustomerPath = '/api/facilitygroup';

  /**
   * Get Facility Groups By Customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFacilityGroupsByCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityGroupsByCustomer$Response(params?: {
    customerId?: number;
  }): Observable<StrictHttpResponse<Array<FacilityGroupDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupService.GetFacilityGroupsByCustomerPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FacilityGroupDto>>;
      })
    );
  }

  /**
   * Get Facility Groups By Customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFacilityGroupsByCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityGroupsByCustomer(params?: {
    customerId?: number;
  }): Observable<Array<FacilityGroupDto>> {

    return this.getFacilityGroupsByCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FacilityGroupDto>>) => r.body as Array<FacilityGroupDto>)
    );
  }

  /**
   * Path part for operation createFacilityGroup
   */
  static readonly CreateFacilityGroupPath = '/api/facilitygroup';

  /**
   * Create Facility Group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFacilityGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFacilityGroup$Response(params: {
    body: FacilityGroupCreateRequest
  }): Observable<StrictHttpResponse<FacilityGroupResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupService.CreateFacilityGroupPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityGroupResponse>;
      })
    );
  }

  /**
   * Create Facility Group.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFacilityGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFacilityGroup(params: {
    body: FacilityGroupCreateRequest
  }): Observable<FacilityGroupResponse> {

    return this.createFacilityGroup$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityGroupResponse>) => r.body as FacilityGroupResponse)
    );
  }

  /**
   * Path part for operation getOverviewFacility
   */
  static readonly GetOverviewFacilityPath = '/api/facilitygroup/overview/facilityId/{facilityId}';

  /**
   * Get Overview Facility.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOverviewFacility()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverviewFacility$Response(params: {
    facilityId: number;
    date?: string;
    isLive?: boolean;
  }): Observable<StrictHttpResponse<Array<OverviewZoneDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupService.GetOverviewFacilityPath, 'get');
    if (params) {
      rb.path('facilityId', params.facilityId, {});
      rb.query('date', params.date, {});
      rb.query('isLive', params.isLive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OverviewZoneDto>>;
      })
    );
  }

  /**
   * Get Overview Facility.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOverviewFacility$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverviewFacility(params: {
    facilityId: number;
    date?: string;
    isLive?: boolean;
  }): Observable<Array<OverviewZoneDto>> {

    return this.getOverviewFacility$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OverviewZoneDto>>) => r.body as Array<OverviewZoneDto>)
    );
  }

  /**
   * Path part for operation getOverviewZonesFacility
   */
  static readonly GetOverviewZonesFacilityPath = '/api/facilitygroup/overview/zones/facilityId/{facilityId}';

  /**
   * Get Overview Zones Facility.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOverviewZonesFacility()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverviewZonesFacility$Response(params: {
    facilityId: number;
    date?: string;
    dateTo?: string;
  }): Observable<StrictHttpResponse<ZonesOverviewResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupService.GetOverviewZonesFacilityPath, 'get');
    if (params) {
      rb.path('facilityId', params.facilityId, {});
      rb.query('date', params.date, {});
      rb.query('dateTo', params.dateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ZonesOverviewResponse>;
      })
    );
  }

  /**
   * Get Overview Zones Facility.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOverviewZonesFacility$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverviewZonesFacility(params: {
    facilityId: number;
    date?: string;
    dateTo?: string;
  }): Observable<ZonesOverviewResponse> {

    return this.getOverviewZonesFacility$Response(params).pipe(
      map((r: StrictHttpResponse<ZonesOverviewResponse>) => r.body as ZonesOverviewResponse)
    );
  }

  /**
   * Path part for operation getZoneTargets
   */
  static readonly GetZoneTargetsPath = '/api/facilitygroup/overview/zones/targets/{facilityId}';

  /**
   * Get Zone Targets.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getZoneTargets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getZoneTargets$Response(params: {
    facilityId: number;
    date?: string;
    dateTo?: string;
    zoneId?: Array<number>;
  }): Observable<StrictHttpResponse<Array<HarvestTargetResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupService.GetZoneTargetsPath, 'get');
    if (params) {
      rb.path('facilityId', params.facilityId, {});
      rb.query('date', params.date, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('zoneId', params.zoneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HarvestTargetResponse>>;
      })
    );
  }

  /**
   * Get Zone Targets.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getZoneTargets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getZoneTargets(params: {
    facilityId: number;
    date?: string;
    dateTo?: string;
    zoneId?: Array<number>;
  }): Observable<Array<HarvestTargetResponse>> {

    return this.getZoneTargets$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HarvestTargetResponse>>) => r.body as Array<HarvestTargetResponse>)
    );
  }

  /**
   * Path part for operation getOverviewFacilityGroup
   */
  static readonly GetOverviewFacilityGroupPath = '/api/facilitygroup/overview/{facilityGroupId}';

  /**
   * Get Overview Facility Group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOverviewFacilityGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverviewFacilityGroup$Response(params: {
    facilityGroupId: number;
    date?: string;
    isLive?: boolean;
  }): Observable<StrictHttpResponse<Array<OverviewFacilityDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupService.GetOverviewFacilityGroupPath, 'get');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
      rb.query('date', params.date, {});
      rb.query('isLive', params.isLive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OverviewFacilityDto>>;
      })
    );
  }

  /**
   * Get Overview Facility Group.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOverviewFacilityGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverviewFacilityGroup(params: {
    facilityGroupId: number;
    date?: string;
    isLive?: boolean;
  }): Observable<Array<OverviewFacilityDto>> {

    return this.getOverviewFacilityGroup$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OverviewFacilityDto>>) => r.body as Array<OverviewFacilityDto>)
    );
  }

  /**
   * Path part for operation updateFacilityGroup
   */
  static readonly UpdateFacilityGroupPath = '/api/facilitygroup/{facilityGroupId}';

  /**
   * Update Facility Group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFacilityGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFacilityGroup$Response(params: {
    facilityGroupId: number;
    body: FacilityGroupUpdateRequest
  }): Observable<StrictHttpResponse<FacilityGroupResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupService.UpdateFacilityGroupPath, 'put');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityGroupResponse>;
      })
    );
  }

  /**
   * Update Facility Group.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFacilityGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFacilityGroup(params: {
    facilityGroupId: number;
    body: FacilityGroupUpdateRequest
  }): Observable<FacilityGroupResponse> {

    return this.updateFacilityGroup$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityGroupResponse>) => r.body as FacilityGroupResponse)
    );
  }

  /**
   * Path part for operation deleteFacilityGroup
   */
  static readonly DeleteFacilityGroupPath = '/api/facilitygroup/{facilityGroupId}';

  /**
   * Delete Facility Group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFacilityGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFacilityGroup$Response(params: {
    facilityGroupId: number;
  }): Observable<StrictHttpResponse<FacilityGroup>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupService.DeleteFacilityGroupPath, 'delete');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityGroup>;
      })
    );
  }

  /**
   * Delete Facility Group.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFacilityGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFacilityGroup(params: {
    facilityGroupId: number;
  }): Observable<FacilityGroup> {

    return this.deleteFacilityGroup$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityGroup>) => r.body as FacilityGroup)
    );
  }

  /**
   * Path part for operation getFacilityGroupHarvests
   */
  static readonly GetFacilityGroupHarvestsPath = '/api/facilitygroup/{facilityGroupId}/harvest';

  /**
   * Get Facility Group Harvests.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFacilityGroupHarvests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityGroupHarvests$Response(params: {
    facilityGroupId: number;
  }): Observable<StrictHttpResponse<Array<Harvest>>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupService.GetFacilityGroupHarvestsPath, 'get');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Harvest>>;
      })
    );
  }

  /**
   * Get Facility Group Harvests.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFacilityGroupHarvests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityGroupHarvests(params: {
    facilityGroupId: number;
  }): Observable<Array<Harvest>> {

    return this.getFacilityGroupHarvests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Harvest>>) => r.body as Array<Harvest>)
    );
  }

  /**
   * Path part for operation getFacilityData
   */
  static readonly GetFacilityDataPath = '/api/facilitygroup/{facilityGroupId}/harvestfacility';

  /**
   * Get Facility Data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFacilityData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityData$Response(params: {
    facilityGroupId: number;
  }): Observable<StrictHttpResponse<Array<FacilityDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityGroupService.GetFacilityDataPath, 'get');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FacilityDataDto>>;
      })
    );
  }

  /**
   * Get Facility Data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFacilityData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityData(params: {
    facilityGroupId: number;
  }): Observable<Array<FacilityDataDto>> {

    return this.getFacilityData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FacilityDataDto>>) => r.body as Array<FacilityDataDto>)
    );
  }

}
