import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmbientDropdownComponent } from './ambient-dropdown.component';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [AmbientDropdownComponent],
  imports: [CommonModule, PipesModule],
  exports: [AmbientDropdownComponent],
})
export class AmbientDropdownModule {}
