import { Injectable } from '@angular/core';
import { UserDetailsResponse } from '../api/models/user-details-response';
import { take } from 'rxjs/operators';
import { MyService } from '../api/services/my.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslationService } from '../translations/translation.service';
import { UtilsService } from '../utils/utils.service';
import { PermissionService } from './permission-service';
import { LanguageEnum } from '../api/models/language-enum';
import { NamedValue } from '../api/models/named-value';
import { HarvestFullResponse } from '../api/models/harvest-full-response';
import { Router } from '@angular/router';

export enum PaginationName {
  FarmsOverview = 'FARMS_OVERVIEW',
  MyUsers = 'MY_USERS',
  Devices = 'DEVICES',
  PerformanceReport = 'PERFORMANCE_REVIEW',
  Customers = 'CUSTOMERS',
  AdminSettings = 'ADMIN_SETTINGS',
}

export enum UrlPagination {
  FarmsOverview = 'farmsOverview',
  MyUsers = 'myUsers',
  Devices = 'devices',
  PerformanceReport = 'performance',
  Customers = 'customers',
  EditCustomer = 'edit-customer',
  AdminSettings = 'settings',
}

export enum DashboardsRoutes {
  Dashboard = 'dashboard',
  ManagementDashboard = 'managementDashboard',
  OutlierDashboard = 'outlierDashboard',
}

export const USER_RES_KEY = 'userRes';
export const WAF_HEADER_USER_EMAIL = 'wafHeaderUserEmail';

@Injectable({ providedIn: 'root' })
export class UserDetailsService {
  public valuesInitialized: Subject<void> = new Subject<void>();
  public paginationConfig: any = [
    {
      name: PaginationName.FarmsOverview,
      value: '5',
    },
    {
      name: PaginationName.MyUsers,
      value: '5',
    },
    {
      name: PaginationName.Devices,
      value: '5',
    },
    {
      name: PaginationName.PerformanceReport,
      value: '5',
    },
    {
      name: PaginationName.Customers,
      value: '5',
    },
    {
      name: PaginationName.AdminSettings,
      value: '5',
    },
  ];

  public reloadAfterPaginationConfigSetup: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  constructor(
    private myService: MyService,
    private translationService: TranslationService,
    private permissionService: PermissionService,
    private router: Router
  ) {}

  public get userRes(): UserDetailsResponse {
    return JSON.parse(
      localStorage.getItem(USER_RES_KEY)
    ) as UserDetailsResponse;
  }

  public setUserDetails(info?: UserDetailsResponse) {
    this.myService.updateLastLoginUser().pipe(take(1)).subscribe();
    if (info) {
      localStorage.setItem(USER_RES_KEY, JSON.stringify(info));
      this.setLanguage(info.language);
    } else {
      this.myService
        .getMyUserDetails()
        .pipe(take(1))
        .subscribe((res) => {
          if (res?.dashboardPreferredView) {
            this.router.navigate(['/' + res.dashboardPreferredView]);
          }
          const url = window.location.pathname.split('/')[1];
          const paginationConfigValue = JSON.parse(
            localStorage.getItem(USER_RES_KEY)
          );
          if (
            paginationConfigValue &&
            this.isChangedPaginationForCurrentPage(res, url)
          ) {
            this.reloadAfterPaginationConfigSetup.next(url);
          }

          this.paginationConfig = [
            {
              name: PaginationName.FarmsOverview,
              value: '5',
            },
            {
              name: PaginationName.MyUsers,
              value: '5',
            },
            {
              name: PaginationName.Devices,
              value: '5',
            },
            {
              name: PaginationName.PerformanceReport,
              value: '5',
            },
            {
              name: PaginationName.Customers,
              value: '5',
            },
            {
              name: PaginationName.AdminSettings,
              value: '5',
            },
          ];
          res.paginationConfig = this.setPaginationConfig(res.paginationConfig);
          localStorage.setItem(USER_RES_KEY, JSON.stringify(res));
          this.setLanguage(res.language);
          this.permissionService.getPermissions();
          this.valuesInitialized.next();
        });
    }
  }

  public isChangedPaginationForCurrentPage(
    res: UserDetailsResponse,
    url: string
  ): boolean {
    const paginationConfigValue = JSON.parse(
      localStorage.getItem(USER_RES_KEY)
    ).paginationConfig;

    if (UrlPagination.FarmsOverview === url) {
      return (
        paginationConfigValue.find(
          (config) => config.name === PaginationName.FarmsOverview
        ).value !==
        res.paginationConfig.find(
          (config) => config.name === PaginationName.FarmsOverview
        ).value
      );
    }
    if (UrlPagination.AdminSettings === url) {
      return (
        paginationConfigValue.find(
          (config) => config.name === PaginationName.AdminSettings
        ).value !==
        res.paginationConfig.find(
          (config) => config.name === PaginationName.AdminSettings
        ).value
      );
    }
    if (UrlPagination.Devices === url) {
      return (
        paginationConfigValue.find(
          (config) => config.name === PaginationName.Devices
        ).value !==
        res.paginationConfig.find(
          (config) => config.name === PaginationName.Devices
        ).value
      );
    }
    if (UrlPagination.PerformanceReport === url) {
      return (
        paginationConfigValue.find(
          (config) => config.name === PaginationName.PerformanceReport
        ).value !==
        res.paginationConfig.find(
          (config) => config.name === PaginationName.PerformanceReport
        ).value
      );
    }
    if (UrlPagination.Customers === url || UrlPagination.EditCustomer === url) {
      return (
        paginationConfigValue.find(
          (config) => config.name === PaginationName.Customers
        ).value !==
        res.paginationConfig.find(
          (config) => config.name === PaginationName.Customers
        ).value
      );
    }
    if (UrlPagination.MyUsers === url) {
      return (
        paginationConfigValue.find(
          (config) => config.name === PaginationName.MyUsers
        ).value !==
        res.paginationConfig.find(
          (config) => config.name === PaginationName.MyUsers
        ).value
      );
    }
  }

  public setPaginationConfig(paginationConfig: Array<NamedValue>) {
    this.paginationConfig = this.paginationConfig.map((item) => {
      const configuration = paginationConfig?.find(
        (config) => item.name === config.name
      );
      return configuration ? configuration : item;
    });
    return this.paginationConfig;
  }

  private async setLanguage(lang: LanguageEnum) {
    await this.translationService.selectLanguage(lang, true);
  }
}
