/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ActivityLogDto } from '../models/activity-log-dto';
import { LocalDate } from '../models/local-date';
import { UserActivityDto } from '../models/user-activity-dto';

@Injectable({
  providedIn: 'root',
})
export class UserActivityLogService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getActivityLog
   */
  static readonly GetActivityLogPath = '/api/userActivityLog';

  /**
   * Get Activity Log.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivityLog()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityLog$Response(params?: {
    fromDate?: string;
    toDate?: string;
    userId?: number;
  }): Observable<StrictHttpResponse<Array<ActivityLogDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserActivityLogService.GetActivityLogPath, 'get');
    if (params) {
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ActivityLogDto>>;
      })
    );
  }

  /**
   * Get Activity Log.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getActivityLog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityLog(params?: {
    fromDate?: string;
    toDate?: string;
    userId?: number;
  }): Observable<Array<ActivityLogDto>> {

    return this.getActivityLog$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ActivityLogDto>>) => r.body as Array<ActivityLogDto>)
    );
  }

  /**
   * Path part for operation getActivityLogPageData
   */
  static readonly GetActivityLogPageDataPath = '/api/userActivityLog/full';

  /**
   * Get Activity Log Page Data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivityLogPageData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityLogPageData$Response(params?: {
    fromDate?: LocalDate;
    toDate?: LocalDate;
    userId?: number;
  }): Observable<StrictHttpResponse<Array<UserActivityDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserActivityLogService.GetActivityLogPageDataPath, 'get');
    if (params) {
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserActivityDto>>;
      })
    );
  }

  /**
   * Get Activity Log Page Data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getActivityLogPageData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityLogPageData(params?: {
    fromDate?: LocalDate;
    toDate?: LocalDate;
    userId?: number;
  }): Observable<Array<UserActivityDto>> {

    return this.getActivityLogPageData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserActivityDto>>) => r.body as Array<UserActivityDto>)
    );
  }

}
