/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ScoringParameters } from '../models/scoring-parameters';
import { ScoringParametersDto } from '../models/scoring-parameters-dto';

@Injectable({
  providedIn: 'root',
})
export class ScoringParametersService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getScoringParameters
   */
  static readonly GetScoringParametersPath = '/api/scoringparameters';

  /**
   * Get Scoring Parameters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScoringParameters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScoringParameters$Response(params?: {
    customerId?: number;
  }): Observable<StrictHttpResponse<Array<ScoringParameters>>> {

    const rb = new RequestBuilder(this.rootUrl, ScoringParametersService.GetScoringParametersPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ScoringParameters>>;
      })
    );
  }

  /**
   * Get Scoring Parameters.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScoringParameters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScoringParameters(params?: {
    customerId?: number;
  }): Observable<Array<ScoringParameters>> {

    return this.getScoringParameters$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ScoringParameters>>) => r.body as Array<ScoringParameters>)
    );
  }

  /**
   * Path part for operation addScoringParameters
   */
  static readonly AddScoringParametersPath = '/api/scoringparameters';

  /**
   * Add Scoring Parameters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addScoringParameters()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addScoringParameters$Response(params: {
    body: ScoringParametersDto
  }): Observable<StrictHttpResponse<ScoringParameters>> {

    const rb = new RequestBuilder(this.rootUrl, ScoringParametersService.AddScoringParametersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScoringParameters>;
      })
    );
  }

  /**
   * Add Scoring Parameters.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addScoringParameters$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addScoringParameters(params: {
    body: ScoringParametersDto
  }): Observable<ScoringParameters> {

    return this.addScoringParameters$Response(params).pipe(
      map((r: StrictHttpResponse<ScoringParameters>) => r.body as ScoringParameters)
    );
  }

  /**
   * Path part for operation getAllScoringParameters
   */
  static readonly GetAllScoringParametersPath = '/api/scoringparameters/all';

  /**
   * Get All Scoring Parameters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllScoringParameters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllScoringParameters$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ScoringParameters>>> {

    const rb = new RequestBuilder(this.rootUrl, ScoringParametersService.GetAllScoringParametersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ScoringParameters>>;
      })
    );
  }

  /**
   * Get All Scoring Parameters.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllScoringParameters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllScoringParameters(params?: {
  }): Observable<Array<ScoringParameters>> {

    return this.getAllScoringParameters$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ScoringParameters>>) => r.body as Array<ScoringParameters>)
    );
  }

  /**
   * Path part for operation findByMetricType
   */
  static readonly FindByMetricTypePath = '/api/scoringparameters/metricType/{metricType}';

  /**
   * Find By Metric Type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByMetricType()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByMetricType$Response(params: {
    metricType: string;
  }): Observable<StrictHttpResponse<Array<ScoringParameters>>> {

    const rb = new RequestBuilder(this.rootUrl, ScoringParametersService.FindByMetricTypePath, 'get');
    if (params) {
      rb.path('metricType', params.metricType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ScoringParameters>>;
      })
    );
  }

  /**
   * Find By Metric Type.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByMetricType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByMetricType(params: {
    metricType: string;
  }): Observable<Array<ScoringParameters>> {

    return this.findByMetricType$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ScoringParameters>>) => r.body as Array<ScoringParameters>)
    );
  }

  /**
   * Path part for operation updateScoringParameters
   */
  static readonly UpdateScoringParametersPath = '/api/scoringparameters/{scoringParameterId}';

  /**
   * Update Scoring Parameters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateScoringParameters()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateScoringParameters$Response(params: {
    scoringParameterId: number;
    body: ScoringParametersDto
  }): Observable<StrictHttpResponse<ScoringParameters>> {

    const rb = new RequestBuilder(this.rootUrl, ScoringParametersService.UpdateScoringParametersPath, 'put');
    if (params) {
      rb.path('scoringParameterId', params.scoringParameterId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScoringParameters>;
      })
    );
  }

  /**
   * Update Scoring Parameters.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateScoringParameters$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateScoringParameters(params: {
    scoringParameterId: number;
    body: ScoringParametersDto
  }): Observable<ScoringParameters> {

    return this.updateScoringParameters$Response(params).pipe(
      map((r: StrictHttpResponse<ScoringParameters>) => r.body as ScoringParameters)
    );
  }

  /**
   * Path part for operation deleteScoringParameters
   */
  static readonly DeleteScoringParametersPath = '/api/scoringparameters/{scoringParameterId}';

  /**
   * Delete Scoring Parameters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteScoringParameters()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteScoringParameters$Response(params: {
    scoringParameterId: number;
  }): Observable<StrictHttpResponse<ScoringParameters>> {

    const rb = new RequestBuilder(this.rootUrl, ScoringParametersService.DeleteScoringParametersPath, 'delete');
    if (params) {
      rb.path('scoringParameterId', params.scoringParameterId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScoringParameters>;
      })
    );
  }

  /**
   * Delete Scoring Parameters.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteScoringParameters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteScoringParameters(params: {
    scoringParameterId: number;
  }): Observable<ScoringParameters> {

    return this.deleteScoringParameters$Response(params).pipe(
      map((r: StrictHttpResponse<ScoringParameters>) => r.body as ScoringParameters)
    );
  }

}
