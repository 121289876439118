import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertService } from '../../common/components/notifications/alert.service';
import { NotificationTitle } from '../api/models/notification-title';
import { NotificationType } from '../api/models/notification-type';
import { NotificationService } from '../api/services/notification.service';
import { AuthService } from '../auth/auth.service';
import { CustomerSelectorsService } from '../customer-selectors/customer-selectors.service';

export enum PossibleRedirections {
  dashboard = 'dashboard',
  endOfCrop = 'end-of-crop',
  harvestCreated = 'harvestcreated',
}

export interface ILinkParams {
  show: boolean;
  expired: boolean;
  dismissDays: string;
  customerId: string;
  facilityGroupId: string;
  facilityId: string;
  name: NotificationTitle;
  type: NotificationType;
  redirectTo: string;
  key?: string;
}

@Injectable({ providedIn: 'root' })
export class NavigateByLinkService {
  public linkParams: ILinkParams;
  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private customerSelectorsService: CustomerSelectorsService,
    private alertService: AlertService,
    private notificationService: NotificationService
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public navigateByLink(params: ILinkParams) {
    switch (params?.redirectTo) {
      case PossibleRedirections.dashboard:
        if (params?.expired === false) {
          if (!params?.dismissDays) {
            params.show = false;
            this.linkParams = params;
            this.router.navigate(['/' + params.redirectTo]);
          } else {
            params.show = true;
            this.linkParams = params;
            const key = this.linkParams.key;
            params.customerId = key.slice(
              key.search('customerId') + 12,
              key.search(',"registeredDeviceIds"')
            );
            this.customerSelectorsService.customersList$.subscribe(() => {
              this.customerSelectorsService.updateStateForService({
                customerId: +params.customerId,
                farmId: +params.facilityGroupId,
              });
              this.changeValuesInCustomerSelectorService(params);
            });
            this.router.navigate(['/' + params.redirectTo]);
          }
        } else if (params?.expired === true) {
          this.router.navigate(['/' + params.redirectTo]);
        }
        return params;
      case PossibleRedirections.endOfCrop:
        if (this.previousUrl.includes('auth')) {
          // NOT OPTIMIZED DECISION
          this.customerSelectorsService.customersList$.subscribe(() => {
            this.customerSelectorsService.updateStateForService({
              customerId: +params.customerId,
              farmId: +params.facilityGroupId,
            });
            this.changeValuesInCustomerSelectorService(params);
          });
          this.router.navigate(['/' + params.redirectTo]);
        }
        break;
      case PossibleRedirections.harvestCreated:
        if (this.previousUrl.includes('auth')) {
          // NOT OPTIMIZED DECISION
          this.customerSelectorsService.customersList$.subscribe(() => {
            this.customerSelectorsService.updateStateForService({
              customerId: +params.customerId,
              farmId: +params.facilityGroupId,
              houseId: +params.facilityId,
            });
            this.changeValuesInCustomerSelectorService(params);
          });
          this.router.navigate(['/' + 'cropCard']);
        }
        break;
    }
  }

  public changeValuesInCustomerSelectorService(params: ILinkParams) {
    // Used setTimeout because not found a timing or subscription for starting next BL
    setTimeout(() => {
      if (params.customerId?.length > 0 && params.facilityGroupId?.length > 0) {
        this.customerSelectorsService.onCustomerChange(+params.customerId);
        setTimeout(() => {
          this.customerSelectorsService.onFarmChange(+params.facilityGroupId);
        }, 1000);
        if (params.facilityId?.length > 0) {
          setTimeout(() => {
            this.customerSelectorsService.onHouseChange(+params.facilityId);
          }, 1500);
        }
      }
    }, 750);
  }

  public removeLinkParams() {
    this.linkParams.show = false;
    this.authService.removeLinkParams();
  }
}
