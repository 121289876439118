import { NgModule } from '@angular/core';
import { PsSensorsModule } from './ps-sensors/ps-sensors.module';
import { MyFarmModule } from './my-farm/my-farm.module';
import { CustMgmtModule } from './cust-mgmt/cust-mgmt.module';
import { AnalysisModule } from './analysis/analysis.module';
import { ProfileModule } from './profile/profile.module';
import { ChangeLanguageDropdownModule } from './change-language-dropdown/change-language-dropdown.module';
import { CustomHousesMultiselectModule } from './custom-houses-multiselect/custom-houses-multiselect.module';
import { ReportsModule } from './reports/reports.module';
import { SimpleDropdownModule } from './simple-dropdown/simple-dropdown.module';
import { DropdownWithHeaderModule } from './dropdown-with-header/dropdown-with-header.module';
import { AmbientDropdownModule } from './ambient-dropdown/ambient-dropdown.module';

@NgModule({
  exports: [
    PsSensorsModule,
    MyFarmModule,
    CustMgmtModule,
    AnalysisModule,
    ProfileModule,
    ChangeLanguageDropdownModule,
    CustomHousesMultiselectModule,
    ReportsModule,
    SimpleDropdownModule,
    DropdownWithHeaderModule,
    AmbientDropdownModule,
  ],
})
export class DropdownsModule {}
