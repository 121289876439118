/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DatedValueDto } from '../models/dated-value-dto';
import { DeviceInUseDto } from '../models/device-in-use-dto';
import { EnvironmentalReadingResponse } from '../models/environmental-reading-response';
import { EventCreateResponseDto } from '../models/event-create-response-dto';
import { EventDto } from '../models/event-dto';
import { FacilityHarvestDto } from '../models/facility-harvest-dto';
import { HarvestBasicResponse } from '../models/harvest-basic-response';
import { HarvestCloseRequest } from '../models/harvest-close-request';
import { HarvestCreateAuxiliaryDataResponse } from '../models/harvest-create-auxiliary-data-response';
import { HarvestCreateRequest } from '../models/harvest-create-request';
import { HarvestCustomerDefaultsDto } from '../models/harvest-customer-defaults-dto';
import { HarvestDto } from '../models/harvest-dto';
import { HarvestEvent } from '../models/harvest-event';
import { HarvestEventDto } from '../models/harvest-event-dto';
import { HarvestFullResponse } from '../models/harvest-full-response';
import { HarvestUpdateRequest } from '../models/harvest-update-request';
import { MortalityAnalysisResponse } from '../models/mortality-analysis-response';
import { MortalityRequest } from '../models/mortality-request';
import { MortalityResponse } from '../models/mortality-response';
import { MortalityTableRow } from '../models/mortality-table-row';
import { MortalityWidgetResponse } from '../models/mortality-widget-response';
import { WaterConsumptionDto } from '../models/water-consumption-dto';

@Injectable({
  providedIn: 'root',
})
export class HarvestService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createHarvest
   */
  static readonly CreateHarvestPath = '/api/harvests';

  /**
   * Create Harvest.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createHarvest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createHarvest$Response(params: {
    body: HarvestCreateRequest
  }): Observable<StrictHttpResponse<HarvestFullResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.CreateHarvestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestFullResponse>;
      })
    );
  }

  /**
   * Create Harvest.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createHarvest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createHarvest(params: {
    body: HarvestCreateRequest
  }): Observable<HarvestFullResponse> {

    return this.createHarvest$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestFullResponse>) => r.body as HarvestFullResponse)
    );
  }

  /**
   * Path part for operation getHarvestCreateAuxiliaryDataResponse
   */
  static readonly GetHarvestCreateAuxiliaryDataResponsePath = '/api/harvests/auxiliary-data';

  /**
   * Get Harvest Create Auxiliary Data Response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHarvestCreateAuxiliaryDataResponse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestCreateAuxiliaryDataResponse$Response(params?: {
  }): Observable<StrictHttpResponse<HarvestCreateAuxiliaryDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetHarvestCreateAuxiliaryDataResponsePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestCreateAuxiliaryDataResponse>;
      })
    );
  }

  /**
   * Get Harvest Create Auxiliary Data Response.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHarvestCreateAuxiliaryDataResponse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestCreateAuxiliaryDataResponse(params?: {
  }): Observable<HarvestCreateAuxiliaryDataResponse> {

    return this.getHarvestCreateAuxiliaryDataResponse$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestCreateAuxiliaryDataResponse>) => r.body as HarvestCreateAuxiliaryDataResponse)
    );
  }

  /**
   * Path part for operation getDefaults
   */
  static readonly GetDefaultsPath = '/api/harvests/defaults';

  /**
   * Get Defaults.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaults()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaults$Response(params?: {
    customerId?: number;
  }): Observable<StrictHttpResponse<HarvestCustomerDefaultsDto>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetDefaultsPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestCustomerDefaultsDto>;
      })
    );
  }

  /**
   * Get Defaults.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDefaults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaults(params?: {
    customerId?: number;
  }): Observable<HarvestCustomerDefaultsDto> {

    return this.getDefaults$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestCustomerDefaultsDto>) => r.body as HarvestCustomerDefaultsDto)
    );
  }

  /**
   * Path part for operation updateDefaults
   */
  static readonly UpdateDefaultsPath = '/api/harvests/defaults';

  /**
   * Update Defaults.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDefaults()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDefaults$Response(params: {
    customerId?: number;
    body: HarvestCustomerDefaultsDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.UpdateDefaultsPath, 'put');
    if (params) {
      rb.query('customerId', params.customerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update Defaults.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDefaults$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDefaults(params: {
    customerId?: number;
    body: HarvestCustomerDefaultsDto
  }): Observable<void> {

    return this.updateDefaults$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDefaults_1
   */
  static readonly GetDefaults_1Path = '/api/harvests/defaults-values';

  /**
   * Get Defaults.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaults_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaults_1$Response(params?: {
  }): Observable<StrictHttpResponse<HarvestCustomerDefaultsDto>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetDefaults_1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestCustomerDefaultsDto>;
      })
    );
  }

  /**
   * Get Defaults.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDefaults_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaults_1(params?: {
  }): Observable<HarvestCustomerDefaultsDto> {

    return this.getDefaults_1$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestCustomerDefaultsDto>) => r.body as HarvestCustomerDefaultsDto)
    );
  }

  /**
   * Path part for operation addEvent
   */
  static readonly AddEventPath = '/api/harvests/event';

  /**
   * Add Event.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addEvent$Response(params: {
    body: EventDto
  }): Observable<StrictHttpResponse<EventCreateResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.AddEventPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventCreateResponseDto>;
      })
    );
  }

  /**
   * Add Event.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addEvent(params: {
    body: EventDto
  }): Observable<EventCreateResponseDto> {

    return this.addEvent$Response(params).pipe(
      map((r: StrictHttpResponse<EventCreateResponseDto>) => r.body as EventCreateResponseDto)
    );
  }

  /**
   * Path part for operation getFacilityGroupEvents
   */
  static readonly GetFacilityGroupEventsPath = '/api/harvests/facilityGroup/{facilityGroupId}/events';

  /**
   * Get Facility Group Events.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFacilityGroupEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityGroupEvents$Response(params: {
    facilityGroupId: number;
  }): Observable<StrictHttpResponse<Array<HarvestEvent>>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetFacilityGroupEventsPath, 'get');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HarvestEvent>>;
      })
    );
  }

  /**
   * Get Facility Group Events.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFacilityGroupEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityGroupEvents(params: {
    facilityGroupId: number;
  }): Observable<Array<HarvestEvent>> {

    return this.getFacilityGroupEvents$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HarvestEvent>>) => r.body as Array<HarvestEvent>)
    );
  }

  /**
   * Path part for operation getHarvests
   */
  static readonly GetHarvestsPath = '/api/harvests/facilityGroupId/{facilityGroupId}';

  /**
   * Get Harvests.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHarvests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvests$Response(params: {
    facilityGroupId: number;
    filterDate?: string;
  }): Observable<StrictHttpResponse<Array<HarvestDto>>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetHarvestsPath, 'get');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
      rb.query('filterDate', params.filterDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HarvestDto>>;
      })
    );
  }

  /**
   * Get Harvests.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHarvests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvests(params: {
    facilityGroupId: number;
    filterDate?: string;
  }): Observable<Array<HarvestDto>> {

    return this.getHarvests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HarvestDto>>) => r.body as Array<HarvestDto>)
    );
  }

  /**
   * Path part for operation getLastHarvestsForFacilityGroup
   */
  static readonly GetLastHarvestsForFacilityGroupPath = '/api/harvests/facilityGroupId/{facilityGroupId}/last';

  /**
   * Get Last Harvests For Facility Group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLastHarvestsForFacilityGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastHarvestsForFacilityGroup$Response(params: {
    facilityGroupId: number;
  }): Observable<StrictHttpResponse<Array<HarvestFullResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetLastHarvestsForFacilityGroupPath, 'get');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HarvestFullResponse>>;
      })
    );
  }

  /**
   * Get Last Harvests For Facility Group.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLastHarvestsForFacilityGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLastHarvestsForFacilityGroup(params: {
    facilityGroupId: number;
  }): Observable<Array<HarvestFullResponse>> {

    return this.getLastHarvestsForFacilityGroup$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HarvestFullResponse>>) => r.body as Array<HarvestFullResponse>)
    );
  }

  /**
   * Path part for operation getWaterConsumptionPerformace
   */
  static readonly GetWaterConsumptionPerformacePath = '/api/harvests/waterConsumptionPerformace/{harvestId}/{deviceId}';

  /**
   * Get Water Consumption Performace.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWaterConsumptionPerformace()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWaterConsumptionPerformace$Response(params: {
    deviceId: number;
    harvestId: number;
    fillMissingDates?: boolean;
  }): Observable<StrictHttpResponse<WaterConsumptionDto>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetWaterConsumptionPerformacePath, 'get');
    if (params) {
      rb.path('deviceId', params.deviceId, {});
      rb.path('harvestId', params.harvestId, {});
      rb.query('fillMissingDates', params.fillMissingDates, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WaterConsumptionDto>;
      })
    );
  }

  /**
   * Get Water Consumption Performace.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWaterConsumptionPerformace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWaterConsumptionPerformace(params: {
    deviceId: number;
    harvestId: number;
    fillMissingDates?: boolean;
  }): Observable<WaterConsumptionDto> {

    return this.getWaterConsumptionPerformace$Response(params).pipe(
      map((r: StrictHttpResponse<WaterConsumptionDto>) => r.body as WaterConsumptionDto)
    );
  }

  /**
   * Path part for operation getHarvestFullResponse
   */
  static readonly GetHarvestFullResponsePath = '/api/harvests/{harvestId}';

  /**
   * Get Harvest Full Response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHarvestFullResponse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestFullResponse$Response(params: {
    harvestId: number;
  }): Observable<StrictHttpResponse<HarvestFullResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetHarvestFullResponsePath, 'get');
    if (params) {
      rb.path('harvestId', params.harvestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestFullResponse>;
      })
    );
  }

  /**
   * Get Harvest Full Response.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHarvestFullResponse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestFullResponse(params: {
    harvestId: number;
  }): Observable<HarvestFullResponse> {

    return this.getHarvestFullResponse$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestFullResponse>) => r.body as HarvestFullResponse)
    );
  }

  /**
   * Path part for operation updateHarvest
   */
  static readonly UpdateHarvestPath = '/api/harvests/{harvestId}';

  /**
   * Update Harvest.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateHarvest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHarvest$Response(params: {
    harvestId: number;
    body: HarvestUpdateRequest
  }): Observable<StrictHttpResponse<HarvestFullResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.UpdateHarvestPath, 'put');
    if (params) {
      rb.path('harvestId', params.harvestId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestFullResponse>;
      })
    );
  }

  /**
   * Update Harvest.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateHarvest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHarvest(params: {
    harvestId: number;
    body: HarvestUpdateRequest
  }): Observable<HarvestFullResponse> {

    return this.updateHarvest$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestFullResponse>) => r.body as HarvestFullResponse)
    );
  }

  /**
   * Path part for operation closeHarvest
   */
  static readonly CloseHarvestPath = '/api/harvests/{harvestId}/close-harvest';

  /**
   * Close Harvest.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeHarvest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeHarvest$Response(params: {
    harvestId: number;
    body: HarvestCloseRequest
  }): Observable<StrictHttpResponse<HarvestBasicResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.CloseHarvestPath, 'put');
    if (params) {
      rb.path('harvestId', params.harvestId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestBasicResponse>;
      })
    );
  }

  /**
   * Close Harvest.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `closeHarvest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeHarvest(params: {
    harvestId: number;
    body: HarvestCloseRequest
  }): Observable<HarvestBasicResponse> {

    return this.closeHarvest$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestBasicResponse>) => r.body as HarvestBasicResponse)
    );
  }

  /**
   * Path part for operation getEnvironmentalReadings
   */
  static readonly GetEnvironmentalReadingsPath = '/api/harvests/{harvestId}/environmental-readings';

  /**
   * Get Environmental Readings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnvironmentalReadings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnvironmentalReadings$Response(params: {
    harvestId: number;
    groupIdx?: Array<number>;
  }): Observable<StrictHttpResponse<Array<EnvironmentalReadingResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetEnvironmentalReadingsPath, 'get');
    if (params) {
      rb.path('harvestId', params.harvestId, {});
      rb.query('groupIdx', params.groupIdx, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EnvironmentalReadingResponse>>;
      })
    );
  }

  /**
   * Get Environmental Readings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnvironmentalReadings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnvironmentalReadings(params: {
    harvestId: number;
    groupIdx?: Array<number>;
  }): Observable<Array<EnvironmentalReadingResponse>> {

    return this.getEnvironmentalReadings$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EnvironmentalReadingResponse>>) => r.body as Array<EnvironmentalReadingResponse>)
    );
  }

  /**
   * Path part for operation addEvent_1
   */
  static readonly AddEvent_1Path = '/api/harvests/{harvestId}/event';

  /**
   * Add Event.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addEvent_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addEvent_1$Response(params: {
    harvestId: number;
    body: Array<HarvestEventDto>
  }): Observable<StrictHttpResponse<Array<HarvestEvent>>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.AddEvent_1Path, 'post');
    if (params) {
      rb.path('harvestId', params.harvestId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HarvestEvent>>;
      })
    );
  }

  /**
   * Add Event.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addEvent_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addEvent_1(params: {
    harvestId: number;
    body: Array<HarvestEventDto>
  }): Observable<Array<HarvestEvent>> {

    return this.addEvent_1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HarvestEvent>>) => r.body as Array<HarvestEvent>)
    );
  }

  /**
   * Path part for operation updateEvent
   */
  static readonly UpdateEventPath = '/api/harvests/{harvestId}/event/{eventId}';

  /**
   * Update Event.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEvent$Response(params: {
    eventId: number;
    harvestId: number;
    body: HarvestEventDto
  }): Observable<StrictHttpResponse<HarvestEvent>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.UpdateEventPath, 'put');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.path('harvestId', params.harvestId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestEvent>;
      })
    );
  }

  /**
   * Update Event.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEvent(params: {
    eventId: number;
    harvestId: number;
    body: HarvestEventDto
  }): Observable<HarvestEvent> {

    return this.updateEvent$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestEvent>) => r.body as HarvestEvent)
    );
  }

  /**
   * Path part for operation deleteEvent
   */
  static readonly DeleteEventPath = '/api/harvests/{harvestId}/event/{eventId}';

  /**
   * Delete Event.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEvent$Response(params: {
    eventId: number;
    harvestId: number;
  }): Observable<StrictHttpResponse<HarvestEvent>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.DeleteEventPath, 'delete');
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.path('harvestId', params.harvestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestEvent>;
      })
    );
  }

  /**
   * Delete Event.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEvent(params: {
    eventId: number;
    harvestId: number;
  }): Observable<HarvestEvent> {

    return this.deleteEvent$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestEvent>) => r.body as HarvestEvent)
    );
  }

  /**
   * Path part for operation getEvents
   */
  static readonly GetEventsPath = '/api/harvests/{harvestId}/events';

  /**
   * Get Events.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEvents$Response(params: {
    harvestId: number;
  }): Observable<StrictHttpResponse<Array<HarvestEvent>>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetEventsPath, 'get');
    if (params) {
      rb.path('harvestId', params.harvestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HarvestEvent>>;
      })
    );
  }

  /**
   * Get Events.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEvents(params: {
    harvestId: number;
  }): Observable<Array<HarvestEvent>> {

    return this.getEvents$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HarvestEvent>>) => r.body as Array<HarvestEvent>)
    );
  }

  /**
   * Path part for operation updateFeed
   */
  static readonly UpdateFeedPath = '/api/harvests/{harvestId}/feed';

  /**
   * Update Feed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFeed()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFeed$Response(params: {
    harvestId: number;
    body: Array<DatedValueDto>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.UpdateFeedPath, 'put');
    if (params) {
      rb.path('harvestId', params.harvestId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update Feed.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFeed$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFeed(params: {
    harvestId: number;
    body: Array<DatedValueDto>
  }): Observable<void> {

    return this.updateFeed$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getFullHarvest
   */
  static readonly GetFullHarvestPath = '/api/harvests/{harvestId}/full-harvest';

  /**
   * Get Full Harvest.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFullHarvest()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullHarvest$Response(params: {
    harvestId: number;
  }): Observable<StrictHttpResponse<FacilityHarvestDto>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetFullHarvestPath, 'get');
    if (params) {
      rb.path('harvestId', params.harvestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityHarvestDto>;
      })
    );
  }

  /**
   * Get Full Harvest.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFullHarvest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullHarvest(params: {
    harvestId: number;
  }): Observable<FacilityHarvestDto> {

    return this.getFullHarvest$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityHarvestDto>) => r.body as FacilityHarvestDto)
    );
  }

  /**
   * Path part for operation updateGroupDevices
   */
  static readonly UpdateGroupDevicesPath = '/api/harvests/{harvestId}/group/devices';

  /**
   * Update Group Devices.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGroupDevices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroupDevices$Response(params: {
    harvestId: number;
    body: Array<DeviceInUseDto>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.UpdateGroupDevicesPath, 'put');
    if (params) {
      rb.path('harvestId', params.harvestId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update Group Devices.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateGroupDevices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroupDevices(params: {
    harvestId: number;
    body: Array<DeviceInUseDto>
  }): Observable<void> {

    return this.updateGroupDevices$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getGroupDevices
   */
  static readonly GetGroupDevicesPath = '/api/harvests/{harvestId}/group/{groupIdx}/devices';

  /**
   * Get Group Devices.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroupDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupDevices$Response(params: {
    groupIdx: number;
    harvestId: number;
  }): Observable<StrictHttpResponse<Array<DeviceInUseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetGroupDevicesPath, 'get');
    if (params) {
      rb.path('groupIdx', params.groupIdx, {});
      rb.path('harvestId', params.harvestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DeviceInUseDto>>;
      })
    );
  }

  /**
   * Get Group Devices.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGroupDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupDevices(params: {
    groupIdx: number;
    harvestId: number;
  }): Observable<Array<DeviceInUseDto>> {

    return this.getGroupDevices$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DeviceInUseDto>>) => r.body as Array<DeviceInUseDto>)
    );
  }

  /**
   * Path part for operation updateGroupDevices_1
   */
  static readonly UpdateGroupDevices_1Path = '/api/harvests/{harvestId}/group/{groupIdx}/devices';

  /**
   * Update Group Devices.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGroupDevices_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroupDevices_1$Response(params: {
    groupIdx: number;
    harvestId: number;
    body: Array<DeviceInUseDto>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.UpdateGroupDevices_1Path, 'put');
    if (params) {
      rb.path('groupIdx', params.groupIdx, {});
      rb.path('harvestId', params.harvestId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update Group Devices.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateGroupDevices_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroupDevices_1(params: {
    groupIdx: number;
    harvestId: number;
    body: Array<DeviceInUseDto>
  }): Observable<void> {

    return this.updateGroupDevices_1$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getMortality
   */
  static readonly GetMortalityPath = '/api/harvests/{harvestId}/mortality';

  /**
   * Get Mortality.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortality()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortality$Response(params: {
    harvestId: number;
    groupIdx?: Array<number>;
  }): Observable<StrictHttpResponse<MortalityResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetMortalityPath, 'get');
    if (params) {
      rb.path('harvestId', params.harvestId, {});
      rb.query('groupIdx', params.groupIdx, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortalityResponse>;
      })
    );
  }

  /**
   * Get Mortality.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMortality$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortality(params: {
    harvestId: number;
    groupIdx?: Array<number>;
  }): Observable<MortalityResponse> {

    return this.getMortality$Response(params).pipe(
      map((r: StrictHttpResponse<MortalityResponse>) => r.body as MortalityResponse)
    );
  }

  /**
   * Path part for operation putMortality
   */
  static readonly PutMortalityPath = '/api/harvests/{harvestId}/mortality';

  /**
   * Put Mortality.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMortality()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMortality$Response(params: {
    harvestId: number;
    body: Array<MortalityRequest>
  }): Observable<StrictHttpResponse<Array<MortalityTableRow>>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.PutMortalityPath, 'put');
    if (params) {
      rb.path('harvestId', params.harvestId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MortalityTableRow>>;
      })
    );
  }

  /**
   * Put Mortality.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMortality$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMortality(params: {
    harvestId: number;
    body: Array<MortalityRequest>
  }): Observable<Array<MortalityTableRow>> {

    return this.putMortality$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MortalityTableRow>>) => r.body as Array<MortalityTableRow>)
    );
  }

  /**
   * Path part for operation getMortalityAnalysisData
   */
  static readonly GetMortalityAnalysisDataPath = '/api/harvests/{harvestId}/mortality-analysis-data';

  /**
   * Get Mortality Analysis Data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortalityAnalysisData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortalityAnalysisData$Response(params: {
    harvestId: number;
  }): Observable<StrictHttpResponse<MortalityAnalysisResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetMortalityAnalysisDataPath, 'get');
    if (params) {
      rb.path('harvestId', params.harvestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortalityAnalysisResponse>;
      })
    );
  }

  /**
   * Get Mortality Analysis Data.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMortalityAnalysisData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortalityAnalysisData(params: {
    harvestId: number;
  }): Observable<MortalityAnalysisResponse> {

    return this.getMortalityAnalysisData$Response(params).pipe(
      map((r: StrictHttpResponse<MortalityAnalysisResponse>) => r.body as MortalityAnalysisResponse)
    );
  }

  /**
   * Path part for operation getMortalityWidgetResponse
   */
  static readonly GetMortalityWidgetResponsePath = '/api/harvests/{harvestId}/mortality-widget';

  /**
   * Get Mortality Widget Response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortalityWidgetResponse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortalityWidgetResponse$Response(params: {
    harvestId: number;
  }): Observable<StrictHttpResponse<MortalityWidgetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestService.GetMortalityWidgetResponsePath, 'get');
    if (params) {
      rb.path('harvestId', params.harvestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortalityWidgetResponse>;
      })
    );
  }

  /**
   * Get Mortality Widget Response.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMortalityWidgetResponse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortalityWidgetResponse(params: {
    harvestId: number;
  }): Observable<MortalityWidgetResponse> {

    return this.getMortalityWidgetResponse$Response(params).pipe(
      map((r: StrictHttpResponse<MortalityWidgetResponse>) => r.body as MortalityWidgetResponse)
    );
  }

}
