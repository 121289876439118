<ng-container *ngIf="mode !== 'report'">
  <div class="display-flex space-between align-center">
    <div class="menus__menu width-25" *ngIf="events">
      <div class="menus__menu-label">{{ 'COMMON.SELECTORS.Search' |translate }}</div>
      <div class="menus__menu-selector">
        <input type="text" (input)="search($event.target.value)">
      </div>
    </div>
    <div class="add-event-button-wrapper width-100">
      <button [disabled]="(!getHouseCloseDate || isOutsideHouse) && !currentCropBehaviourService.isSuperSupportAdmin"
              [class.disabled]="(!getHouseCloseDate || isOutsideHouse) && !currentCropBehaviourService.isSuperSupportAdmin"
              [class.none]="!permissionService.hasPermission(myFarmCropCardAddEvents)"
              class="blue-rounded-button add-event-button" (click)="isAddNewEventModal = true">
        {{ 'CROP_CARD.+_Add_Event' | translate }}
      </button>
    </div>
  </div>
</ng-container>

<table class="reports-table">
  <thead>
  <tr>
    <th class="sortable" (click)="sortRows(EventProperties.date)">{{ 'CROP_CARD.Date' | translate }}
      <span class="svg arrow-downward-svg"
            *ngIf="isCurrentColumn(EventProperties.date, SortingDirection.Desc)"></span>
      <span class="svg arrow-upward-svg"
            *ngIf="isCurrentColumn(EventProperties.date, SortingDirection.Asc)"></span>
    </th>
    <th class="sortable" (click)="sortRows(EventProperties.birdAge)">{{ 'COMMON.TABLES.Age' | translate }}
      <span class="svg arrow-downward-svg"
            *ngIf="isCurrentColumn(EventProperties.birdAge, SortingDirection.Desc)"></span>
      <span class="svg arrow-upward-svg"
            *ngIf="isCurrentColumn(EventProperties.birdAge, SortingDirection.Asc)"></span>
    </th>
    <th class="sortable" (click)="sortRows(EventProperties.type)">{{ 'CROP_CARD.Event_Type' | translate }}
      <span class="svg arrow-downward-svg"
            *ngIf="isCurrentColumn(EventProperties.type, SortingDirection.Desc)"></span>
      <span class="svg arrow-upward-svg"
            *ngIf="isCurrentColumn(EventProperties.type, SortingDirection.Asc)"></span>
    </th>
    <th>{{ 'CROP_CARD.Event_Data' | translate }}</th>
    <th class="sortable" (click)="sortRows(EventProperties.creator)">{{ 'EVENT.creator' | translate }}
      <span class="svg arrow-downward-svg"
            *ngIf="isCurrentColumn(EventProperties.type, SortingDirection.Desc)"></span>
      <span class="svg arrow-upward-svg"
            *ngIf="isCurrentColumn(EventProperties.type, SortingDirection.Asc)"></span>
    </th>
    <th *ngIf="isShowActions()">
      <div class="text-align-center bold">{{ 'EVENT.actions' | translate }}</div>
    </th>
    <th>
      <div class="text-align-center bold">{{ 'EVENT.Status' | translate }}</div>
    </th>
  </tr>
  </thead>
  <tbody *ngIf="!containsData">
  <tr>
    <td class="no-records" colspan="7">
      <no-data-available></no-data-available>
    </td>
  </tr>
  </tbody>
  <tbody *ngIf="containsData">
  <ng-container *ngIf="events">
    <tr *ngFor="let event of events; let i = index">
      <td>{{ event?.date | date : translationService.localeLongDateTimeFormatMinutesOnly }}</td>
      <td>{{ event?.birdAge }} {{ ("CROP_CARD." + (event.birdAge === 1 ? "day" : "days")) | translate }}</td>
      <td>{{ "EVENT.TYPES." + event?.type | translate }}</td>
      <td class="display-flex space-between">
        <div class="mr-minus25">
          <div *ngFor="let item of event?.values" class="display-flex">
            <ng-container
              *ngIf="event.type !== eventTypesEnum.FlushWaterLine && event.type !== eventTypesEnum.OtherEvents">
              <b>{{ "EVENT." + item?.name | translate }}</b>:
              <span *ngIf="item.name !== 'Kg' && item.name !== 'totalKg'">{{ item?.value }}</span>
              <span
                *ngIf="item.name === 'Kg' || item.name === 'totalKg'">{{ item?.value | unitsConvert: 'kg': false: ' ': true }}</span>
            </ng-container>
            <ng-container
              *ngIf="event.type === eventTypesEnum.FlushWaterLine || event.type === eventTypesEnum.OtherEvents">
              <b
                *ngIf="item?.name !== 'isRecurring' && item?.name !== 'startBirdAge' && item?.name !== 'endBirdAge' ">{{ "EVENT." + item?.name | translate }}</b>
              <!--              <ng-container-->
              <!--                *ngIf="item?.name !== 'isRecurring' && item?.name !== 'startBirdAge' && item?.name !== 'endBirdAge'  && item?.name !== 'startDate'  && item?.name !== 'endDate'  && item?.name !== 'Interval' ">-->
              <!--                :<span class="item-value" *ngIf="item.name !== 'Kg' && item.name !== 'totalKg'">{{ item?.value }}</span>-->
              <!--                <span-->
              <!--                  *ngIf="item.name === 'Kg' || item.name === 'totalKg'">-->
              <!--                  {{ item?.value | unitsConvert: 'kg': false: ' ': true }}-->
              <!--                </span>-->
              <!--              </ng-container>-->
              <ng-container *ngIf="item.name === 'Comment'">:<span>{{ item?.value }}</span></ng-container>
              <ng-container *ngIf="item.name === 'Daily Estimate'">:<span>{{ item?.value }}</span></ng-container>
              <ng-container *ngIf="item.name === 'startDate' || item.name === 'endDate'">
                :<span>{{ item?.value  |  date: translationService.translationShortDateFormat }}</span>
              </ng-container>
              <ng-container *ngIf="item.name === 'intervalDays'">
                :
                <span *ngIf="+item?.value !== 1">{{'EVENT.every_number_days' | translate : null : item?.value }}</span>
                <span *ngIf="+item?.value === 1">{{'EVENT.every_number_day' | translate : null : item?.value }}</span>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div *ngIf="isEventRecurrent(event)" class="recurrent-marker">
          <span class="svg refresh-outlined-white"></span>
        </div>

      </td>
      <td>{{ event.createdBy }}</td>
      <td *ngIf="isShowActions()">
        <div class="center">
          <ng-container *ngIf="currentCropBehaviourService.isEditable === true">
            <ng-container *ngIf="event.type !== 'Brooding'">
              <span class="svg edit-blue cursor-pointer" (click)="editEvent(event, i)"
                    [class.color-gray]="!permissionService.hasPermission(myFarmCropCardEditEvents)"></span>
              <span class="color-gray">|</span>
              <span *ngIf="event.isDone === true || event.type === 'Planned Clearing Age'"
                    class="svg delete-inactive"
                    [class.color-gray]="!permissionService.hasPermission(myFarmCropCardDeleteEvents)"></span>
              <span *ngIf="event.isDone !== true && event.type !== 'Planned Clearing Age'"
                    class="svg delete-blue"
                    [class.color-gray]="!permissionService.hasPermission(myFarmCropCardDeleteEvents)"
                    (click)="deleteEvent(event)"></span>
              <span class="color-gray">|</span>
              <span *ngIf="event.isDone === true" class="svg complete-green"></span>
              <span *ngIf="event.isDone !== true"
                    [class.color-gray]="!permissionService.hasPermission(myFarmCropCardCompleteEvents)"
                    class="svg complete-blue cursor-pointer"
                    (click)="markEventAsDone(event, event.type)"></span>
            </ng-container>
            <ng-container *ngIf="event.type === 'Brooding'">
          <span
            *ngIf="isEditBroodingButtonActive(event,i)"
            class="svg edit-blue cursor-pointer"
            (click)="editEvent(event, i)"
            [class.color-gray]="!permissionService.hasPermission(myFarmCropCardEditBrooding)"></span>
              <span
                *ngIf="!isEditBroodingButtonActive(event,i)"
                class="svg edit-inactive"
                [class.color-gray]="!permissionService.hasPermission(myFarmCropCardEditBrooding)"></span>
              <span class="color-gray">|</span>
              <span *ngIf="!isDeleteButtonActive(event,i)"
                    class="svg delete-inactive"
                    [class.color-gray]="!permissionService.hasPermission(myFarmCropCardDeleteBrooding)"></span>
              <span *ngIf="isDeleteButtonActive(event,i)"
                    class="svg delete-blue cursor-pointer"
                    [class.color-gray]="!permissionService.hasPermission(myFarmCropCardDeleteBrooding)"
                    (click)="deleteEvent(event)"></span>
              <span class="color-gray">|</span>
              <span *ngIf="event.isDone === true" class="svg complete-green"></span>
              <span *ngIf="event.isDone !== true"
                    [class.color-gray]="!permissionService.hasPermission(myFarmCropCardAddBrooding)"
                    class="svg complete-blue cursor-pointer"
                    (click)="markEventAsDone(event, event.type)"></span>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="currentCropBehaviourService.isEditable === false">
            <span class="svg edit-inactive"></span>
            <span class="color-gray">|</span>
            <span class="svg delete-inactive"></span>
            <span class="color-gray">|</span>
            <span class="svg complete-disabled"></span>
          </ng-container>
        </div>
      </td>
      <td>
        <div class="text-align-center" *ngIf="event.isDone === true">{{ 'EVENT.Completed' | translate }}</div>
        <div class="text-align-center" *ngIf="event.isDone !== true">{{ 'EVENT.Pending' | translate }}</div>
      </td>
    </tr>
  </ng-container>
  </tbody>
</table>

<add-event-modal
  *ngIf="isAddNewEventModal"
  (onCloseModal)="isAddNewEventModal = false"
  (onEventCreated)="eventCreated()"
  (onError)="showError($event)"
  (onNotification)="showNotification($event)"
  [allEvents]="events"
  [currentHarvest]="currentHarvest">
</add-event-modal>

<edit-event-modal
  *ngIf="isEditEventModal"
  [isComplete]="isCompleteEvent"
  [currentEventIndex]="currentEventIndex"
  (onCloseModal)="isEditEventModal = false; eventUpdated()"
  (onEventCreated)="eventUpdated()"
  [allEvents]="events"
  [currentHarvest]="currentHarvest"
  [currentEvent]="currentEvent"
>
</edit-event-modal>

<notification-modal [name]="notificationContent.name" *ngIf="isShowNotificationModal" [content]="notificationContent"
                    (onClose)="closeNotificationModal()"></notification-modal>
