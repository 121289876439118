import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import * as _ from 'lodash';
import * as tz from 'moment-timezone';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  Observable,
  Subject,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  pairwise,
  take,
  takeUntil,
} from 'rxjs/operators';
import { CustomerResponse } from '../api/models/customer-response';
import { FacilityDto } from '../api/models/facility-dto';
import { FacilityGroupDto } from '../api/models/facility-group-dto';
import { HarvestBasicResponse } from '../api/models/harvest-basic-response';
import { HarvestMetricType } from '../api/models/harvest-metric-type';
import { ApplicationSettingsService } from '../api/services/application-settings.service';
import { CustomerService } from '../api/services/customer.service';
import { FacilityGroupService } from '../api/services/facility-group.service';
import { FacilityService } from '../api/services/facility.service';
import { ConfigService } from '../config/config.service';
import { ErrorsService } from '../errors-service/errors.service';
import { TranslationService } from '../translations/translation.service';
import { USER_RES_KEY, UserDetailsService } from '../user/user-details.service';
import { HarvestService } from '../api/services/harvest.service';
import { HarvestGroupResponseDto } from '../api/models/harvest-group-response-dto';
import moment from 'moment';

interface IStateProps {
  customerId?: number;
  farmId?: number;
  houseId?: number;
  cropId?: number;
  groupId?: number;
}

export interface IGroup {
  groupId?: number;
  groupName?: string;
  houseId?: number;
  selected?: boolean;
}

// tslint:disable-next-line:no-any
declare let pendo: any;

export const SESSION_KEY = 'customer-selector-session';
export const SAVED_DEFAULT_DATE_KEY = 'saved-default-date';

@Injectable({ providedIn: 'root' })
export class CustomerSelectorsService implements OnDestroy {
  public isValuesInitialized$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isCustomersInitialized$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public negativeUsersSystemTimeZoneOffset: number;

  public selectedKpis = [];
  public allSelectedKpis = [];
  public selectedTargetKPI;
  public selectedMinMaxAmbient;
  public currentSensorsPageGraphWeekCount;

  public currentComparisonPageGraphWeekCount;
  public showAverageOfCrops: boolean = true;
  public showIndividualOfCrops: boolean = true;

  public selectedComparisonCrops: any[] = [];
  public selectedComparisonParameter: any = null;
  public selectedComparisonTargetIndex: number = 0;
  public selectedComparisonAmbientIndex: number = 0;

  public comparisonSelectedCropParameter: string =
    HarvestMetricType.Temperature;

  public groups: HarvestGroupResponseDto[] = [];

  public comparisonSelectedSectionB = {
    customerId: null,
    farmId: null,
    houseId: null,
    cropIds: [],
  };

  public get isValuesInitialized() {
    return this.isValuesInitialized$.value;
  }

  public get isCustomerInitialized() {
    return this.isCustomersInitialized$.value;
  }

  public get isServiceInitialized() {
    return this.isCustomerInitialized && this.isValuesInitialized;
  }

  public farmsFullList$: BehaviorSubject<FacilityGroupDto[]> =
    new BehaviorSubject<FacilityGroupDto[]>(undefined);
  public customersList$: BehaviorSubject<CustomerResponse[]> =
    new BehaviorSubject<CustomerResponse[]>(undefined);
  public farmsList$: BehaviorSubject<FacilityGroupDto[]> = new BehaviorSubject<
    FacilityGroupDto[]
  >(undefined);
  public housesList$: BehaviorSubject<FacilityDto[]> = new BehaviorSubject<
    FacilityDto[]
  >(undefined);
  public cropsList$: BehaviorSubject<HarvestBasicResponse[]> =
    new BehaviorSubject<HarvestBasicResponse[]>(undefined);
  public groupsList$: BehaviorSubject<HarvestGroupResponseDto[]> =
    new BehaviorSubject<HarvestGroupResponseDto[]>(undefined);
  public currentCustomer$: BehaviorSubject<CustomerResponse> =
    new BehaviorSubject<CustomerResponse>(undefined);
  public currentFarm$: BehaviorSubject<FacilityGroupDto> =
    new BehaviorSubject<FacilityGroupDto>(undefined);
  public currentHouse$: BehaviorSubject<FacilityDto> =
    new BehaviorSubject<FacilityDto>(undefined);
  public currentCrop$: BehaviorSubject<HarvestBasicResponse> =
    new BehaviorSubject<HarvestBasicResponse>(undefined);
  public currentGroup$: BehaviorSubject<HarvestGroupResponseDto> =
    new BehaviorSubject<HarvestGroupResponseDto>(undefined);

  public get farmsFullList(): FacilityGroupDto[] {
    return this.farmsFullList$.value;
  }

  public get customersList(): CustomerResponse[] {
    return this.customersList$.value;
  }

  public get groupsList(): HarvestGroupResponseDto[] {
    return this.groupsList$.value;
  }

  public get farmsList(): FacilityGroupDto[] {
    return this.farmsList$.value;
  }

  public get housesList(): FacilityDto[] {
    return this.housesList$.value;
  }

  public get cropsList(): HarvestBasicResponse[] {
    return this.cropsList$.value;
  }

  public get currentCrop(): HarvestBasicResponse {
    return this.currentCrop$.value;
  }

  public get currentGroup(): IGroup {
    return this.currentGroup$.value;
  }

  public get currentCustomer(): CustomerResponse {
    return this.currentCustomer$.value;
  }

  public get currentFarm(): FacilityGroupDto {
    return this.currentFarm$.value;
  }

  public get currentFarmTimeZoneOffset(): string {
    if (!!this.currentFarm?.timeZone) {
      const tzoffset: number =
        tz?.tz
          ?.zone(this.currentFarm.timeZone)
          ?.utcOffset(new Date().getTime()) / -60;

      if (tzoffset >= 0) {
        return '+' + tzoffset.toString();
      } else {
        tzoffset.toString();
      }
      return tzoffset?.toString();
    }
  }

  public get currentHouse(): FacilityDto {
    return this.currentHouse$.value;
  }

  public get lastCrop(): HarvestBasicResponse {
    if (!!this.cropsList && this.cropsList.length > 0) {
      return this.cropsList[0];
    }
  }

  public get isLatestCropOpened() {
    return !this.lastCrop?.closeDate;
  }

  public get customerId(): number {
    return this.currentCustomer$?.value?.id;
  }

  public get farmId(): number {
    return this.currentFarm$?.value?.id;
  }

  public get houseId(): number {
    return this.currentHouse$?.value?.id;
  }

  public get groupId(): number {
    return this.currentGroup$?.value?.idx;
  }

  private destroyed$ = new Subject();

  private stateProps: IStateProps;

  public completeInitialized$: Observable<boolean>;

  public reservedCustomersList: CustomerResponse[];

  public setState(stateProps: IStateProps) {
    this.updateStateForService({
      ...this.stateProps,
      ...stateProps,
    });
  }

  public get state() {
    return JSON.parse(localStorage.getItem(SESSION_KEY));
  }

  public get savedDefaultDate() {
    return JSON.parse(localStorage.getItem(SAVED_DEFAULT_DATE_KEY));
  }

  constructor(
    private customerService: CustomerService,
    private configService: ConfigService,
    private facilityGroupService: FacilityGroupService,
    private userDataService: UserDetailsService,
    private facilityService: FacilityService,
    private router: Router,
    private errorsService: ErrorsService,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslationService,
    private applicationSettingsService: ApplicationSettingsService,
    private harvestService: HarvestService
  ) {
    this.customersList$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((customersList) => {
        if (customersList === undefined) {
          return;
        }
        this.onCustomerChange(this.state?.customerId ?? this.customerId);
      });
    this.currentCustomer$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((customer) => {
        if (customer && customer.id) {
          this.setState({ customerId: customer?.id });
          this.getFacilityGroupsByCustomer();
          this.updateFarmsListByCustomer();
        }
      });
    this.farmsList$.pipe(takeUntil(this.destroyed$)).subscribe((farmsList) => {
      if (farmsList === undefined) {
        return;
      }
      this.onFarmChange(this.state?.farmId ?? this.farmId);
    });
    this.currentFarm$.pipe(takeUntil(this.destroyed$)).subscribe((farm) => {
      if (farm && farm.id) {
        this.setState({ farmId: farm?.id });
        const outsideHouse = farm?.facilities.find(
          (f) => f.houseType === 'OUTSIDE'
        );
        if (outsideHouse) {
          farm.facilities = farm?.facilities.filter(
            (f) => f.houseType !== 'OUTSIDE'
          );
          farm?.facilities.push(outsideHouse);
        }

        this.housesList$.next(farm?.facilities);
        this.updateGroupsListByHouse();
        this.groupsList$.next(this.groupsList);
        const systemOffset: number =
          tz?.tz
            ?.zone(Intl.DateTimeFormat().resolvedOptions().timeZone)
            ?.utcOffset(new Date().getTime()) / -60;
        if (!!systemOffset) {
          this.negativeUsersSystemTimeZoneOffset = systemOffset / -1;
        }
      }
    });
    this.housesList$.pipe(takeUntil(this.destroyed$)).subscribe((houseList) => {
      if (houseList === undefined) {
        this.onHouseChange(undefined);
        return;
      }
      this.onHouseChange(this.state?.houseId ?? this.houseId);
      this.updatePendo();
    });
    this.groupsList$.pipe(takeUntil(this.destroyed$)).subscribe((groupList) => {
      if (groupList === undefined) {
        this.onGroupChange(undefined);
        return;
      }
      this.onGroupChange(this.state?.groupId ?? this.groupId);
    });
    this.currentHouse$.pipe(takeUntil(this.destroyed$)).subscribe((house) => {
      if (house && house?.id) {
        this.setState({ houseId: house?.id });
        this.getCropsList(house?.id);
      }
    });
    this.cropsList$.pipe(takeUntil(this.destroyed$)).subscribe((cropList) => {
      if (cropList === undefined) {
        return;
      }
      this.onCropChange(this.currentCrop$?.value?.id);
    });
    this.currentCrop$.pipe(takeUntil(this.destroyed$)).subscribe((crop) => {
      if (crop && crop?.id) {
        this.setState({
          cropId: crop?.id,
        });
      }
    });
    // Hide/Show customers according to LUX behaviour
    router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        const prevUrl = events[0].urlAfterRedirects;
        const currUrl = events[1].urlAfterRedirects;
        if (currUrl === '/hourlyLux') {
          const customersListWithLUXEnabled = this.customersList.filter(
            (customer) => customer.luxEnable === true
          );
          this.customersList$.next(customersListWithLUXEnabled);
        } else if (prevUrl === '/hourlyLux') {
          this.customersList$.next(this.reservedCustomersList);
        }
      });
    this.translationService.languageChangeSubject
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.updatePendo());
  }

  public getCustomerCustomers() {
    const result = this.customerService.getCustomers().pipe(
      takeUntil(this.destroyed$),
      take(1),
      catchError(() => {
        this.errorsService.isShowError$.next(true);
        return EMPTY;
      })
    );
    result.subscribe((res) => {
      this.reservedCustomersList = _.cloneDeep(res);
      this.customersList$.next(res);
      this.isCustomersInitialized$.next(true);
      if (this.customerId) {
        let currentCustomer = res.find(
          (customer) => customer.id === this.customerId
        );
        if (!currentCustomer) {
          currentCustomer = res[0];
        }
        if (currentCustomer !== this.currentCustomer$.value) {
          this.currentCustomer$.next(currentCustomer);
        }
      }
    });
  }

  public getFacilityGroupsByCustomer() {
    const result = this.facilityGroupService
      .getFacilityGroupsByCustomer({
        customerId: this.customerId,
      })
      .pipe(takeUntil(this.destroyed$), take(1));
    result.subscribe((res) => {
      this.farmsFullList$.next(res);
      this.updateFarmsListByCustomer();
      this.isValuesInitialized$.next(true);
    });
    return result;
  }

  public getCropsList(id: number) {
    if (id) {
      this.facilityService
        .gatHarvestBasicResponse({ facilityId: id })
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res) => {
          const cropsList = res.sort((c1, c2) => c2.idx - c1.idx);
          this.cropsList$.next(cropsList);
        });
    } else {
      this.cropsList$.next([]);
    }
  }

  public completeInitialize() {
    this.getCustomerCustomers(); // contains customersList
    if (this.customerId) {
      this.getFacilityGroupsByCustomer();
    }
    this.completeInitialized$ = combineLatest([
      this.isValuesInitialized$,
      this.isCustomersInitialized$,
    ]).pipe(
      takeUntil(this.destroyed$),
      map((values) => values[0] && values[1]),
      filter((value) => value),
      take(1)
    );
    return this.completeInitialized$;
  }

  public reloadFarms() {
    // this.isValuesInitialized$.next(false);
    // this.farmsFullList$.next(undefined);
    return this.getFacilityGroupsByCustomer();
  }

  public onCustomerChange(id: number) {
    if (this.customersList?.length > 0) {
      id = Number(id);
      const newCustomer =
        this.customersList.find((customer) => customer.id === id) ??
        this.customersList[0];
      this.selectedComparisonCrops = [];
      this.selectedComparisonParameter = null;
      this.selectedComparisonTargetIndex = 0;
      this.currentCustomer$.next(newCustomer);
    }
  }

  public onFarmChange(id: number) {
    id = Number(id);
    if (this.farmsList) {
      const newFarm =
        this.farmsList.find((farm) => farm.id === id) ?? this.farmsList[0];
      this.currentFarm$.next(newFarm);
    }
  }

  public onHouseChange(id: number, updatedHouse?: FacilityDto) {
    id = Number(id);
    if (this.housesList) {
      const newHouse =
        this.housesList.find((house) => house.id === id) ?? this.housesList[0];
      if (updatedHouse && updatedHouse !== newHouse) {
        Object.assign(newHouse, updatedHouse);
      }
      this.currentHouse$.next(newHouse);
    } else if (isNaN(id) && !this.housesList) {
      this.currentHouse$.next(undefined);
    }
  }

  public onCropChange(id: number) {
    id = Number(id);
    if (this.cropsList) {
      const newCrop =
        this.cropsList.find((crop) => crop.id === id) ?? this.cropsList[0];
      this.currentCrop$.next(newCrop);
    } else {
      this.currentCrop$.next(undefined);
    }
  }

  public onGroupChange(id: number) {
    id = Number(id);
    if (this.groupsList) {
      const newGroup =
        this.groupsList.find((group) => group.idx === id) ?? this.groupsList[0];
      this.currentGroup$.next(newGroup);
    } else {
      this.currentGroup$.next(undefined);
    }
  }

  public resetService() {
    this.reservedCustomersList = [];
    this.isValuesInitialized$.next(false);
    this.isCustomersInitialized$.next(false);
    this.currentCrop$.next(undefined);
    this.cropsList$.next(undefined);
    this.currentHouse$.next(undefined);
    this.housesList$.next(undefined);
    this.groupsList$.next(undefined);
    this.currentFarm$.next(undefined);
    this.farmsList$.next(undefined);
    this.currentCustomer$.next(undefined);
    this.customersList$.next(undefined);
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public getFarmsByCustomer(customerId: number) {
    customerId = Number(customerId);
    return this.farmsFullList?.filter((farm) => farm.customerId === customerId);
  }

  public getGroupsByHouse(houseId: number) {
    houseId = Number(houseId);
    return this.groups;
  }

  public getFacilitiesByFarm(farmId: number) {
    farmId = Number(farmId);
    const farm = this.farmsFullList.find(
      (facilityGroup) => facilityGroup.id === farmId
    );
    return farm?.facilities ?? [];
  }

  private updateFarmsListByCustomer() {
    const farms = this.getFarmsByCustomer(this.customerId);
    this.farmsList$.next(farms);
  }

  public updateGroupsListByHouse() {
    const groups = this.getGroupsByHouse(this.houseId);
    this.groupsList$.next(groups);
  }

  public updateStateForService(stateProps: IStateProps) {
    const currentState = { ...this.state, ...stateProps };
    const state = JSON.stringify(currentState);
    this.stateProps = currentState;
    localStorage.setItem(SESSION_KEY, state);
  }

  // Pendo
  public async updatePendo() {
    if (!!this.userDataService?.userRes?.id) {
      let settings = await this.applicationSettingsService
        .getSettings()
        .toPromise();

      if (settings.isPendoActive) {
        // tslint:disable-next-line:no-any
        let pendoDataObject: any;

        const visitorId = JSON.parse(localStorage.getItem(USER_RES_KEY)).id;
        const visitorEmail = JSON.parse(
          localStorage.getItem(USER_RES_KEY)
        ).email;
        const visitorFirstName = JSON.parse(
          localStorage.getItem(USER_RES_KEY)
        ).firstName;
        const visitorLastName = JSON.parse(
          localStorage.getItem(USER_RES_KEY)
        ).lastName;

        // Find role
        let farmRole = '';
        const currentFarm = this.userDataService.userRes?.role.find(
          (role) => role.facilityGroupId === this.currentFarm?.id
        );
        if (currentFarm) {
          farmRole = currentFarm?.roleName;
        } else {
          const currentCustomer = this.userDataService.userRes?.role.find(
            (role) => role.customerId === this.customerId
          );
          if (currentCustomer) {
            farmRole = currentCustomer?.roleName;
          } else {
            farmRole = this.userDataService.userRes?.role[0].roleName;
          }
        }

        pendoDataObject = {
          visitor: {
            id: visitorId,
            device: 'Web',
            farmRole: farmRole,
            lang: this.translationService.selectedLanguage,
            firstName: visitorFirstName,
            lastName: visitorLastName,
          },
          account: {
            id: this.currentCustomer?.id,
            customerCountry_P: this.currentCustomer?.country,
            customerName_P: this.currentCustomer?.name,
            facilityGroupId_P: this.farmId,
            facilityId_P: this.houseId,
            environment_P: this.configService?.storedConfiguration?.apiUrl,
          },
        };

        if (pendo && pendo?.isReady()) {
          if (
            pendo.visitorId !== pendoDataObject?.visitor?.id ||
            pendo.accountId !== pendoDataObject?.account?.id
          ) {
            pendo.identify(
              pendoDataObject.visitor.id,
              pendoDataObject.account.id
            );
            pendo.updateOptions(pendoDataObject);
          } else {
            pendo.updateOptions(pendoDataObject);
          }
        } else {
          pendo.initialize(pendoDataObject);
          pendo.loadGuides();
        }
      }
    }
  }

  public clearKpisSelection() {
    this.selectedComparisonCrops = [];
    this.selectedComparisonParameter = null;
    this.selectedComparisonTargetIndex = 0;
    this.showAverageOfCrops = true;
    this.showIndividualOfCrops = true;
    this.selectedKpis = [];
    this.allSelectedKpis = [];
    this.selectedTargetKPI = null;
    this.selectedMinMaxAmbient = null;
    this.currentSensorsPageGraphWeekCount = null;
    this.currentComparisonPageGraphWeekCount = null;
    this.comparisonSelectedCropParameter = HarvestMetricType.Temperature;
    this.comparisonSelectedSectionB = {
      customerId: null,
      farmId: null,
      houseId: null,
      cropIds: [],
    };
  }

  public setSavedDefaultDate(date: Date) {
    date = moment.utc(date).toDate();
    localStorage.setItem(SAVED_DEFAULT_DATE_KEY, JSON.stringify(date));
  }

  public deleteSavedDefaultDate() {
    localStorage.removeItem(SAVED_DEFAULT_DATE_KEY);
  }
}
