/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FacilityHarvestDto } from '../models/facility-harvest-dto';
import { FacilityReportsDto } from '../models/facility-reports-dto';
import { FacilityRequest } from '../models/facility-request';
import { FacilityResponse } from '../models/facility-response';
import { HarvestBasicResponse } from '../models/harvest-basic-response';

@Injectable({
  providedIn: 'root',
})
export class FacilityService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createFacility
   */
  static readonly CreateFacilityPath = '/api/facility';

  /**
   * Create Facility.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFacility()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFacility$Response(params: {
    body: FacilityRequest
  }): Observable<StrictHttpResponse<FacilityResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityService.CreateFacilityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityResponse>;
      })
    );
  }

  /**
   * Create Facility.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFacility$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFacility(params: {
    body: FacilityRequest
  }): Observable<FacilityResponse> {

    return this.createFacility$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityResponse>) => r.body as FacilityResponse)
    );
  }

  /**
   * Path part for operation updateFacility
   */
  static readonly UpdateFacilityPath = '/api/facility/{facilityId}';

  /**
   * Update Facility.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFacility()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFacility$Response(params: {
    facilityId: number;
    body: FacilityRequest
  }): Observable<StrictHttpResponse<FacilityResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityService.UpdateFacilityPath, 'put');
    if (params) {
      rb.path('facilityId', params.facilityId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityResponse>;
      })
    );
  }

  /**
   * Update Facility.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFacility$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFacility(params: {
    facilityId: number;
    body: FacilityRequest
  }): Observable<FacilityResponse> {

    return this.updateFacility$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityResponse>) => r.body as FacilityResponse)
    );
  }

  /**
   * Path part for operation deleteFacility
   */
  static readonly DeleteFacilityPath = '/api/facility/{facilityId}';

  /**
   * Delete Facility.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFacility()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFacility$Response(params: {
    facilityId: number;
  }): Observable<StrictHttpResponse<FacilityResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityService.DeleteFacilityPath, 'delete');
    if (params) {
      rb.path('facilityId', params.facilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityResponse>;
      })
    );
  }

  /**
   * Delete Facility.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFacility$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFacility(params: {
    facilityId: number;
  }): Observable<FacilityResponse> {

    return this.deleteFacility$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityResponse>) => r.body as FacilityResponse)
    );
  }

  /**
   * Path part for operation getFacilityReports
   */
  static readonly GetFacilityReportsPath = '/api/facility/{facilityId}/facilityreport';

  /**
   * Get Facility Reports.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFacilityReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityReports$Response(params: {
    facilityId: number;
    authorId?: number;
    fetchAllRecord?: boolean;
    publishedOnly?: boolean;
  }): Observable<StrictHttpResponse<FacilityReportsDto>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityService.GetFacilityReportsPath, 'get');
    if (params) {
      rb.path('facilityId', params.facilityId, {});
      rb.query('authorId', params.authorId, {});
      rb.query('fetchAllRecord', params.fetchAllRecord, {});
      rb.query('publishedOnly', params.publishedOnly, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityReportsDto>;
      })
    );
  }

  /**
   * Get Facility Reports.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFacilityReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityReports(params: {
    facilityId: number;
    authorId?: number;
    fetchAllRecord?: boolean;
    publishedOnly?: boolean;
  }): Observable<FacilityReportsDto> {

    return this.getFacilityReports$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityReportsDto>) => r.body as FacilityReportsDto)
    );
  }

  /**
   * Path part for operation getFacilityHarvests
   */
  static readonly GetFacilityHarvestsPath = '/api/facility/{facilityId}/harvest';

  /**
   * Get Facility Harvests.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFacilityHarvests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityHarvests$Response(params: {
    facilityId: number;
    filterDate?: string;
  }): Observable<StrictHttpResponse<Array<FacilityHarvestDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityService.GetFacilityHarvestsPath, 'get');
    if (params) {
      rb.path('facilityId', params.facilityId, {});
      rb.query('filterDate', params.filterDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FacilityHarvestDto>>;
      })
    );
  }

  /**
   * Get Facility Harvests.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFacilityHarvests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityHarvests(params: {
    facilityId: number;
    filterDate?: string;
  }): Observable<Array<FacilityHarvestDto>> {

    return this.getFacilityHarvests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FacilityHarvestDto>>) => r.body as Array<FacilityHarvestDto>)
    );
  }

  /**
   * Path part for operation gatHarvestBasicResponse
   */
  static readonly GatHarvestBasicResponsePath = '/api/facility/{facilityId}/harvest-basic-response';

  /**
   * Gat Harvest Basic Response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gatHarvestBasicResponse()` instead.
   *
   * This method doesn't expect any request body.
   */
  gatHarvestBasicResponse$Response(params: {
    facilityId: number;
  }): Observable<StrictHttpResponse<Array<HarvestBasicResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityService.GatHarvestBasicResponsePath, 'get');
    if (params) {
      rb.path('facilityId', params.facilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HarvestBasicResponse>>;
      })
    );
  }

  /**
   * Gat Harvest Basic Response.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `gatHarvestBasicResponse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gatHarvestBasicResponse(params: {
    facilityId: number;
  }): Observable<Array<HarvestBasicResponse>> {

    return this.gatHarvestBasicResponse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HarvestBasicResponse>>) => r.body as Array<HarvestBasicResponse>)
    );
  }

}
