import { NgModule } from '@angular/core';
import { QuickInsightWidgetComponent } from './quick-insight-widget.component';
import { NoDataAvailableModule } from '../../no-data-available/no-data-available.module';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../../pipes/pipes.module';
import { WindSpeedImpactModalModule } from '../../modals/wind-speed-impact/wind-speed-impact-modal.module';

@NgModule({
  declarations: [QuickInsightWidgetComponent],
  imports: [
    NoDataAvailableModule,
    CommonModule,
    PipesModule,
    WindSpeedImpactModalModule,
  ],
  exports: [QuickInsightWidgetComponent],
})
export class QuickInsightWidgetModule {}
