<div class="display-flex position-relative">
  <div class="menus__menu">
    <div class="menus__menu-selector height66" *ngIf="isDateChanged">
      <div class="menus__menu-selector-label">{{ 'COMMON.Date' | translate }}</div>
      <date-picker
        [min]="startDate"
        [max]="maxDate"
        [value]="selectedDate"
        (valueChange)="changeSelectedDate($event)"
      ></date-picker>
    </div>
    <div class="menus__menu-selector height66" *ngIf="!isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'COMMON.Date' | translate }}</div>
      <input type="text" value="NA" (click)="isDateChanged = true; changeSelectedDate(systemNowDate)"/>
    </div>
  </div>
  <div class="menus__menu" *ngIf="functionalMode === EventFunctionalModes.addEvent">
    <div class="menus__menu-selector height66" *ngIf="!isDateChanged">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
      <input
        [options]="{
              digitGroupSeparator: translationService.digitGroupSeparator,
              decimalCharacter: translationService.decimalCharacter,
              decimalPlaces: 0,
              minimumValue: '0',
              maximumValue: '80'
            }"
        autonumeric
        [value]="birdAge"
        (change)="changeBirdAge($event.target.value)"
      >
    </div>
    <div class="menus__menu-selector height66" *ngIf="isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
      <input type="text" value="NA" (click)="isDateChanged = false; changeBirdAge(birdAge)"/>
    </div>
  </div>
  <div class="menus__dash" *ngIf="functionalMode !== EventFunctionalModes.addEvent">&mdash;</div>
  <div class="menus__menu" *ngIf="functionalMode !== EventFunctionalModes.addEvent">
    <div class="menus__menu-selector height66 custom-select custom-select__disabled">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_age' | translate }}</div>
      <input disabled [value]="birdAge">
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66">
      <div class="menus__menu-selector-label">{{"EVENT.coccidiostat" | translate}}</div>
      <simple-dropdown
        [items]="coccidiostats"
        [selectedValue]="coccidiostatsSelectedItem"
        (onItemSelected)="coccidiostatChanged($event)"
      >
      </simple-dropdown>
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66">
      <div class="menus__menu-selector-label">{{"EVENT.additives" | translate}}</div>

      <simple-dropdown
        [items]="additives"
        [allowClear]="false"
        [selectedValue]="additivesSelectedItem"
        (onItemSelected)="additivesChanged($event)">
      </simple-dropdown>
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66">
      <div class="menus__menu-selector-label">{{"EVENT.ration" | translate}}</div>

      <simple-dropdown
        [items]="rations"
        [allowClear]="false"
        [selectedValue]="rationsSelectedItem"
        (onItemSelected)="rationChanged($event)">
      </simple-dropdown>
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66" (click)="isTouched = true">
      <div class="menus__menu-selector-label">{{"EVENT.mill" | translate}}</div>
      <ejs-autocomplete
        [fields]="fields"
        [dataSource]='mills'
        [allowFiltering]='true'
        [allowCustom]="false"
        [autofill]="true"
        [sortOrder]="'Ascending'"
        [filterType]="'Contains'"
        [value]="millsSelectedItem"
        (change)="millChanged($event?.value)"
        [placeholder]='event.values[2].value'
      ></ejs-autocomplete>
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66">
      <div class="menus__menu-selector-label">{{"EVENT.Feed_Total_Kg" | translate}}</div>
      <input
        [options]="{
                digitGroupSeparator: translationService.digitGroupSeparator,
                decimalCharacter: translationService.decimalCharacter,
                decimalPlaces: 3,
                minimumValue: '0'
              }"
        autonumeric
        [(ngModel)]="kg"
        (change)="kgChanged($event.target.value)">
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66">
      <div class="menus__menu-selector-label">{{"EVENT.BatchNumber" | translate}}</div>
      <input type="number" min="0" max="999999999999" [(ngModel)]="batchNumber"
             (change)="batchNumber = batchNumberChanged($event.target.value)">
    </div>
  </div>
  <div class="remove-event" (click)="onRemoveEvent()">
    <span class="svg delete-blue"></span>
  </div>
</div>
