/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AlertNotificationSetting } from '../models/alert-notification-setting';
import { AlertNotificationSettingDto } from '../models/alert-notification-setting-dto';
import { NotificationTitle } from '../models/notification-title';

@Injectable({
  providedIn: 'root',
})
export class AlertNotificationSettingService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getList_2
   */
  static readonly GetList_2Path = '/api/notification/alert/setting';

  /**
   * Get List.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getList_2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList_2$Response(params?: {
  }): Observable<StrictHttpResponse<Array<AlertNotificationSetting>>> {

    const rb = new RequestBuilder(this.rootUrl, AlertNotificationSettingService.GetList_2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AlertNotificationSetting>>;
      })
    );
  }

  /**
   * Get List.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getList_2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList_2(params?: {
  }): Observable<Array<AlertNotificationSetting>> {

    return this.getList_2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AlertNotificationSetting>>) => r.body as Array<AlertNotificationSetting>)
    );
  }

  /**
   * Path part for operation addSetting_2
   */
  static readonly AddSetting_2Path = '/api/notification/alert/setting';

  /**
   * Add Setting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSetting_2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSetting_2$Response(params: {
    body: AlertNotificationSettingDto
  }): Observable<StrictHttpResponse<AlertNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, AlertNotificationSettingService.AddSetting_2Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlertNotificationSetting>;
      })
    );
  }

  /**
   * Add Setting.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addSetting_2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSetting_2(params: {
    body: AlertNotificationSettingDto
  }): Observable<AlertNotificationSetting> {

    return this.addSetting_2$Response(params).pipe(
      map((r: StrictHttpResponse<AlertNotificationSetting>) => r.body as AlertNotificationSetting)
    );
  }

  /**
   * Path part for operation getSetting_3
   */
  static readonly GetSetting_3Path = '/api/notification/alert/setting/id/{settingId}';

  /**
   * Get Setting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSetting_3()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetting_3$Response(params: {
    settingId: number;
  }): Observable<StrictHttpResponse<AlertNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, AlertNotificationSettingService.GetSetting_3Path, 'get');
    if (params) {
      rb.path('settingId', params.settingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlertNotificationSetting>;
      })
    );
  }

  /**
   * Get Setting.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSetting_3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetting_3(params: {
    settingId: number;
  }): Observable<AlertNotificationSetting> {

    return this.getSetting_3$Response(params).pipe(
      map((r: StrictHttpResponse<AlertNotificationSetting>) => r.body as AlertNotificationSetting)
    );
  }

  /**
   * Path part for operation findByRoleId
   */
  static readonly FindByRoleIdPath = '/api/notification/alert/setting/roleId/{roleId}';

  /**
   * Find By Role Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByRoleId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByRoleId$Response(params: {
    roleId: number;
  }): Observable<StrictHttpResponse<Array<AlertNotificationSetting>>> {

    const rb = new RequestBuilder(this.rootUrl, AlertNotificationSettingService.FindByRoleIdPath, 'get');
    if (params) {
      rb.path('roleId', params.roleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AlertNotificationSetting>>;
      })
    );
  }

  /**
   * Find By Role Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByRoleId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByRoleId(params: {
    roleId: number;
  }): Observable<Array<AlertNotificationSetting>> {

    return this.findByRoleId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AlertNotificationSetting>>) => r.body as Array<AlertNotificationSetting>)
    );
  }

  /**
   * Path part for operation findByRoleName_1
   */
  static readonly FindByRoleName_1Path = '/api/notification/alert/setting/roleName/{roleName}';

  /**
   * Find By Role Name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByRoleName_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByRoleName_1$Response(params: {
    roleName: string;
  }): Observable<StrictHttpResponse<Array<AlertNotificationSetting>>> {

    const rb = new RequestBuilder(this.rootUrl, AlertNotificationSettingService.FindByRoleName_1Path, 'get');
    if (params) {
      rb.path('roleName', params.roleName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AlertNotificationSetting>>;
      })
    );
  }

  /**
   * Find By Role Name.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByRoleName_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByRoleName_1(params: {
    roleName: string;
  }): Observable<Array<AlertNotificationSetting>> {

    return this.findByRoleName_1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AlertNotificationSetting>>) => r.body as Array<AlertNotificationSetting>)
    );
  }

  /**
   * Path part for operation findByTitle
   */
  static readonly FindByTitlePath = '/api/notification/alert/setting/title/{title}';

  /**
   * Find By Title.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByTitle()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByTitle$Response(params: {
    title: NotificationTitle;
  }): Observable<StrictHttpResponse<Array<AlertNotificationSetting>>> {

    const rb = new RequestBuilder(this.rootUrl, AlertNotificationSettingService.FindByTitlePath, 'get');
    if (params) {
      rb.path('title', params.title, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AlertNotificationSetting>>;
      })
    );
  }

  /**
   * Find By Title.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByTitle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByTitle(params: {
    title: NotificationTitle;
  }): Observable<Array<AlertNotificationSetting>> {

    return this.findByTitle$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AlertNotificationSetting>>) => r.body as Array<AlertNotificationSetting>)
    );
  }

  /**
   * Path part for operation getSettingsByTitleRoleId_1
   */
  static readonly GetSettingsByTitleRoleId_1Path = '/api/notification/alert/setting/title/{title}/roleId/{roleId}';

  /**
   * Get Settings By Title Role Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingsByTitleRoleId_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsByTitleRoleId_1$Response(params: {
    roleId: number;
    title: NotificationTitle;
  }): Observable<StrictHttpResponse<AlertNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, AlertNotificationSettingService.GetSettingsByTitleRoleId_1Path, 'get');
    if (params) {
      rb.path('roleId', params.roleId, {});
      rb.path('title', params.title, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlertNotificationSetting>;
      })
    );
  }

  /**
   * Get Settings By Title Role Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSettingsByTitleRoleId_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsByTitleRoleId_1(params: {
    roleId: number;
    title: NotificationTitle;
  }): Observable<AlertNotificationSetting> {

    return this.getSettingsByTitleRoleId_1$Response(params).pipe(
      map((r: StrictHttpResponse<AlertNotificationSetting>) => r.body as AlertNotificationSetting)
    );
  }

  /**
   * Path part for operation getSettingsByTitleRoleName
   */
  static readonly GetSettingsByTitleRoleNamePath = '/api/notification/alert/setting/title/{title}/roleName/{roleName}';

  /**
   * Get Settings By Title Role Name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingsByTitleRoleName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsByTitleRoleName$Response(params: {
    roleName: string;
    title: NotificationTitle;
  }): Observable<StrictHttpResponse<AlertNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, AlertNotificationSettingService.GetSettingsByTitleRoleNamePath, 'get');
    if (params) {
      rb.path('roleName', params.roleName, {});
      rb.path('title', params.title, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlertNotificationSetting>;
      })
    );
  }

  /**
   * Get Settings By Title Role Name.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSettingsByTitleRoleName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsByTitleRoleName(params: {
    roleName: string;
    title: NotificationTitle;
  }): Observable<AlertNotificationSetting> {

    return this.getSettingsByTitleRoleName$Response(params).pipe(
      map((r: StrictHttpResponse<AlertNotificationSetting>) => r.body as AlertNotificationSetting)
    );
  }

  /**
   * Path part for operation updateSetting_4
   */
  static readonly UpdateSetting_4Path = '/api/notification/alert/setting/{settingId}';

  /**
   * Update Setting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSetting_4()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSetting_4$Response(params: {
    settingId: number;
    body: AlertNotificationSettingDto
  }): Observable<StrictHttpResponse<AlertNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, AlertNotificationSettingService.UpdateSetting_4Path, 'put');
    if (params) {
      rb.path('settingId', params.settingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlertNotificationSetting>;
      })
    );
  }

  /**
   * Update Setting.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSetting_4$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSetting_4(params: {
    settingId: number;
    body: AlertNotificationSettingDto
  }): Observable<AlertNotificationSetting> {

    return this.updateSetting_4$Response(params).pipe(
      map((r: StrictHttpResponse<AlertNotificationSetting>) => r.body as AlertNotificationSetting)
    );
  }

  /**
   * Path part for operation deleteSetting_2
   */
  static readonly DeleteSetting_2Path = '/api/notification/alert/setting/{settingId}';

  /**
   * Delete Setting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSetting_2()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSetting_2$Response(params: {
    settingId: number;
  }): Observable<StrictHttpResponse<AlertNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, AlertNotificationSettingService.DeleteSetting_2Path, 'delete');
    if (params) {
      rb.path('settingId', params.settingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AlertNotificationSetting>;
      })
    );
  }

  /**
   * Delete Setting.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSetting_2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSetting_2(params: {
    settingId: number;
  }): Observable<AlertNotificationSetting> {

    return this.deleteSetting_2$Response(params).pipe(
      map((r: StrictHttpResponse<AlertNotificationSetting>) => r.body as AlertNotificationSetting)
    );
  }

}
