import { NgModule } from '@angular/core';
import { DashboardsComponent } from './dashboards.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [DashboardsComponent],
  imports: [PipesModule, RouterModule, CommonModule],
  exports: [DashboardsComponent],
})
export class DashboardsModule {}
