<div class="menus__menu">
  <div class="multiselect" (click)="openDropdown()">
    <div class="selected-values" *ngIf="selectedValues.length && !isOpen">{{isTranslated ? translatedSelectedValues : selectedValues}}</div>
    <div class="selected-values" *ngIf="!selectedValues.length && !isOpen">{{placeholder}}</div>
    <div class="selected-values" *ngIf="isOpen && isSearchable">
    <input (keyup)="search($event.target.value)">
    </div>
    <div class="drop-icon" [ngClass]="isOpen ? 'fold-up': ''"></div>
    <div class="values-list" #list [class.none]="!isOpen">
      <div class="checkboxes">
        <label class="checkboxes__checkbox" *ngIf="searchValue?.length < 1">
          <input type="checkbox"
                 [checked]="isChecked('all')"
                 (click)="toggle('all')">
          <span class="checkmark"></span>
          <span class="checkbox-name">{{'CUSTOMERS.All' | translate}}</span>
        </label>
        <label class="checkboxes__checkbox"
               *ngFor="let value of valuesCopy">
          <input type="checkbox"
                 [checked]="isChecked(value)"
                 (click)="toggle(value)">
          <span class="checkmark"></span>
          <div *ngIf="!isTranslated" class="tooltip">
            {{value}}
          </div>
          <div *ngIf="isTranslated" class="tooltip">
            {{'COMMENTS.' + value | translate}}
          </div>
        </label>
      </div>
    </div>
  </div>
</div>
