<div class="popup">
  <div class="popup__body">
    <div class="popup__body-header" *ngIf="currentRole">
      <div>{{ currentRole.name }}</div>
      <div class="popup__body-close">
        <span class="svg close-svg-blue" (click)="closeModal()"></span>
      </div>
    </div>
    <div class="popup__body-content">
      <div class="display-flex flex-column role-name width-100">
        <ng-container *ngFor="let notificationSettingType of mappedSettings">
          <div class="menus__menu width-100" [class.opened]="notificationSettingType.title === openedSettingType">
            <div class="menus__menu-selector">
              <div class="type-header" (click)="selectNotificationSettingType(notificationSettingType.title)">
                {{ 'NOTIFICATIONS.' + notificationSettingType.title | translate }}
              </div>
              <div class="type-body" *ngIf="notificationSettingType.title === openedSettingType">
                <div class="selector">
                  <div class="alerts">
                    <label class="type-selection">
                      <input
                        class="radio"
                        type="radio"
                        name="type"
                        [checked]="notificationSettingType.status === NotificationStatus.No"
                        [value]="NotificationStatus.No"
                        (change)="notificationSettingType.status = $event.target.value"
                      />
                      <span>{{ "SETTINGS.NOTIFICATION_SETTINGS.No" | translate }}</span>
                    </label>
                    <label class="type-selection">
                      <input
                        class="radio"
                        type="radio"
                        name="type"
                        [checked]="notificationSettingType.status === NotificationStatus.Alert"
                        [value]="NotificationStatus.Alert"
                        (change)="notificationSettingType.status = $event.target.value"
                      />
                      <span>{{ "SETTINGS.NOTIFICATION_SETTINGS.Alert" | translate }}</span>
                    </label>
                    <label class="type-selection">
                      <input
                        class="radio"
                        type="radio"
                        name="type"
                        [checked]="notificationSettingType.status === NotificationStatus.All"
                        [value]="NotificationStatus.All"
                        (change)="notificationSettingType.status = $event.target.value"
                      />
                      <span>{{ "SETTINGS.NOTIFICATION_SETTINGS.All" | translate }}</span>
                    </label>
                  </div>
                </div>
                <div class="selector">
                  <div class="time">
                    <div class="selector-header">{{ "SETTINGS.NOTIFICATION_SETTINGS.Time" | translate }}</div>
                    <div class="display-flex width-100">
                      <div class="width-50 from">
                        <div class="selector-header">{{ "SETTINGS.NOTIFICATION_SETTINGS.From" | translate }}</div>
                        <time-picker class="create-batch-event-content-control"
                                     [shownRemovableIcon]="false"
                                     (epochChanged)="onEpochChanged($event, notificationSettingType, 'timeFrom')"
                                     [selectedEpoch]="notificationSettingType.timeFrom"></time-picker>
                      </div>
                      <div class="width-50 to">
                        <div class="selector-header">{{ "SETTINGS.NOTIFICATION_SETTINGS.To" | translate }}</div>
                        <time-picker class="create-batch-event-content-control"
                                     [shownRemovableIcon]="false"
                                     (epochChanged)="onEpochChanged($event, notificationSettingType, 'timeTo')"
                                     [selectedEpoch]="notificationSettingType.timeTo"></time-picker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="selector">
                  <div class="time">
                    <div class="display-flex width-100">
                      <div class="width-50 from">
                        <div class="selector-header">{{ "SETTINGS.NOTIFICATION_SETTINGS.Per_Day" | translate }}</div>
                        <input
                          type="number"
                          min="1"
                          max="10"
                          oninput="validity.valid||(value='');"
                          [(ngModel)]="notificationSettingType.maxPerDay"
                          (change)="setMaxPerDay(notificationSettingType, Number($event.target.value))"
                        />
                      </div>
                      <div class="width-50">
                        <div class="selector-header">{{ "SETTINGS.NOTIFICATION_SETTINGS.Interval" | translate }}</div>
                        <input
                          type="number"
                          min="1"
                          max="24"
                          oninput="validity.valid||(value='');"
                          [(ngModel)]="notificationSettingType.hoursInterval"
                          (change)="setInterval(notificationSettingType, Number($event.target.value))"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="selector">
                  <div class="notification">
                    <div class="selector-header">{{ "SETTINGS.NOTIFICATION_SETTINGS.Type" | translate }}</div>
                    <label class="checkbox">
                      <input type="checkbox"
                             [disabled]="!utilsService.isPhoneValid(profileData?.phone, true) && router.url !== '/settings'"
                             [checked]="notificationSettingType.isSmsActive"
                             (change)="notificationSettingType.isSmsActive = !notificationSettingType.isSmsActive"/>
                      <span class="checkmark"></span>
                      <span>SMS</span>
                    </label>
                    <label class="checkbox ml-37">
                      <input type="checkbox"
                             [disabled]="!utilsService.isPhoneValid(profileData?.phone, true) && router.url !== '/settings'"
                             [checked]="!!notificationSettingType.isWhatsAppActive"
                             (change)="!notificationSettingType.isWhatsAppActive ? onShowPopup.emit() : null;
                             notificationSettingType.isWhatsAppActive = !notificationSettingType.isWhatsAppActive; "/>
                      <span class="checkmark"></span>
                      <span>WhatsApp</span>
                    </label>
                    <label class="checkbox ml-37">
                      <input type="checkbox" [checked]="notificationSettingType.isEmailActive"
                             (change)="notificationSettingType.isEmailActive = !notificationSettingType.isEmailActive"/>
                      <span class="checkmark"></span>
                      <span>EMAIL</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="display-flex end width-100 farms-button-wrapper">
      <button
        class="blue-rounded-button"
        [disabled]="!isChangedSettings"
        (click)="saveNotificationSettings()"
      >
        {{ "COMMON.BUTTONS.SAVE_CHANGES" | translate }}
      </button>
    </div>
  </div>

</div>
