/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AgreementsDto } from '../models/agreements-dto';
import { LanguageEnum } from '../models/language-enum';
import { NamedValue } from '../models/named-value';
import { ResendPasswordDto } from '../models/resend-password-dto';
import { ResendPasswordVerifyDto } from '../models/resend-password-verify-dto';
import { UpdateUserPasswordDto } from '../models/update-user-password-dto';
import { UserActivateDto } from '../models/user-activate-dto';
import { UserCreateRequest } from '../models/user-create-request';
import { UserResponse } from '../models/user-response';
import { UserRolePermissionDto } from '../models/user-role-permission-dto';
import { UserUpdateRequest } from '../models/user-update-request';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUsersByCustomerId
   */
  static readonly GetUsersByCustomerIdPath = '/api/user';

  /**
   * Get Users By Customer Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersByCustomerId$Response(params: {
    customerId: number;
  }): Observable<StrictHttpResponse<Array<UserResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUsersByCustomerIdPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserResponse>>;
      })
    );
  }

  /**
   * Get Users By Customer Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsersByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersByCustomerId(params: {
    customerId: number;
  }): Observable<Array<UserResponse>> {

    return this.getUsersByCustomerId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserResponse>>) => r.body as Array<UserResponse>)
    );
  }

  /**
   * Path part for operation activateInvitedUser
   */
  static readonly ActivateInvitedUserPath = '/api/user/activate';

  /**
   * Activate Invited User.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateInvitedUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateInvitedUser$Response(params: {
    body: UserActivateDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ActivateInvitedUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Activate Invited User.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateInvitedUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateInvitedUser(params: {
    body: UserActivateDto
  }): Observable<void> {

    return this.activateInvitedUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getAgreements
   */
  static readonly GetAgreementsPath = '/api/user/agreements';

  /**
   * Get Agreements.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgreements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreements$Response(params?: {
    language?: LanguageEnum;
  }): Observable<StrictHttpResponse<AgreementsDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetAgreementsPath, 'get');
    if (params) {
      rb.query('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgreementsDto>;
      })
    );
  }

  /**
   * Get Agreements.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAgreements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreements(params?: {
    language?: LanguageEnum;
  }): Observable<AgreementsDto> {

    return this.getAgreements$Response(params).pipe(
      map((r: StrictHttpResponse<AgreementsDto>) => r.body as AgreementsDto)
    );
  }

  /**
   * Path part for operation getAgreementsSigned
   */
  static readonly GetAgreementsSignedPath = '/api/user/agreements/signed';

  /**
   * Get Agreements Signed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgreementsSigned()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreementsSigned$Response(params?: {
  }): Observable<StrictHttpResponse<AgreementsDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetAgreementsSignedPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AgreementsDto>;
      })
    );
  }

  /**
   * Get Agreements Signed.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAgreementsSigned$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgreementsSigned(params?: {
  }): Observable<AgreementsDto> {

    return this.getAgreementsSigned$Response(params).pipe(
      map((r: StrictHttpResponse<AgreementsDto>) => r.body as AgreementsDto)
    );
  }

  /**
   * Path part for operation changePassword
   */
  static readonly ChangePasswordPath = '/api/user/changepassword';

  /**
   * Change Password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword$Response(params: {
    body: UpdateUserPasswordDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ChangePasswordPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change Password.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword(params: {
    body: UpdateUserPasswordDto
  }): Observable<void> {

    return this.changePassword$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation forgotPassword
   */
  static readonly ForgotPasswordPath = '/api/user/forgotpassword';

  /**
   * Forgot Password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forgotPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPassword$Response(params: {
    body: ResendPasswordDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ForgotPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Forgot Password.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `forgotPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPassword(params: {
    body: ResendPasswordDto
  }): Observable<void> {

    return this.forgotPassword$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation forgotPasswordVerify
   */
  static readonly ForgotPasswordVerifyPath = '/api/user/forgotpassword/verify';

  /**
   * Forgot Password Verify.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forgotPasswordVerify()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPasswordVerify$Response(params: {
    body: ResendPasswordVerifyDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ForgotPasswordVerifyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Forgot Password Verify.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `forgotPasswordVerify$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPasswordVerify(params: {
    body: ResendPasswordVerifyDto
  }): Observable<void> {

    return this.forgotPasswordVerify$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation inviteUser
   */
  static readonly InviteUserPath = '/api/user/invite';

  /**
   * Invite User.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inviteUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUser$Response(params: {
    body: UserCreateRequest
  }): Observable<StrictHttpResponse<UserResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.InviteUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserResponse>;
      })
    );
  }

  /**
   * Invite User.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inviteUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUser(params: {
    body: UserCreateRequest
  }): Observable<UserResponse> {

    return this.inviteUser$Response(params).pipe(
      map((r: StrictHttpResponse<UserResponse>) => r.body as UserResponse)
    );
  }

  /**
   * Path part for operation getRolesPermissions
   */
  static readonly GetRolesPermissionsPath = '/api/user/roles';

  /**
   * Get Roles Permissions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRolesPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRolesPermissions$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UserRolePermissionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetRolesPermissionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserRolePermissionDto>>;
      })
    );
  }

  /**
   * Get Roles Permissions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRolesPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRolesPermissions(params?: {
  }): Observable<Array<UserRolePermissionDto>> {

    return this.getRolesPermissions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserRolePermissionDto>>) => r.body as Array<UserRolePermissionDto>)
    );
  }

  /**
   * Path part for operation updateUser
   */
  static readonly UpdateUserPath = '/api/user/{userId}';

  /**
   * Update User.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: {
    userId: number;
    body: UserUpdateRequest
  }): Observable<StrictHttpResponse<UserResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UpdateUserPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserResponse>;
      })
    );
  }

  /**
   * Update User.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: {
    userId: number;
    body: UserUpdateRequest
  }): Observable<UserResponse> {

    return this.updateUser$Response(params).pipe(
      map((r: StrictHttpResponse<UserResponse>) => r.body as UserResponse)
    );
  }

  /**
   * Path part for operation deleteUserById
   */
  static readonly DeleteUserByIdPath = '/api/user/{userId}';

  /**
   * Delete User By Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserById$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.DeleteUserByIdPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete User By Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserById(params: {
    userId: number;
  }): Observable<void> {

    return this.deleteUserById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updatePaginationConfig
   */
  static readonly UpdatePaginationConfigPath = '/api/user/{userId}/config/pagination';

  /**
   * Update Pagination Config.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePaginationConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePaginationConfig$Response(params: {
    userId: number;
    body: Array<NamedValue>
  }): Observable<StrictHttpResponse<UserResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UpdatePaginationConfigPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserResponse>;
      })
    );
  }

  /**
   * Update Pagination Config.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePaginationConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePaginationConfig(params: {
    userId: number;
    body: Array<NamedValue>
  }): Observable<UserResponse> {

    return this.updatePaginationConfig$Response(params).pipe(
      map((r: StrictHttpResponse<UserResponse>) => r.body as UserResponse)
    );
  }

}
